export default {
  "alpha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur n’est pas alphabétique"])},
  "alphaNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur doit être alphanumérique"])},
  "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur ne correspond pas à tous les validateurs fournis"])},
  "between": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La valeur doit être comprise entre ", _interpolate(_named("min")), " et ", _interpolate(_named("max"))])},
  "decimal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur doit être décimale"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur n’est pas une adresse e-mail valide"])},
  "integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur n’est pas un entier"])},
  "ipAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur n’est pas une adresse IP valide"])},
  "macAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur n’est pas une adresse MAC valide"])},
  "maxKeywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu peux choisir un maximum de 3 mots clés"])},
  "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La longueur maximale autorisée est ", _interpolate(_named("max"))])},
  "maxThemes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu peux choisir un maximum de 3 thèmes"])},
  "maxValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La valeur maximale est ", _interpolate(_named("max"))])},
  "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La longueur minimale autorisée est ", _interpolate(_named("min"))])},
  "minValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La valeur minimale autorisée est ", _interpolate(_named("min"))])},
  "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur ne correspond pas au validateur fourni"])},
  "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur doit être numérique"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur ne correspond à aucun des validateurs fournis"])},
  "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur est requise"])},
  "requiredIf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur est requise"])},
  "requiredUnless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur est requise"])},
  "sameAs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La valeur doit être égale à la valeur ", _interpolate(_named("otherName"))])},
  "sameAsTrue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu dois accepter pour continuer"])},
  "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur n’est pas une adresse URL valide"])}
}