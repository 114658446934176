<template>
    <div id="login">
        <header id="header" class="mb-4">
            <h1>
                Se connecter
            </h1>
        </header>

        <div id="main">
            <v-form @submit.prevent="login">
                <v-text-field
                    autocomplete="email"
                    :label="$t('security.email')"
                    v-model="credentials.email"
                    :error-messages="Filters.errors(vuelidate.email.$errors)"
                    @blur="vuelidate.email.$touch"

                ></v-text-field>

                <v-text-field
                    autocomplete="current-password"
                    :label="$t('security.password')"
                    v-model="credentials.password"
                    :type="hidePassword?'password':'text'"
                    :error-messages="Filters.errors(vuelidate.password.$errors)"
                    @blur="vuelidate.password.$touch"
                    :append-inner-icon="hidePassword?'mdi-eye':'mdi-eye-off'"
                    @click:append-inner="hidePassword = !hidePassword"
                >
                </v-text-field>

                <v-btn-success
                    type="submit"
                    :loading="loginMutation.isPending.value"
                    :disabled="vuelidate.$invalid"
                >
                    Se connecter
                </v-btn-success>

                <div class="mt-4">
                    Vous n’avez pas de compte?
                    <router-link :to="{name: 'registration'}">
                        Créez un compte AppliGoutte.
                    </router-link>
                </div>
            </v-form>
        </div>
    </div>

</template>

<script lang="ts" setup>
import {reactive, ref} from "vue";
import SecurityManager from "@/managers/SecurityManager";
import useVuelidate from "@vuelidate/core";
import Vuelidate from "@/utils/Vuelidate";
import Filters from "@/utils/Filters";
import {CredentialType} from "@/.generated/api";
import {useRouter} from "vue-router";
import {UnwrapNestedRefs} from "@vue/reactivity";
import Notify, {NotificationType} from "@/notify";

const hidePassword = ref(true);

const credentials: UnwrapNestedRefs<CredentialType> = reactive({
    email: "",
    password: "",
});

const loginMutation = SecurityManager.useTokenMutation();
const router = useRouter();

const login = async () => {
    await loginMutation.mutateAsync(credentials)
        .then(() => {
            Notify.addNotification({
                title: "security.success.title",
                icon: "mdi-check",
                type: NotificationType.SUCCESS,
            });

            router.push({name: "home"});
        })
        .catch(() => {
            Notify.addNotification({
                title: "security.error.title",
                content: "security.error.invalidCredentials",
                icon: "mdi-error",
                type: NotificationType.ERROR,
            });
        });
};

const vuelidate = useVuelidate(
    {
        email: [Vuelidate.required, Vuelidate.email],
        password: [Vuelidate.required],
    },
    credentials,
);
</script>

<style lang="scss" scoped>
#login {
    display: flex;
    flex-direction: column;
    height: 100%;
}
</style>