<template>
    <Line
        v-if="chartData"
        :chartData="chartData"
        :chartOptions="chartOptions"
        :plugins="plugins"
    >
    </Line>
</template>

<script lang="ts" setup>
import {Line} from "vue-chartjs";
import {CategoryScale, Chart as ChartJS, Colors, Filler, LinearScale, LineElement, PointElement, Title} from "chart.js";
import {UalHistoryLine} from "@/.generated/api";
import {computed, toRefs} from "vue";
import moment from "moment";
import {useI18n} from "vue-i18n";
import ChartDataLabels from "chartjs-plugin-datalabels";

const i18n = useI18n();
ChartJS.register(
    Title,
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale,
    Colors,
    Filler,
);

const props = defineProps<{
    ualHistoric: UalHistoryLine[] | undefined
}>();
const {ualHistoric} = toRefs(props);

const plugins = [ChartDataLabels];

const chartData = computed(() => {
    if (!ualHistoric.value) {
        return;
    }

    const labels = [];
    const data = [];

    for (const ualHistoricLine of (ualHistoric.value ?? []).slice(-5)) {
        labels.push(moment(ualHistoricLine.date).format("ll").split(" "));
        data.push(ualHistoricLine.ual);
    }

    return {
        labels: labels,
        datasets: [
            {
                label: i18n.t("ual.umol/L"),
                data: data,
                pointStyle: "circle",
                pointRadius: 5,
                pointHoverRadius: 10,
                cubicInterpolationMode: "monotone",
                fill: {
                    target: 1,
                    above: "rgba(255, 0, 0, 0.2)",   // Area will be red above the origin
                    below: "rgba(0, 255, 0, 0.2)",    // And blue below the origin
                },
                backgroundColor: "#FF0000",
                datalabels: {
                    color: "#FFFFFF",
                    backgroundColor: "#FF0000",
                    borderRadius: 50,
                    formatter: function (value: number) {
                        return `${value} ${i18n.t("ual.umol/L")}`;
                    },
                },
            },
            {
                data: data.map(() => 360),
                pointRadius: 0,
                borderColor: "#9B74F9",
                datalabels: {
                    display: false,
                },

            },
        ],
    };
});

const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
        padding: 38,
    },
    scales: {
        y: {
            display: false,
        },
    },
};
</script>