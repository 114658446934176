<template>
    <div id="daily-view" :class="'slide-'+currentSlideIndex">
        <div id="daily-content">
            <div v-if="currentSlideIndex <= 6">
                <h1 :class="{'dynamic-title': true, 'dynamic-title--small': currentSlideIndex >= 1}">
                    Suivez votre crise de goutte
                </h1>

                <template v-if="currentSlideIndex >= 1">
                    <Breadcrumb :current-step="currentSlideIndex - 1" :nb-steps="slides.length - 2"/>
                </template>
            </div>

            <Swiper
                id="daily-swiper"
                :slides-per-view="1"
                :space-between="20"
                :loop="false"
                :pagination="{clickable: false , el: '.swiper-pagination' }"
                @swiper="(currentSwiper) => swiper = currentSwiper"
                :allow-touch-move="false"
                style="height: 100%; width: 100%;"
            >
                <SwiperSlide v-for="slide in slides" :key="slide.id">
                    <component :is="slide.component" v-model="model"/>
                </SwiperSlide>
            </Swiper>

            <div id="daily-buttons">
                <v-container>
                    <v-row>
                        <v-col v-if="currentSlideIndex > 0" cols="6">
                            <v-btn
                                @click.prevent="previousSlide"
                            >
                                {{ isLastSlide ? "Modifier" : "Précedent" }}
                            </v-btn>
                        </v-col>
                        <v-col :cols="currentSlideIndex === 0 ? 12: 6">
                            <v-btn-success
                                @click.prevent="() => isLastSlide ? sendInformations(): nextSlide()"
                                :disabled="!canNextSlide"
                            >
                                {{ isLastSlide ? "Valider" : "Continuer" }}
                            </v-btn-success>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import DailyFollowingSlide1 from "@/components/gout/crisisDailyFollowing/DailyFollowingSlide1.vue";
import DailyFollowingSlide7 from "@/components/gout/crisisDailyFollowing/DailyFollowingSlide7.vue";
import {Swiper, SwiperSlide} from "swiper/vue";
import {computed, markRaw, onMounted, Ref, ref, watch} from "vue";
import {Swiper as SwiperClass} from "swiper/types";
import {useRouter} from "vue-router";
import {CrisisDailyFollowingType, CrisisFinishedType} from "@/.generated/api";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb.vue";
import Notify, {NotificationType} from "@/notify";
import MeManager from "@/managers/MeManager";
import DailyFollowingSlide2 from "@/components/gout/crisisDailyFollowing/DailyFollowingSlide2.vue";
import DailyFollowingSlide3 from "@/components/gout/crisisDailyFollowing/DailyFollowingSlide3.vue";
import DailyFollowingSlide4 from "@/components/gout/crisisDailyFollowing/DailyFollowingSlide4.vue";
import DailyFollowingSlide5 from "@/components/gout/crisisDailyFollowing/DailyFollowingSlide5.vue";
import DailyFollowingSlide6 from "@/components/gout/crisisDailyFollowing/DailyFollowingSlide6.vue";
import GoutManager from "@/managers/GoutManager";

const router = useRouter();

const swiper = ref<SwiperClass | null>(null);

const patientQuery = MeManager.useGetPatient();
const patientGoutCrisisFinishMutation = GoutManager.usePatientGoutCrisisFinishMutation();
const patientGoutCrisisDailyFollowingMutation = GoutManager.usePatientGoutCrisisDailyFollowingMutation();

const patient = computed(() => {
    return patientQuery.data.value;
});

const currentCrisis = computed(() => {
    if (!patientQuery.data.value) {
        return undefined;
    }

    return patientQuery.data.value.currentCrisis;
});

const slides = computed(() => {
    const slidesGenerator = [
        {id: 1, component: markRaw(DailyFollowingSlide1)},
        {id: 2, component: markRaw(DailyFollowingSlide2)},
    ];

    if (!model.value.crisisContinue) {
        slidesGenerator.push(...([
            {id: 3, component: markRaw(DailyFollowingSlide3)},
            {id: 4, component: markRaw(DailyFollowingSlide4)},
            {id: 5, component: markRaw(DailyFollowingSlide5)},
            {id: 6, component: markRaw(DailyFollowingSlide6)},
        ] as any[]));
    }

    slidesGenerator.push({id: 7, component: markRaw(DailyFollowingSlide7)});


    return slidesGenerator;
});

export interface CrisisDailyCustomModel {
    crisisContinue: boolean | undefined;
    crisisFinishType: Partial<CrisisFinishedType>;
    crisisDailyFollowingType: Partial<CrisisDailyFollowingType>;
}

const model: Ref<CrisisDailyCustomModel> = ref({
    crisisContinue: undefined,
    crisisFinishType: {
        stoppedAt: undefined,
    },
    crisisDailyFollowingType: {
        feelings: undefined,
        medicationTook: undefined,
        painLevel: undefined,
        symptoms: undefined,
    },
});

const crisisFinishType: Ref<Partial<CrisisFinishedType>> = computed(() => {
    return model.value.crisisFinishType;
});

const crisisDailyFollowingType: Ref<Partial<CrisisDailyFollowingType>> = computed(() => {
    return model.value.crisisDailyFollowingType;
});

function checkIfCurrentCrisis() {
    if (!currentCrisis.value) {
        router.push({name: "home"});

        Notify.addNotification({
            title: "Pas de crise à completer",
            content: "Vous avez déjà complété les informations de vos crises.",
            icon: "mdi-error",
            type: NotificationType.ERROR,
        });

        return;
    }
}

onMounted(checkIfCurrentCrisis)
watch(patient, () => {
    checkIfCurrentCrisis();
});

const currentSlideIndex = computed(() => {
    let swiperUnwrapped = swiper.value;

    if (!swiperUnwrapped) {
        return;
    }

    return swiperUnwrapped.activeIndex;
});

const currentSlide = computed(() => {
    if (!currentSlideIndex) {
        return;
    }

    return slides.value[currentSlideIndex.value];
});

const isLastSlide = computed(() => {
    let swiperUnwrapped = swiper.value;
    if (!swiperUnwrapped) {
        return;
    }

    return swiperUnwrapped.isEnd;
});

const nextSlide = () => {
    let swiperUnwrapped = swiper.value;
    if (!swiperUnwrapped) {
        return;
    }
    if (swiperUnwrapped.isEnd) {
        return;
    }

    swiperUnwrapped.slideNext();
};

const previousSlide = () => {
    let swiperUnwrapped = swiper.value;
    if (!swiperUnwrapped) {
        return;
    }
    if (swiperUnwrapped.isBeginning) {
        return;
    }

    swiperUnwrapped.slidePrev();
};


const updateSlideMaxHeight = () => {
    let swiperUnwrapped = swiper.value;
    if (!swiperUnwrapped) {
        return;
    }

    const currentSlide = swiperUnwrapped.slides[swiperUnwrapped.activeIndex];
    const slides = Array.from(swiperUnwrapped.wrapperEl.children) as HTMLElement[];
    for (const slide of slides) {
        slide.style.maxHeight = currentSlide.offsetHeight + "px";
    }
};

watch(currentSlideIndex, () => {
    updateSlideMaxHeight();
});

onMounted(updateSlideMaxHeight);

const canNextSlide = computed(() => {
    let unwrapCurrentSlide = currentSlide.value;
    let unwrapModel = model.value;

    if (unwrapCurrentSlide === undefined) {
        return false;
    }

    return (
        unwrapCurrentSlide.id === 1 ||
        (unwrapCurrentSlide.id === 2 && unwrapModel.crisisContinue === false || (unwrapModel.crisisContinue === true && unwrapModel.crisisFinishType.stoppedAt !== undefined)) ||
        (unwrapCurrentSlide.id === 3 && unwrapModel.crisisDailyFollowingType.treatmentTook !== undefined) ||
        (unwrapCurrentSlide.id === 4 && unwrapModel.crisisDailyFollowingType.painLevel !== undefined) ||
        (unwrapCurrentSlide.id === 5 && unwrapModel.crisisDailyFollowingType.feelings !== undefined) ||
        (unwrapCurrentSlide.id === 6 && unwrapModel.crisisDailyFollowingType.symptoms !== undefined) ||
        (unwrapCurrentSlide.id === 7 && Object.values(unwrapModel).reduce((acc, value) => (acc && value !== undefined), true))
    );
});

const sendInformations = async () => {
    if (model.value.crisisContinue) {
        await patientGoutCrisisFinishMutation.mutateAsync({
            crisisId: currentCrisis.value.id,
            crisisFinished: crisisFinishType.value as CrisisFinishedType,
        });

        Notify.addNotification({
            title: "gout.crisis.sendFinished.success.title",
            content: "gout.crisis.sendFinished.success.content",
            icon: "mdi-check",
            type: NotificationType.SUCCESS,
        });
        router.push({name: "home"});

        return;
    }

    await patientGoutCrisisDailyFollowingMutation.mutateAsync({
        crisisId: currentCrisis.value.id,
        crisisDailyFollowing: crisisDailyFollowingType.value as CrisisDailyFollowingType,
    });

    Notify.addNotification({
        title: "gout.crisis.sendDailyFollowing.success.title",
        content: "gout.crisis.sendDailyFollowing.success.content",
        icon: "mdi-check",
        type: NotificationType.SUCCESS,
    });

    router.push({name: "home"});
};
</script>

<style lang="scss" scoped>
#daily-view {
    display: flex;
    flex-direction: column;
    height: 100%;

    &.slide-6 {
        background: #FFF0EB;
    }

    .dynamic-title {
        transition: font-size ease-in-out 250ms, line-height ease-in-out 250ms;
        margin-bottom: 28px;
        font-size: 44px;
        line-height: 44px;

        &--small {
            font-size: 20px;
            line-height: 24px;
        }
    }

    #daily-content {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    #daily-swiper {
        margin-top: 23px;
        flex: 1;
    }
}
</style>

<style>
.swiper-slide-active {
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.slide {
    box-sizing: border-box;
}
</style>