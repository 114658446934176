<template>
    <div>
        <h3>
            <img :src="triggerFactorCategory.imgUrl" :alt="triggerFactorCategory.name">
            {{ triggerFactorCategory.name }}
        </h3>

        <Swiper
            :modules="[Mousewheel]"
            :mousewheel="true"
            :space-between="30"
            :width="300"
            style="height: 150px"
        >
            <SwiperSlide
                v-for="triggerFactor in triggerFactorCategory.triggerFactorsChoices"
            >
                <button
                    type="button"
                    class="factor-trigger-btn"
                    @click="toggleTriggerFactor(triggerFactor.value)"
                    :class="{active: isToggleFactorSelected(triggerFactor.value)}"
                >
                <span class="factor-trigger-btn-label">
                    {{ triggerFactor.name }}
                </span>
                    <span class="factor-trigger-btn-img">
                    <img :src="triggerFactor.imgUrl" :alt="triggerFactor.name">
                </span>
                </button>


            </SwiperSlide>


        </Swiper>
    </div>
</template>

<script lang="ts" setup>

import {CrisisInformationsType} from "@/.generated/api";
import {Swiper, SwiperSlide} from "swiper/vue";
import {computed, toRefs} from "vue";
import {Mousewheel} from "swiper";


export type TriggerFactorType = {
    name: string,
    imgUrl: string,
    value: CrisisInformationsType["triggerFactors"][number]
}

export type TriggerFactorCategoryType = {
    name: string,
    imgUrl: string,
    triggerFactorsChoices: TriggerFactorType[]
}

const props = defineProps<{
    triggerFactorCategory: TriggerFactorCategoryType
}>();

const {triggerFactorCategory} = toRefs(props);

const crisisInformationType = defineModel<Partial<CrisisInformationsType>>({required: true});

const triggerFactors = computed({
    get: () => {
        return crisisInformationType.value.triggerFactors;
    },
    set: (triggerFactors: array) => {
        crisisInformationType.value.triggerFactors = triggerFactors;
    },
});

type TriggerFactorEnum = typeof CrisisInformationsType.triggerFactors

const toggleTriggerFactor = (toggleFactor: TriggerFactorEnum) => {
    if (triggerFactors.value === undefined) {
        triggerFactors.value = [];
    }

    const index = triggerFactors.value.indexOf(toggleFactor);

    if (index === -1) {
        triggerFactors.value.push(toggleFactor);
        return;
    }

    triggerFactors.value.splice(index, 1);
};

const isToggleFactorSelected = (toggleFactor: TriggerFactorEnum): boolean => {
    return triggerFactors.value.includes(toggleFactor) ?? false;
};
</script>

<style lang="scss" scoped>
.factor-trigger-btn {
    display: flex;

    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: white;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;

    border: 1px solid #66C1BF;
    border-radius: 20px;

    padding: 5px 10px;
    align-items: center;
    gap: 5px;


    &.active {
        background: #FFA050;
    }

    & > &-label {
        flex: 1;
        text-align: left;
    }

    & > &-img {
        width: 123px;
        height: 123px;

        background: white;
        border-radius: 100%;
        padding: 5px;

        flex-shrink: 0;
    }
}
</style>