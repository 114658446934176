/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Crisis } from '../models/Crisis';
import type { CrisisDailyFollowingType } from '../models/CrisisDailyFollowingType';
import type { CrisisDiagnosisType } from '../models/CrisisDiagnosisType';
import type { CrisisFinishedType } from '../models/CrisisFinishedType';
import type { CrisisInformationsType } from '../models/CrisisInformationsType';
import type { Positioning } from '../models/Positioning';
import type { PositioningType } from '../models/PositioningType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GoutService {

    /**
     * Add diagnosis to patient
     * Permit to add a diagnosis to patient.
     * @param requestBody
     * @returns Crisis Add a diagnosis to patient
     * @throws ApiError
     */
    public static postPatientGoutCrisisDiagnosis(
        requestBody?: CrisisDiagnosisType,
    ): CancelablePromise<Crisis> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/patient/gout/crisis',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Add informations to crisis
     * Permit to add informations to crisis.
     * @param id
     * @param requestBody
     * @returns Crisis Add informations to crisis
     * @throws ApiError
     */
    public static postPatientGoutCrisisInformations(
        id: string,
        requestBody?: CrisisInformationsType,
    ): CancelablePromise<Crisis> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/patient/gout/crisis/{id}/informations',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Add daily following to crisis
     * Permit to add daily following to crisis
     * @param id
     * @param requestBody
     * @returns Crisis Add daily following to crisis
     * @throws ApiError
     */
    public static postPatientGoutCrisisDailyFollowing(
        id: string,
        requestBody?: CrisisDailyFollowingType,
    ): CancelablePromise<Crisis> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/patient/gout/crisis/{id}/dailyFollowing',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Finish a crisis
     * Permit to finish a crisis
     * @param id
     * @param requestBody
     * @returns Crisis Stop the crisis
     * @throws ApiError
     */
    public static patchPatientGoutCrisisFinish(
        id: string,
        requestBody?: CrisisFinishedType,
    ): CancelablePromise<Crisis> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/patient/gout/crisis/{id}/dailyFollowing',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Add positioning to patient
     * Permit to add a positioning to patient.
     * @param requestBody
     * @returns Positioning Add a positioning to patient
     * @throws ApiError
     */
    public static postPatientGoutPositioning(
        requestBody?: PositioningType,
    ): CancelablePromise<Positioning> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/patient/gout/positioning',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
