<template>
    <div>
        <section>
            <h2>
                <label for="ual-result">
                    {{ $t("crisis.yourHistoric") }}
                </label>
            </h2>

            <template v-if="mePatient.isSuccess && crisiss && crisiss.length > 0">
                <v-expansion-panels variant="accordion">
                    <v-expansion-panel
                        :title="title(crisis)"
                        v-for="crisis of crisiss"
                        :key="crisis.id"
                    >
                        <v-expansion-panel-text>
                            <CrisisDetails :crisis="crisis"/>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </template>
            <template v-else>
                {{ $t("crisis.noHistory") }}
            </template>
        </section>
    </div>
</template>

<script lang="ts" setup>
import MeManager from "@/managers/MeManager";
import {computed} from "vue";
import CrisisDetails from "@/components/gout/crisisHistory/CrisisDetails.vue";
import {Crisis2} from "@/.generated/api";
import filters from "@/filters";

const mePatient = MeManager.useGetPatient();

const patient = computed(() => {
    return mePatient.data.value;
});

const crisiss = computed(() => {
    if (!patient.value) {
        return undefined;
    }

    return patient.value.crisis;
});

const title = (crisis: Crisis2) => {
    return `Crise du ${filters.date(new Date(crisis.startedAt ?? ""), "L")}` + (crisis.stoppedAt ? ` au ${filters.date(new Date(crisis.stoppedAt ?? ""), "L")}` : "");
};
</script>

<style lang="scss" scoped>
</style>