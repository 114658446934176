<template>
    <div
        id="app-layout" :class="{'display-navigation-bar': appStore.appStyle.displayNavigationBar}"
        :style="
            {
                'color': appStore.appStyle.color,
                'background-color': appStore.appStyle.backgroundColor,
                ...paddingFullWidth
            }
        "
    >
        <header>
            <Logo/>
        </header>

        <main>
            <slot></slot>
        </main>

        <NavigationBar v-if="appStore.appStyle.displayNavigationBar"/>
    </div>
</template>

<script lang="ts" setup>
import NavigationBar from "@/components/navigationBar/NavigationBar.vue";
import Logo from "@/components/logo/Logo.vue";
import {useAppStore} from "@/stores/AppStore";
import {computed} from "vue";

const appStore = useAppStore();

const paddingFullWidth = computed(() => {
    return appStore.appStyle.fullWidth && {
        "padding-left": "0",
        "padding-right": "0",
    };
});
</script>

<style lang="scss" scoped>
#app-layout {
    display: flex;
    flex-direction: column;
    min-height: 100dvh;

    padding: 33px 48px;
    @media (max-width: 375px) {
        padding: 17px 24px;
    }

    header {
        padding-bottom: 30px;
    }

    main {
        min-height: 0;
        flex: 1;
    }

    &.display-navigation-bar {
        padding: 34px 30px calc(96px + 34px);
        @media (max-width: 375px) {
            padding: 17px 24px calc(96px + 17px);
        }
    }
}
</style>