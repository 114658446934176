import {defineStore} from "pinia";
import {computed, reactive} from "vue";
import router from "@/router/router";
import {RouteLocationRaw} from "vue-router";

export interface AppStyle {
    fullWidth: boolean,
    displayNavigationBar: boolean;
    color: string;
    backgroundColor: string;
}

interface AppState {
    appStyle: AppStyle;
}

export const useAppStore = defineStore("app", () => {
    let defaultState = {
        appStyle: {
            fullWidth: false,
            displayNavigationBar: true,
            color: "#000000",
            backgroundColor: "#FFF0EB",
        },
    };

    const state: AppState = reactive({...defaultState});

    const appStyle = computed({
            get: (): AppStyle => {
                return state.appStyle;
            },
            set: (appStyle: AppStyle) => {
                state.appStyle = {
                    ...defaultState.appStyle,
                    ...appStyle,
                };
            },
        },
    );

    return {
        state,
        appStyle: appStyle,
    };
});