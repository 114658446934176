<template>
    <div class="slide">
        <h2>
            Avez-vous mal même quand vous ne bougez pas ?
        </h2>

        <div
            class="pa-4 pt-0"
        >
            <PainLevel v-model="modelValue.painLevel"></PainLevel>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {toRefs} from "vue";
import {CustomDiagnosisType} from "@/views/gout/CrisisDiagnosisView.vue";
import PainLevel from "@/components/gout/PainLevel.vue";

const props = defineProps<{
    modelValue: CustomDiagnosisType
}>();

const {modelValue} = toRefs(props);
</script>

<style lang="scss" scoped>
</style>