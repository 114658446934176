<template>
    <v-dialog
        v-model="openModal"
        width="auto"
        transition="dialog-bottom-transition"
        scrollable
    >
        <v-card>
            <v-card-title>
                <v-icon @click="openModal = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-item>
                <v-card-text>
                    <h3>{{ model }}</h3>

                    <p class="mt-4">Vous pouvez regarder nos conseil dans le e-learning, en cas de doute contactez votre médecin.</p>

                    <v-btn-danger class="mt-8" @click.prevent="openModal = false">Compris</v-btn-danger>
                </v-card-text>
            </v-card-item>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import {computed} from "vue";

const model = defineModel<string | undefined>();

const openModal = computed({
    get: () => model.value !== undefined,
    set: (open) => {
        if (!open) {
            model.value = undefined;
        }
    },
});
</script>

<style scoped lang="scss">
svg {
    max-width: 100%;
}

.v-card {
    max-width: 600px !important;
    background-color: #fcf1ec;

    .v-card-title {
        display: flex;
        justify-content: flex-end;
        background: red;
    }
}
</style>