<template>
    <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
            <v-card-text>
                <v-container>
                    <v-row align="center">
                        <v-col class="d-flex justify-center" cols="12">
                            <span class="mdi mdi-48px mdi-cellphone-download-outline"></span>
                        </v-col>
                        <v-col class="text-center" cols="12">
                            <h2>Installer l'application</h2>
                        </v-col>
                        <v-col class="text-center" cols="12">
                            <p class="mb-5">Ajoutez l'application à votre écran d'accueil pour y accéder plus
                                facilement.</p>
                        </v-col>
                        <v-col class="d-flex justify-center" cols="12">
                            <v-btn color="primary" @click="installApp" class="white--text">
                                Installer
                            </v-btn>
                        </v-col>
                        <v-col class="d-flex justify-center" cols="12">
                            <v-btn @click="closeDialog" text color="primary">
                                Fermer
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>


<script lang="ts" setup>
import {computed, ref} from "vue";
import Browser from "@/utils/Browser";

const deferredPrompt = ref<Event | any | null>(null);
const dialog = ref<boolean>(false);
const isMobile = computed(() => Browser.isMobile());

window.addEventListener("beforeinstallprompt", (e) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    deferredPrompt.value = e;

    if (isMobile.value) {
        dialog.value = true;
    }
});

function closeDialog() {
    dialog.value = false;
}

async function installApp() {
    if (deferredPrompt.value) {
        deferredPrompt.value.prompt();

        const choiceResult = await deferredPrompt.value.userChoice;
        if (choiceResult.outcome === "accepted") {
            console.log("User accepted the A2HS prompt");
        } else {
            console.log("User dismissed the A2HS prompt");
        }

        deferredPrompt.value = null;

        dialog.value = false;
    }
}
</script>
