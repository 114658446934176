<template>
    <div class="slide">
        <main>
            <div id="title">
                Toutes les réponses
                à vos questions
            </div>
            <div id="content">
                Des videos, des quizz, des infos scientifiques !
            </div>
        </main>

        <div class="intro-background">
            <img :src="require('@/assets/svg/intro/foot.svg')" :alt="$t('global.app')" height="250">
        </div>
    </div>
</template>

<script lang="ts" setup>
</script>

<style>
main {
    #title {
        font-weight: 700;
        font-size: 44px;
        color: #13002C;

    }

    #content {
        font-size: 20px;
        color: #13002C;
    }
}

.intro-background {
    justify-content: flex-end !important;
}
</style>