<template>
    <Line
        :chartData="chartData"
        :chartOptions="chartOptions"
        :plugins="plugins"
    >
    </Line>
</template>

<script lang="ts" setup>
import {Line} from "vue-chartjs";
import {CategoryScale, Chart as ChartJS, Colors, Filler, LinearScale, LineElement, PointElement, Title} from "chart.js";
import {computed, toRefs} from "vue";
import {useI18n} from "vue-i18n";
import moment from "moment";

const i18n = useI18n();
ChartJS.register(
    Title,
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale,
    Colors,
    Filler,
);

const props = defineProps<{
    history: any
}>();
const {history} = toRefs(props);

const plugins = [];

const dataMap = computed(() => {
    if (!history.value) {
        return;
    }

    const labels = [];
    const values = [];

    for (const historyLine of (history.value ?? []).slice(-5)) {
        labels.push(moment(historyLine.date).format("ll"));
        values.push(historyLine.painLevel);
    }

    const {t} = useI18n();

    return {
        values: values,
        labels: labels,
    };

});

const chartData = computed(() => {
    return {
        datasets: [{
            data: dataMap.value?.values,
            fill: false,
            showLine: true,
        }],
        labels: dataMap.value?.labels,
    };
});

const chartOptions = computed(() => {
    return {
        responsive: true,
        scales: {
            y: {
                min: 0,
                max: 10,
            },
        },
    };
});
</script>