/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NewPatientType = {
    email: string;
    firstname: string;
    lastname: string;
    birthdate: string;
    gender: NewPatientType.gender;
    country: string;
    city: string;
    plainPassword: string;
};

export namespace NewPatientType {

    export enum gender {
        MALE = 'Male',
        FEMALE = 'Female',
    }


}

