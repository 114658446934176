import {useMutation} from "@tanstack/vue-query";
import {CredentialType, NewPatientType, OpenAPI, SecurityService} from "@/.generated/api";
import {computed, ref, Ref} from "vue";

const TOKEN_STORAGE_KEY = "security.token";
const TOKEN_REFRESH_STORAGE_KEY = "security.token_refresh";

interface ICredentials {
    username: string;
    password: string;
}

interface IJwtPayload {
    exp: number;
    iat: number;
    sub: string;
    username: string;
    roles: string[];
}

class SecurityManager {
    public _token: Ref<string | undefined> = ref(undefined);

    public useTokenMutation() {
        return useMutation({
            mutationFn: (credentials: CredentialType) => this.loginWithCredentials(credentials),
        });
    }

    public useRegisterMutation() {
        return useMutation({
            mutationFn: (newPatientType: NewPatientType) => SecurityService.postPatientSecurityRegister(newPatientType),
        });
    }

    private async loginWithCredentials(credentials: CredentialType) {
        OpenAPI.TOKEN = undefined;
        const response = await SecurityService.postPatientSecurityToken(credentials);

        this.useTokens({
            token: response.token ?? undefined,
            tokenRefresh: response.tokenRefresh ?? undefined,
        });
    }

    public useTokenFromLocalStorage(): void {
        const jwtToken = localStorage.getItem(TOKEN_STORAGE_KEY);
        if (jwtToken) {
            this.useTokens({token: jwtToken});
        }
    }

    private useTokens(tokens: { token?: string; tokenRefresh?: string }): void {
        if (tokens.token) {
            this._token.value = tokens.token;
            OpenAPI.TOKEN = tokens.token;
            localStorage.setItem(TOKEN_STORAGE_KEY, tokens.token);
        }

        if (tokens.tokenRefresh) {
            localStorage.setItem(TOKEN_REFRESH_STORAGE_KEY, <string>tokens.tokenRefresh);
        }
    }

    public get token(): Ref<string | undefined> {
        return this._token;
    }

    private jwtPayload: Ref<IJwtPayload> = computed(() => {
        if (!this.token.value) {
            return undefined;
        }

        try {
            return JSON.parse(window.atob((this.token.value ?? "").split(".")[1]));
        } catch (e) {
            return undefined;
        }
    });

    public isAuthenticated(): boolean {
        if (!this.jwtPayload.value) {
            return false;
        }

        return this.jwtPayload.value.exp * 1000 >= +new Date();
    };

    public async tryToRefreshToken(): Promise<boolean> {
        try {
            OpenAPI.TOKEN = undefined;
            const tokenRefresh = localStorage.getItem(TOKEN_REFRESH_STORAGE_KEY);

            if (!tokenRefresh) {
                return false;
            }

            const response = await SecurityService.postPatientSecurityTokenRefresh({
                tokenRefresh: tokenRefresh,
            });

            this.useTokens({
                token: response.token ?? undefined,
                tokenRefresh: response.tokenRefresh ?? undefined,
            });

            return true;
        } catch (e) {
            return false;
        }
    }

    public logout() {
        this._token.value = undefined;
        OpenAPI.TOKEN = undefined;
        localStorage.removeItem(TOKEN_STORAGE_KEY);
        localStorage.removeItem(TOKEN_REFRESH_STORAGE_KEY);
    }
}

export default new SecurityManager();