<template>
    <div id="crisis-detail">
        <template v-if="crisis.followingLines && crisis.followingLines.length >= 1">

            <section style="background-color: rgba(255, 206, 68, 0.2);" v-if="crisis.followingLines">
                <h3>Douleur</h3>

                <CrisisHistoryLineChart :history="crisis.followingLines"/>
            </section>
            <section style="background-color: rgba(255, 206, 68, 0.2);" v-if="crisis.triggerFactors && crisis.triggerFactors.length >= 2">
                <h3>Facteurs Déclenchants</h3>

                <ul>
                    <li
                        v-for="triggerFactor in crisis.triggerFactors"
                    >
                        {{ $t(`crisis.triggerFactors.${filters.toSnakeCase(triggerFactor)}`) }}
                    </li>
                </ul>
            </section>
            <section style="background-color: rgba(0, 100, 180, 0.2);">
                <h3>Résumé</h3>
                <ul>
                    <template v-for="followingLine of (crisis.followingLines as any[])">
                        <li>
                            <b>{{ filters.date(followingLine.date, "L") }}</b> :
                            <ul>
                                <li>
                                    <b>Niveau de douleur :</b> {{ followingLine.painLevel }}
                                </li>
                                <li v-if="followingLine.treatmentTook">
                                    <b>Médicament :</b> {{
                                        $t(`crisis.treatment.crisis.${followingLine.treatmentTook}`)
                                    }}
                                </li>
                                <li v-if="followingLine.feelings.length > 0">
                                    <b>Ressentis :</b>
                                    {{ followingLine.feelings.map(el => $t(`crisis.feelings.${el}`)).join(", ") }}
                                </li>
                                <li v-if="followingLine.symptoms.length > 0">
                                    <b>Symptomes :</b>
                                    {{ followingLine.symptoms.map(el => $t(`crisis.symptoms.${filters.toSnakeCase(el)}`)).join(", ") }}
                                </li>
                            </ul>
                        </li>
                    </template>
                </ul>
            </section>
        </template>
        <template v-else>
            Vous n'avez pas indiqué d'information sur la crise
        </template>
    </div>
</template>

<script lang="ts" setup>
import {toRefs} from "vue";
import filters from "@/filters";
import {Crisis2} from "@/.generated/api";
import CrisisHistoryLineChart from "@/components/gout/crisisHistory/CrisisHistoryLineChart.vue";

const props = defineProps<{ crisis: Crisis2 }>();
const {crisis} = toRefs(props);
</script>

<style lang="scss" scoped>
ul {
    padding-inline-start: 40px !important;
}

#crisis-detail {

    > section {
        border-radius: 20px;
        padding: 20px;
        margin-bottom: 20px;
    }
}
</style>