export default {
  "positioning": {
    "sendPositioning": {
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "sendDiagnosis": {
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    }
  },
  "crisis": {
    "sendFinished": {
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End of crisis"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your end of crisis has been saved!"])}
      }
    },
    "sendDailyFollowing": {
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crisis follow-up"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your daily follow-up has been saved!"])}
      }
    }
  },
  "crisiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crisis"])}
}