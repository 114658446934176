<template>
    <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
            <v-card-text>
                <v-container>
                    <v-row align="center">
                        <v-col class="d-flex justify-center" cols="12">
                            <span class="mdi mdi-48px mdi-cellphone-download-outline"></span>
                        </v-col>
                        <v-col class="text-center" cols="12">
                            <h2>{{ $t("pushNotification.permission.dialog.title") }}</h2>
                        </v-col>
                        <v-col class="text-center" cols="12">
                            <p class="mb-5">
                                {{ $t("pushNotification.permission.dialog.content") }}
                            </p>
                        </v-col>
                        <v-col class="d-flex justify-center" cols="12">
                            <v-btn color="primary" @click="subscribeToNotifications" class="white--text">
                                {{ $t("pushNotification.permission.dialog.activate") }}
                            </v-btn>
                        </v-col>
                        <v-col class="d-flex justify-center" cols="12">
                            <v-btn @click="closeDialog" text color="primary">
                                {{ $t("pushNotification.permission.dialog.close") }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>


<script lang="ts" setup>
import {onMounted, ref} from "vue";
import Firebase from "@/plugins/firebase";
import Notify, {NotificationType} from "@/notify";
import MessagingManager from "@/managers/MessagingManager";

const REFUSED_TO_SUBSCRIBE_TO_NOTIFICATION = "refusedToSubscribeToNotification";

const refusedToSubscribeToNotificationScreen = ref(!!sessionStorage.getItem(REFUSED_TO_SUBSCRIBE_TO_NOTIFICATION));

const dialog = ref<boolean>(false);

const patientUpdateMutation = MessagingManager.usePostPatientMessagingSubscribeMutation();


function closeDialog() {
    dialog.value = false;
    sessionStorage.setItem(REFUSED_TO_SUBSCRIBE_TO_NOTIFICATION, true);
}

async function subscribeToNotifications() {
    try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
            const token = await Firebase.getToken();

            if (!token) {
                throw new Error();
            }

            await patientUpdateMutation.mutateAsync({
                token: token,
            });

            Notify.addNotification({
                title: "pushNotification.permission.success.title",
                content: "pushNotification.permission.success.title",
                icon: "mdi-check",
                type: NotificationType.SUCCESS,
            });
        } else {
            Notify.addNotification({
                title: "pushNotification.permission.refused.title",
                content: "pushNotification.permission.refused.content",
                icon: "mdi-check",
                type: NotificationType.WARNING,
            });
        }
    } catch (error) {
        Notify.addNotification({
            title: "pushNotification.permission.error.title",
            content: "pushNotification.permission.error.title",
            icon: "mdi-check",
            type: NotificationType.ERROR,
        });
    }

    dialog.value = false;
}

onMounted(() => {
        if (Notification.permission === "default" && !refusedToSubscribeToNotificationScreen.value) {
            dialog.value = true;
        }
    },
);
</script>
