import {useQuery} from "@tanstack/vue-query";
import {TreatmentService} from "@/.generated/api";

export default abstract class TreatmentManager {
    public static useTreatmentListQuery(type: "crisis" | "daily") {
        return useQuery({
            queryKey: ["treatment", "list", type],
            queryFn: () => TreatmentService.getPatientTreatmentList(type),
        });
    }

    public static useTreatmentShowQuery(treatmentId: string) {
        return useQuery({
            queryKey: ["treatment", "show", treatmentId],
            queryFn: () => TreatmentService.getPatientTreatmentShow(treatmentId),
        });
    }
}