<template>
    <div id="home-view">
        <div class="advice" v-if="lastUalMoreThan1Month ">
            <div class="advice-text">
                <div class="advice-title">
                    {{ userData.actionTitle ?? "Vite fait, bien fait !" }}
                </div>
                <div class="advice-content">
                    {{ userData.actionMessage ?? "Avez-vous pensé à vérifier votre taux d’acide urique ?" }}
                </div>
            </div>
            <div v-if="userData.actionKey === 'goToDoctolibPractitioner'" class="advice-action">
                <v-btn-success @click.prevent="toDoctorDoctolib">
                    {{ userData.actionButtonName }}
                </v-btn-success>
            </div>
            <div v-else-if="userData.actionKey === 'goToDoctolibLabo'" class="advice-action">
                <v-btn-success @click.prevent="toLaboratoryDoctissimo">
                    {{ userData.actionButtonName }}
                </v-btn-success>
            </div>
            <div v-else-if="userData.actionKey === 'doNothing'" class="advice-action">
                <v-btn-success @click.prevent="doNothing">
                    {{ userData.actionButtonName }}
                </v-btn-success>
            </div>
            <div v-else class="advice-action">
                <v-btn-success @click.prevent="toLaboratoryDoctissimo">
                    {{ userData.actionButtonName ?? "Prendre RV" }}
                </v-btn-success>
            </div>
        </div>

        <div id="my-monitoring">
            <h2>
                <img :src="require('@/assets/svg/menu/monitoring.svg')" :alt="$t('global.myMonitoring')">
                {{ $t("global.myMonitoring") }}
            </h2>
            <Swiper
                :modules="[Mousewheel]"
                :mousewheel="true"
                :space-between="30"
                :width="200"
            >
                <SwiperSlide>
                    <router-link :to="{name: 'ual'}" style="background-color: #FFCE44;">
                        <img :src="require('@/assets/svg/home/ualHistoric.svg')" :alt="$t('global.ualLevel')">
                        <div v-if="lastUal">
                            <h3>{{ $t("global.ualLevel") }}</h3>
                            du {{ filters.date(lastUal.date, "L") }}
                        </div>
                        <div v-else>
                            <h3>{{ $t("global.addUalLevel") }}</h3>
                        </div>
                    </router-link>

                    <div class="last-ual">
                        <div v-if="lastUal" :class="{'text-red': (lastUal.ual >= 300)}">
                            {{ lastUal.ual }} {{ $t("ual.umol/L") }}
                        </div>
                        <img v-else :src="require('@/assets/svg/home/add.svg')" :alt="$t('global.addUalLevel')">
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <router-link
                        :to="{name: 'treatmentList', params: {type: 'daily'}}"
                        style="background-color: #FFA050;"
                    >
                        <img
                            :src="require('@/assets/svg/home/dailyTreatment.svg')"
                            :alt="$t('global.treatment.daily')"
                        />
                        <h3>{{ $t("global.treatment.daily") }}</h3>
                    </router-link>
                </SwiperSlide>
                <SwiperSlide>
                    <a
                        :href="'https://www.doctolib.fr/rhumatologue/' + userData.city.toLowerCase()" target="_blank"
                        style="background-color: #0064B4;"
                    >
                        <img
                            :src="require('@/assets/svg/home/practitioner.svg')"
                            :alt="$t('global.doctorAppointment')"
                        />
                        <h3>{{ $t("global.doctorAppointment") }}</h3>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a
                        :href="'https://www.doctolib.fr/laboratoire-de-biologie/' + userData.city.toLowerCase()"
                        target="_blank"
                        style="background-color: #FFA050;"
                    >
                        <img
                            :src="require('@/assets/svg/home/laboratory.svg')"
                            :alt="$t('global.laboratoryAppointment')"
                        />
                        <h3>{{ $t("global.laboratoryAppointment") }}</h3>
                    </a>
                </SwiperSlide>
            </Swiper>
        </div>

        <div id="education">
            <h2>
                <img :src="require('@/assets/svg/menu/education.svg')" :alt="$t('global.understand')">
                {{ $t("global.understand") }}
            </h2>
            <Swiper
                :modules="[Mousewheel]"
                :mousewheel="true"
                :space-between="30"
                :width="200"
            >
                <SwiperSlide v-if="moduleListQuery.data" v-for="module in moduleListQuery.data.value">
                    <div @click.prevent="toModule(module)">
                        <ModuleDoughnutChart :module="module"/>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>

        <div id="crisis">
            <h2>
                <img :src="require('@/assets/svg/menu/crisis.svg')" :alt="$t('global.iHaveAGoutCrisis')">
                {{ $t("global.iHaveAGoutCrisis") }}
            </h2>
            <Swiper
                :modules="[Mousewheel]"
                :mousewheel="true"
                :space-between="30"
                :slides-per-view="2.3"
            >
                <SwiperSlide
                    :style="`background-color: ${Color.adjustColorBrightness('#FF0000', 30)}`"
                    :class="{deactivated: hasCurrentCrisis}"
                >
                    <router-link :to="{name: 'goutCrisisDiagnosis'}">
                        <img :src="require('@/assets/svg/home/diagnosis.svg')" :alt="$t('global.diagnosis')">
                        <h3>{{ $t("global.diagnosis") }}</h3>
                    </router-link>
                </SwiperSlide>

                <SwiperSlide
                    :style="`background-color: ${Color.adjustColorBrightness('#FF0000', 30)};`"
                    :class="{deactivated: !userData.needingToFillDailyCrisis}"
                >
                    <router-link :to="{name: 'goutCrisisDailyFollowing'}">
                        <img :src="require('@/assets/svg/home/dailyFollowing.svg')" :alt="$t('global.dailyFollowing')">
                        <h3>{{ $t("global.dailyFollowing") }}</h3>
                    </router-link>
                </SwiperSlide>

                <SwiperSlide
                    :style="`background-color: ${Color.adjustColorBrightness('#FF0000', 30)}`"
                >
                    <router-link :to="{name: 'goutCrisisHistoric'}">
                        <img
                            :src="require('@/assets/svg/home/goutCrisisHistoric.svg')"
                            :alt="$t('global.crisisHistoric')"
                        >
                        <h3>{{ $t("global.crisisHistoric") }}</h3>
                    </router-link>
                </SwiperSlide>

                <SwiperSlide
                    :style="`background-color: #FFA050;`"
                >
                    <router-link
                        :to="{name: 'treatmentList', params: {type: 'crisis'}}"
                    >
                        <img
                            :src="require('@/assets/svg/home/dailyTreatment.svg')"
                            :alt="$t('global.treatment.crisis')"
                        />

                        <h3>{{ $t("global.treatment.crisis") }}</h3>
                    </router-link>
                </SwiperSlide>
            </Swiper>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {useRouter} from "vue-router";
import {Swiper, SwiperSlide} from "swiper/vue";
import ModuleDoughnutChart from "@/components/learning/ModuleDoughnutChart.vue";
import ModuleManager from "@/managers/ModuleManager";
import {Module} from "@/.generated/api";
import MeManager from "@/managers/MeManager";
import {computed} from "vue";
import filters from "../filters";
import Color from "@/utils/Color";
import {Mousewheel} from "swiper";

const router = useRouter();

const ualListQuery = MeManager.useUalListQuery();
const moduleListQuery = ModuleManager.useModuleProfileListQuery();
const profileQuery = MeManager.useGetPatient();

// TODO REMOVE ALL KEYS BEFORE PROFILEQUERY.DATA
const userData = computed(() => {
        return {
            firstname: profileQuery.data.value?.firstname ?? "",
            lastname: profileQuery.data.value?.lastname ?? "",
            email: profileQuery.data.value?.email ?? "",
            age: profileQuery.data.value?.age ?? null,
            city: profileQuery.data.value?.city ?? "",
            practitioner: profileQuery.data.value?.practitioner ?? "",
            actionKey: profileQuery.data.value?.action?.action ?? "",
            actionTitle: profileQuery.data.value?.action?.title,
            actionMessage: profileQuery.data.value?.action?.description,
            actionButtonName: profileQuery.data.value?.action?.buttonNamePatient,
            ...profileQuery.data.value,
        };
    },
);
const lastUal = computed(() => {
    return ualListQuery.data.value?.ualHistory?.[ualListQuery.data.value.ualHistory.length - 1];
});

const lastUalMoreThan1Month = computed(() => {
    if (!lastUal.value) {
        return true;
    }

    const lastUalDate = new Date(lastUal.value.date);
    const oneMonthAgoDate = new Date();
    oneMonthAgoDate.setMonth(oneMonthAgoDate.getMonth() - 1);

    return lastUalDate < oneMonthAgoDate;
});

const toUal = () => {
    router.push({name: "ual"});
};
const toDoctorDoctolib = () => {
    alert("Vous allez être rediriger vers Doctolib.");
    window.open("https://www.doctolib.fr/rhumatologue/" + userData.city.toLowerCase());
};

const toLaboratoryDoctissimo = () => {
    alert("Vous allez être rediriger vers Doctolib.");
    window.open("https://www.doctolib.fr/laboratoire-de-biologie/" + userData.city.toLowerCase());
};

const doNothing = () => {
    alert("Vous êtes sur la bonne voie");
};


const toModule = (module: Module) => {
    router.push({name: "module", params: {"moduleId": module.id}});
};

const toContentOrQuiz = (module: Module, contentOrQuizId: number, type: ("content" | "quiz")) => {
    const step = (type === "content" ? "c" : "q") + contentOrQuizId;

    router.push({name: "module", params: {"moduleId": module.id}, query: {step: step}});
};

const hasCurrentCrisis = computed(() => {
    return !!userData.value.currentCrisis;
});
</script>

<style lang="scss" scoped>
#home-view {
    .advice {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;

        background: #FFFFFF;
        border-radius: 10px;
        padding: 30px;
        margin: 30px 0 50px 0;

        > .advice-text {
            width: 100%;
            line-height: 27px;
            font-size: 22px;

            > .advice-title {
                font-weight: bold;
            }

            > .advice-content {
                margin-bottom: 10px;
            }
        }
    }

    h2 {
        display: flex;
        align-items: center;

        font-size: 25px;
        margin: 0 0 10px;

        img {
            max-width: 44px;
            max-height: 44px;
            padding-right: 5px;
        }
    }

    #my-monitoring, #education, #crisis {
        margin: 30px 0;
    }

    #my-monitoring {
        .swiper-slide {
            position: relative;
            min-height: 275px;
            max-height: 275px;
            min-width: 200px;
            max-width: 200px;
            text-align: center;

            > a {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                border-radius: 10px;
                padding: 5px 15px 20px;
                box-sizing: border-box;

                min-width: 100%;
                max-width: 100%;
                min-height: 250px;
                max-height: 250px;

                > img {
                    height: 100px;
                }
            }

            > .last-ual {
                position: absolute;
                bottom: 0;

                left: 0;
                right: 0;

                display: flex;
                justify-content: center;

                > div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 48px;

                    border-radius: 80px;
                    border: 1px solid #66C1BF;
                    background: #FFFFFF;

                    padding: 0 10px;

                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                }

                > img {
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }

    #education {
        padding: 20px 10px;
        border-radius: 20px;
        background: #FFF;
    }

    #crisis {
        .swiper-slide {
            border-radius: 20px;
            min-height: 250px;
            max-height: 250px;
            min-width: 200px;
            max-width: 200px;

            div, a {
                height: 180px;

                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                padding: 20px;

                > img {
                    max-width: 100%;
                    max-height: 100%;
                }

                h3 {
                    text-align: center;
                }
            }
        }

        .deactivated {
            position: relative;
            cursor: not-allowed;

            &:before {
                pointer-events: none;
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba(lightgrey, .5);
                border-radius: 20px;
            }
        }
    }

    #my-monitoring img, #crisis img {
        height: 100px;
    }
}

</style>