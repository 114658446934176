<template>
    <div id="intro-buttons">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <router-link :to="{name: 'login'}">
                        <v-btn-success>
                            Se connecter
                        </v-btn-success>
                    </router-link>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <router-link :to="{name: 'registration'}">
                        <v-btn-success>
                            S'enregistrer
                        </v-btn-success>
                    </router-link>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts" setup>

</script>

<style scoped>
#intro-buttons {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
</style>