export default {
  "positioning": {
    "sendPositioning": {
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positionnement sauvegardé."])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos informations viennent d'être sauvegardé."])}
      }
    },
    "sendDiagnosis": {
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagnostic sauvegardé"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre diagnostic a été sauvegardé avec succès."])}
      }
    }
  },
  "crisis": {
    "sendFinished": {
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin de crise"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre fin de crise a bien été sauvegardé !"])}
      }
    },
    "sendDailyFollowing": {
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi de la crise"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre suivi journalier a bien été sauvegardé !"])}
      }
    }
  },
  "crisiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crises"])}
}