<template>
    <main id="module-view">
        <div id="education">
            <h2 class="mb-5">
                <img :src="require('@/assets/svg/menu/education.svg')" :alt="$t('global.understand')">
                {{ $t("global.understand") }}
            </h2>


            <div class="mb-10">
                Les contenus de cette rubrique ont été élaborés avec l’aide du centre expert de l’hôpital
                Lariboisière à Paris.
            </div>

            <div id="modules">
                <router-link
                    v-if="moduleListQuery.data"
                    v-for="module in moduleListQuery.data.value"
                    :to="{name: 'module', params: {'moduleId': module.id}}"
                >
                    <ModuleDoughnutChart :module="module"/>
                </router-link>
            </div>
        </div>
    </main>
</template>

<script lang="ts" setup>
import ModuleManager from "@/managers/ModuleManager";
import ModuleDoughnutChart from "@/components/learning/ModuleDoughnutChart.vue";

const moduleListQuery = ModuleManager.useModuleListQuery();
</script>

<style lang="scss" scoped>
#module-view {
    #education {
        margin-top: 40px;
    }

    #modules {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        > a {
            min-width: 150px;
            max-width: 150px;

            margin-bottom: 40px;
        }
    }

}
</style>