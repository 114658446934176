<template>
    <svg width="262" height="494" viewBox="0 0 262 494" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M65.6704 63.9924V0.10943C81.3189 8.85169 95.8488 15.5391 108.762 24.561C123.899 35.1271 136.982 35.8323 152.315 24.7677C165.143 15.5269 179.831 8.86385 195.552 0V64.3207C152.096 45.292 109.126 46.3863 65.6704 63.9924Z"
            fill="#DD3354"
        />
        <path
            d="M196.026 319.342V494C177.532 483.495 160.255 474.473 143.864 464.041C134.441 458.034 127.036 458.204 117.71 464.053C101.636 474.157 84.7841 483.021 66.2661 493.538V320.923C97.3443 337.884 167.951 337.556 196.026 319.342Z"
            fill="#DD3354"
        />
        <g clip-path="url(#clip0_132_642)">
            <path
                d="M259.317 193.175C259.157 264.204 202.503 320.275 131.386 319.779C60.3425 319.298 3.67381 262.119 4.3736 191.615C5.08797 120.79 62.923 63.5526 132.859 64.442C204.048 65.3604 259.478 121.781 259.317 193.16V193.175ZM233.075 193.204C233.192 136.783 188.653 91.2091 133.019 90.8009C77.8957 90.4072 30.922 136.433 30.3097 191.425C29.6974 246.695 75.3298 292.939 131.095 293.566C186.918 294.178 232.958 248.881 233.075 193.204Z"
                fill="#FFDE59"
            />
            <path
                d="M220.493 193.642C220.129 243.662 181.319 281.437 130.92 280.839C81.759 280.256 41.7687 239.522 42.8038 191.09C43.8389 142.178 84.8498 102.421 133.369 103.281C182.938 104.17 220.872 143.504 220.508 193.642H220.493Z"
                fill="white"
            />
        </g>
        <text
            x="50%"
            y="44%"
            text-anchor="middle"
            fill="black"
            font-size="70"
            font-weight="800"
        >
            {{ score }}%
        </text>
        <path
            d="M82.2322 398.741C82.2322 395.808 83.2539 393.398 85.2979 391.509C87.342 389.622 89.9131 388.677 93.0112 388.677C94.826 388.677 96.48 389.025 97.9746 389.722C99.4685 390.419 100.683 391.4 101.618 392.664L101.865 392.994L101.535 393.242L98.1528 395.881L97.7952 396.129L97.5204 395.799C96.4383 394.46 94.9356 393.791 93.0106 393.791C91.397 393.791 90.0588 394.255 88.996 395.18C87.9326 396.106 87.4012 397.293 87.4012 398.741C87.4012 400.189 87.9326 401.377 88.996 402.302C90.0588 403.228 91.3977 403.691 93.0106 403.691C94.9169 403.691 96.3928 403.306 97.4376 402.536V401.243H93.9177V397.339H102.057V404.818L101.974 404.928C101.039 406.12 99.7651 407.06 98.1521 407.747C96.5386 408.435 94.8247 408.778 93.01 408.778C89.9118 408.778 87.3407 407.834 85.2967 405.946C83.2527 404.058 82.231 401.656 82.231 398.741H82.2322Z"
            fill="white"
        />
        <path
            d="M106.128 406.523C104.497 405.02 103.681 403.104 103.681 400.776C103.681 398.448 104.497 396.538 106.128 395.043C107.76 393.549 109.841 392.802 112.37 392.802C114.899 392.802 116.98 393.549 118.612 395.043C120.244 396.538 121.059 398.448 121.059 400.776C121.059 403.104 120.243 405.021 118.612 406.523C116.981 408.027 114.9 408.778 112.37 408.778C109.84 408.778 107.759 408.027 106.128 406.523ZM115.12 403.375C115.853 402.706 116.219 401.84 116.219 400.776C116.219 399.713 115.853 398.851 115.12 398.192C114.387 397.532 113.47 397.202 112.37 397.202C111.27 397.202 110.349 397.532 109.606 398.192C108.864 398.851 108.493 399.714 108.493 400.776C108.493 401.839 108.864 402.706 109.606 403.375C110.349 404.044 111.27 404.379 112.37 404.379C113.47 404.379 114.387 404.044 115.12 403.375Z"
            fill="white"
        />
        <path
            d="M122.681 393.132H127.658V401.298C127.658 402.215 127.832 402.939 128.181 403.471C128.529 404.003 129.171 404.268 130.105 404.268C130.948 404.268 131.677 404.117 132.292 403.815C132.906 403.512 133.35 403.104 133.625 402.591V393.132H138.602V408.42H133.625V406.991C132.158 408.183 130.481 408.778 128.593 408.778C126.979 408.778 125.591 408.26 124.427 407.224C123.263 406.189 122.681 404.809 122.681 403.086V393.131L122.681 393.132Z"
            fill="white"
        />
        <path
            d="M142.232 388.979H147.485V393.132H151.72V397.642H147.485V402.069C147.485 402.858 147.581 403.44 147.774 403.815C147.966 404.191 148.356 404.379 148.943 404.379C149.328 404.379 149.699 404.32 150.057 404.2C150.414 404.081 150.675 403.967 150.84 403.857L151.088 403.664L151.5 403.279L151.747 403.801L153.012 406.716L153.094 406.963L152.929 407.156C151.847 408.237 150.399 408.778 148.584 408.778C147.631 408.778 146.788 408.677 146.054 408.475C145.321 408.274 144.73 407.966 144.281 407.554C143.832 407.142 143.465 406.711 143.181 406.262C142.897 405.813 142.686 405.258 142.549 404.598C142.412 403.939 142.324 403.347 142.288 402.825C142.251 402.302 142.233 401.675 142.233 400.941V397.641H139.73V393.131H142.233V388.979L142.232 388.979Z"
            fill="white"
        />
        <path
            d="M155.734 388.979H160.986V393.132H165.221V397.642H160.986V402.069C160.986 402.858 161.083 403.44 161.275 403.815C161.468 404.191 161.857 404.379 162.444 404.379C162.829 404.379 163.2 404.32 163.558 404.2C163.916 404.081 164.177 403.967 164.342 403.857L164.589 403.664L165.001 403.279L165.249 403.801L166.513 406.716L166.596 406.963L166.431 407.156C165.348 408.237 163.901 408.778 162.086 408.778C161.132 408.778 160.289 408.677 159.556 408.475C158.823 408.274 158.231 407.966 157.782 407.554C157.333 407.142 156.966 406.711 156.683 406.262C156.398 405.813 156.187 405.258 156.05 404.598C155.913 403.939 155.825 403.347 155.789 402.825C155.753 402.302 155.734 401.675 155.734 400.941V397.641H153.232V393.131H155.734V388.979L155.734 388.979Z"
            fill="white"
        />
        <path
            d="M168.644 406.523C167.003 405.02 166.184 403.104 166.184 400.776C166.184 398.448 167.008 396.538 168.658 395.043C170.308 393.549 172.407 392.802 174.955 392.802C177.229 392.802 179.112 393.485 180.606 394.85C182.1 396.217 182.847 398.164 182.847 400.694C182.847 401.226 182.829 401.639 182.793 401.931L182.738 402.289H171.298C171.555 402.931 172.004 403.435 172.646 403.802C173.288 404.169 174.039 404.352 174.901 404.352C176.294 404.352 177.366 403.802 178.118 402.701L178.338 402.371L178.696 402.564L182.216 404.296L182.601 404.489L182.381 404.874C181.647 406.084 180.625 407.037 179.314 407.734C178.004 408.43 176.532 408.779 174.901 408.779C172.371 408.779 170.285 408.027 168.645 406.524L168.644 406.523ZM177.815 398.384C177.503 397.596 176.531 397.202 174.9 397.202C173.543 397.202 172.498 397.596 171.765 398.384H177.814H177.815Z"
            fill="white"
        />
        <path
            d="M103.242 364.279H109.072L109.181 364.554L116.826 383.115L117.046 383.666H111.409L111.298 383.39L110.034 380.173H102.279L101.015 383.39L100.904 383.666H95.2676L95.4875 383.115L103.132 364.554L103.242 364.279H103.242ZM108.219 375.993L106.157 370.631L104.067 375.993H108.22H108.219Z"
            fill="white"
        />
        <path
            d="M122.876 369.229C124.086 368.441 125.516 368.047 127.166 368.047C129.659 368.047 131.652 368.789 133.147 370.274C134.641 371.759 135.388 373.675 135.388 376.021C135.388 378.367 134.641 380.288 133.147 381.782C131.653 383.276 129.659 384.023 127.166 384.023C125.498 384.023 124.068 383.629 122.876 382.84V389.165H117.899V368.376H122.876V369.229ZM129.448 378.606C130.181 377.946 130.548 377.084 130.548 376.021C130.548 374.957 130.181 374.096 129.448 373.436C128.715 372.776 127.798 372.446 126.698 372.446C125.598 372.446 124.686 372.776 123.962 373.436C123.237 374.096 122.876 374.958 122.876 376.021C122.876 377.084 123.238 377.946 123.962 378.606C124.686 379.265 125.598 379.596 126.698 379.596C127.798 379.596 128.715 379.265 129.448 378.606Z"
            fill="white"
        />
        <path
            d="M141.987 369.229C143.197 368.441 144.626 368.047 146.277 368.047C148.77 368.047 150.763 368.789 152.257 370.274C153.751 371.759 154.498 373.675 154.498 376.021C154.498 378.367 153.751 380.288 152.257 381.782C150.764 383.276 148.769 384.023 146.277 384.023C144.608 384.023 143.179 383.629 141.987 382.84V389.165H137.01V368.376H141.987V369.229ZM148.559 378.606C149.291 377.946 149.658 377.084 149.658 376.021C149.658 374.957 149.291 374.096 148.559 373.436C147.826 372.776 146.908 372.446 145.809 372.446C144.709 372.446 143.797 372.776 143.073 373.436C142.348 374.096 141.987 374.958 141.987 376.021C141.987 377.084 142.349 377.946 143.073 378.606C143.797 379.265 144.709 379.596 145.809 379.596C146.909 379.596 147.826 379.265 148.559 378.606Z"
            fill="white"
        />
        <path d="M156.121 362.904H160.961V383.666H156.121V362.904Z" fill="white"/>
        <path
            d="M163.133 371.211C163.133 371.211 164.204 371.585 165.564 371.585C166.924 371.585 167.973 371.211 167.973 371.211V383.665H163.133V371.211Z"
            fill="white"/>
        <path
            d="M166.467 360.488C172.903 367.446 167.035 371.321 163.896 368.803C162.529 367.704 162.185 365.764 163.031 364.225C163.733 362.949 164.928 361.84 165.669 360.569C165.841 360.283 166.242 360.244 166.467 360.488L166.467 360.488Z"
            fill="white"
        />
        <defs>
            <clipPath id="clip0_132_642">
                <rect width="254.944" height="255.337" fill="white" transform="translate(4.37354 64.4423)"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script lang="ts" setup>
import {toRefs} from "vue";

const props = defineProps<{
    score: number
}>();

const {score} = toRefs(props);
</script>

<style lang="scss" scoped>

</style>