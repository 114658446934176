import {useMutation, useQuery, useQueryClient} from "@tanstack/vue-query";
import {ClinicalTestService, FollowUpType} from "@/.generated/api";
import {computed, Ref} from "vue";

export default abstract class ClinicalTestManager {
    public static usePatientClinicalTestSessionQuery(invitationCode: Ref<string>) {
        return useQuery({
            queryKey: ["clinicalTest", "session", invitationCode],
            queryFn: () => ClinicalTestService.getPatientClinicalTestSession(invitationCode.value),
            enabled: computed(() => !!invitationCode && invitationCode.value.length === 6),
        });
    }

    public static usePatientClinicalTestFollowUpMutation() {
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: (followUpType: FollowUpType) => ClinicalTestService.postPatientClinicalTestFollowUp(followUpType),
            onSuccess: async () => {
                await queryClient.invalidateQueries({queryKey: ["patient", "me", "profile"]});
            },
        });
    }

    public static usePatientClinicalTestFollowUpIgnoreMutation() {
        return useMutation({
            mutationFn: ClinicalTestService.patchPatientClinicalTestFollowUpIgnore,
        });
    }
}