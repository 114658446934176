/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FollowUp } from '../models/FollowUp';
import type { FollowUpType } from '../models/FollowUpType';
import type { Patient } from '../models/Patient';
import type { Session } from '../models/Session';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ClinicalTestService {

    /**
     * Add a follow up to patient
     * Permit to add a follow up to patient
     * @param requestBody
     * @returns FollowUp Add a follow up to patient
     * @throws ApiError
     */
    public static postPatientClinicalTestFollowUp(
        requestBody?: FollowUpType,
    ): CancelablePromise<FollowUp> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/patient/clinicalTest/followUp',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Ignore Follow up
     * Permit to ignore a follow up to patient
     * @returns Patient Ignore a follow up to patient
     * @throws ApiError
     */
    public static patchPatientClinicalTestFollowUpIgnore(): CancelablePromise<Patient> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/patient/clinicalTest/followUp/ignore',
        });
    }

    /**
     * Find a session by invitation code
     * Permit to find a session by invitation code.
     * @param invitationCode
     * @returns Session Find a session by invitation code
     * @throws ApiError
     */
    public static getPatientClinicalTestSession(
        invitationCode: string,
    ): CancelablePromise<Session> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/patient/clinicalTest/session/{invitationCode}',
            path: {
                'invitationCode': invitationCode,
            },
        });
    }

}
