<template>
    <footer id="footer" :style="marginFullWidth">
        <nav>
            <router-link :to="{name: 'home'}">
                <img :src="require('@/assets/svg/menu/home.svg')" alt="Accueil">
                <div>Accueil</div>
            </router-link>

            <router-link :to="{name: 'moduleList'}">
                <img :src="require('@/assets/svg/menu/education.svg')" alt="Comprendre">
                <div>Comprendre</div>
            </router-link>

            <router-link :to="{name: 'ual'}">
                <img :src="require('@/assets/svg/menu/monitoring.svg')" alt="Mon Suivi">
                <div>Mon Suivi</div>
            </router-link>

            <router-link :to="{name: 'profileView'}">
                <img :src="require('@/assets/svg/menu/profile.svg')" alt="Mon Profil">
                <div>Mon Profil</div>
            </router-link>
        </nav>
    </footer>
</template>

<script setup lang="ts">
import {useAppStore} from "@/stores/AppStore";
import {computed} from "vue";

const appStore = useAppStore();

const marginFullWidth = computed(() => {
    return appStore.appStyle.fullWidth && {
        "margin-left": "0",
        "margin-right": "0",
    };
});

</script>

<style scoped lang="scss">
#footer {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100vw;
    max-width: 600px;
    background-color: #FFFFFF;
    height: 96px;
    padding: 10px;
    border-top: 0.5px solid #66C0BE;

    margin: 0 -30px;
    @media (max-width: 375px) {
        margin: 0 -24px;
    }

    nav {
        display: flex;
        justify-content: space-around;

        > a {
            font-size: 12px;
            text-align: center;
            text-decoration: none;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;

            > img {
                flex: 1;
            }
        }
    }
}
</style>