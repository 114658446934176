<template>
    <v-app theme="goutTheme">
        <template v-if="displaySplashScreen">
            <FullScreenLayout>
                <SplashScreen/>
            </FullScreenLayout>
        </template>
        <template v-else>
            <FullScreenLayout v-if="layout === 'FullScreenLayout'">
                <router-view/>
            </FullScreenLayout>
            <AppLayout v-else>
                <router-view/>
            </AppLayout>
            <Notifications></Notifications>
            <Pwa></Pwa>
            <PushNotification v-if="SecurityManager.isAuthenticated()"></PushNotification>
        </template>
    </v-app>
</template>


<script lang="ts" setup>
import "@/config/axios";
import Notifications from "@/components/utils/notifications/Notifications.vue";
import AppLayout from "@/layouts/AppLayout.vue";
import FullScreenLayout from "@/layouts/FullScreenLayout.vue";
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, Ref, ref, watch} from "vue";
import Pwa from "@/components/popups/Pwa.vue";
import PushNotification from "@/components/popups/PushNotification.vue";
import SecurityManager from "@/managers/SecurityManager";
import securityManager from "@/managers/SecurityManager";
import SplashScreen from "@/views/SplashScreen.vue";
import MeManager from "@/managers/MeManager";
import {AppStyle, useAppStore} from "@/stores/AppStore";

const appStore = useAppStore();
const router = useRouter();
const route = useRoute();

const layout = computed(
    () => {
        return route.meta.layout;
    },
);

const ALREADY_DISPLAYED_SESSION_STORAGE_KEY = "alreadyDisplayedSplashScreen";

const displaySplashScreen = ref(!sessionStorage.getItem(ALREADY_DISPLAYED_SESSION_STORAGE_KEY));


const getPatient = MeManager.useGetPatient({enabled: securityManager.isAuthenticated()});

watch([route, getPatient.data as Ref<any>], () => {
    if (!getPatient.isSuccess.value || !getPatient.data.value) {
        return;
    }

    if (!getPatient.data.value?.followUpAnswered) {
        router.replace({
            name: "selectPractitioner",
        });

        return;
    }

    if (!getPatient.data.value?.positioning) {
        router.replace({
            name: "goutPositioning",
        });

        return;
    }

    if (getPatient.data.value?.needingToFillInformationsForCurrentCrisis === true) {
        router.replace({
            name: "goutCrisisInformations",
        });

        return;
    }
});

watch(route, () => {
    appStore.appStyle = route.meta.appStyle as AppStyle;
});


onMounted(() => {
    if (displaySplashScreen.value) {
        setTimeout(() => {
            displaySplashScreen.value = false;
            sessionStorage.setItem(ALREADY_DISPLAYED_SESSION_STORAGE_KEY, true);
        }, 2000);
    }
});
</script>

<style lang="scss">
body {
    font-family: 'Montserrat', sans-serif;
    background: black;
}

#app {
    margin: auto;
    max-width: 600px;
}
</style>