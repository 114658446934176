<template>
    <div id="registration-confirmation">
        <div id="main">
            <img
                :src="require('@/assets/svg/registration/register_confirmation.svg')"
                alt="Confirmation d'enregistrement"/>
            <h1 id="content">
                Votre compte a bien été créé !
            </h1>
        </div>

        <footer id="footer">
            <router-link :to="{name: 'home'}">
                <v-btn>
                    Continuer
                </v-btn>
            </router-link>
        </footer>

    </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
#registration-confirmation {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #47B9AE;
    border-color: #47B9AE;

    > #header {
        position: relative;
    }

    > #main {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

</style>