<template>
    <main id="treatment-list-view">
        <div>
            <h2 class="mb-5">
                {{ $t(`global.treatment.${type}`) }}
            </h2>

            <hr>

            <div class="treatment-list-container">
                <router-link
                    v-if="treatmentListQuery.data.value"
                    v-for="treatment in treatmentListQuery.data.value"
                    :to="{name: 'treatment', params: {'treatmentId': treatment.id}}"
                    class="treatment"
                >
                    <h3>{{ treatment.name }}</h3>
                    <img :src="treatment.image.signedUrl" :alt="treatment.name">
                </router-link>
            </div>
        </div>
    </main>
</template>

<script lang="ts" setup>
import TreatmentManager from "@/managers/TreatmentManager";
import {useRoute} from "vue-router";
import {computed, ComputedRef} from "vue";

const route = useRoute();

const type: ComputedRef<string> = computed(() => {
    return route.params.type.toString();
});

const treatmentListQuery = TreatmentManager.useTreatmentListQuery(type.value);
</script>

<style lang="scss" scoped>
#treatment-list-view {
    hr {
        border: 1px dashed #13002C;
        margin-bottom: 30px;
    }

    .treatment-list-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        background: white;
        padding: 10px;
        border-radius: 20px;

        .treatment {
            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: 10px;
            border-radius: 20px;
            border: 0.5px solid #66C1BF;

            img {
                width: 90px;
                object-fit: cover;
                border-radius: 20px;
            }
        }
    }
}
</style>