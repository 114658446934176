import {RouteRecordRaw} from "vue-router";
import PositioningView from "@/views/gout/PositioningView.vue";
import CrisisDiagnosisView from "@/views/gout/CrisisDiagnosisView.vue";
import CrisisInformationsView from "@/views/gout/CrisisInformationsView.vue";
import CrisisDailyFollowingView from "@/views/gout/CrisisDailyFollowingView.vue";
import CrisisHistoric from "@/views/gout/CrisisHistoric.vue";
import CrisisHistoricView from "@/views/gout/CrisisHistoricView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/gout/positioning",
        name: "goutPositioning",
        component: PositioningView,
        meta: {
            layout: "FullScreenLayout",
        },
    },
    {
        path: "/gout/crisis/historic",
        name: "goutCrisisHistoric",
        component: CrisisHistoricView,
        meta: {
            layout: "AppLayout",
        },
    },
    {
        path: "/gout/crisis/diagnosis",
        name: "goutCrisisDiagnosis",
        component: CrisisDiagnosisView,
        meta: {
            layout: "AppLayout",
        },
    },
    {
        path: "/gout/crisis/informations",
        name: "goutCrisisInformations",
        component: CrisisInformationsView,
        meta: {
            layout: "AppLayout",
        },
    },
    {
        path: "/gout/crisis/dailyFollowing",
        name: "goutCrisisDailyFollowing",
        component: CrisisDailyFollowingView,
        meta: {
            layout: "AppLayout",
        },
    },
];

export default routes;
