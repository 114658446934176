import IntroView from "@/views/IntroView.vue";
import HomeView from "@/views/HomeView.vue";
import securityRoutes from "@/router/routes/securityRoutes";
import ualRoutes from "@/router/routes/ualRoutes";
import {RouteRecordRaw} from "vue-router";
import learningRoutes from "@/router/routes/learningRoutes";
import userRoutes from "@/router/routes/userRoutes";
import clinicalTestFollowUpRoutes from "@/router/routes/clinicalTestFollowUpRoutes";
import goutRoutes from "@/router/routes/goutRoutes";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/intro",
        name: "intro",
        component: IntroView,
        meta: {
            allowAnonymous: true,
            disallowConnected: true,
            layout: "FullScreenLayout",
        },
    },
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
            layout: "AppLayout",
        },
    },
    ...securityRoutes,
    ...ualRoutes,
    ...learningRoutes,
    ...userRoutes,
    ...clinicalTestFollowUpRoutes,
    ...goutRoutes,
];

export default routes;
