<template>
    <div id="positioning-view" :class="'slide-'+currentSlide">
        <Logo alt="duotone"/>

        <div id="positioning-content">
            <div v-if="currentSlide <= 5">
                <h1 :class="{'dynamic-title': true, 'dynamic-title--small': currentSlide >= 1}">
                    Dites-nous où vous en êtes avec la goutte...
                </h1>

                <template v-if="currentSlide >= 1">
                    <Breadcrumb :current-step="currentSlide - 1" :nb-steps="slides.length - 2"/>
                </template>
            </div>

            <Swiper
                id="positioning-swiper"
                :slides-per-view="1"
                :space-between="20"
                :loop="false"
                :pagination="{clickable: false , el: '.swiper-pagination' }"
                @swiper="(currentSwiper) => swiper = currentSwiper"
                :allow-touch-move="false"
                style="height: 100%; width: 100%;"
            >
                <SwiperSlide v-for="slide in slides" :key="slide.id">
                    <component :is="slide.component" v-model="goutPositioning"/>
                </SwiperSlide>
            </Swiper>

            <div id="positioning-buttons">
                <v-container>
                    <v-row>
                        <v-col v-if="currentSlide > 0" cols="6">
                            <v-btn
                                @click.prevent="lastSlide"
                            >
                                {{ currentSlide === 6 ? "Modifier" : "Précedent" }}
                            </v-btn>
                        </v-col>
                        <v-col :cols="currentSlide === 0 ? 12: 6">
                            <v-btn
                                @click.prevent="() => currentSlide === 6 ? sendPositioning(): nextSlide()"
                                :disabled="!canNextSlide"
                            >
                                {{ currentSlide === 6 ? "Valider" : "Continuer" }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import PositioningSlide1 from "@/components/gout/positioning/PositioningSlide1.vue";
import PositioningSlide2 from "@/components/gout/positioning/PositioningSlide2.vue";
import PositioningSlide3 from "@/components/gout/positioning/PositioningSlide3.vue";
import PositioningSlide4 from "@/components/gout/positioning/PositioningSlide4.vue";
import PositioningSlide5 from "@/components/gout/positioning/PositioningSlide5.vue";
import PositioningSlide6 from "@/components/gout/positioning/PositioningSlide6.vue";
import PositioningSlide7 from "@/components/gout/positioning/PositioningSlide7.vue";
import {Swiper, SwiperSlide} from "swiper/vue";
import {computed, markRaw, onMounted, Ref, ref, watch} from "vue";
import {Swiper as SwiperClass} from "swiper/types";
import {useRouter} from "vue-router";
import Logo from "@/components/logo/Logo.vue";
import {PositioningType} from "@/.generated/api";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb.vue";
import GoutManager from "@/managers/GoutManager";
import Notify, {NotificationType} from "@/notify";

const router = useRouter();

const swiper = ref<SwiperClass | null>(null);

const patientGoutPositioningMutation = GoutManager.usePatientGoutPositioningMutation();

const slides = ref([
    {id: 1, component: markRaw(PositioningSlide1)},
    {id: 2, component: markRaw(PositioningSlide2)},
    {id: 3, component: markRaw(PositioningSlide3)},
    {id: 4, component: markRaw(PositioningSlide4)},
    {id: 5, component: markRaw(PositioningSlide5)},
    {id: 6, component: markRaw(PositioningSlide6)},
    {id: 7, component: markRaw(PositioningSlide7)},
]);

const goutPositioning: Ref<Partial<PositioningType>> = ref({
    numberOfCrisis: undefined,
    sinceWhen: undefined,
    kindOfPractitioner: undefined,
    lastTreatment: undefined,
    stillUnderTheLastTreatment: undefined,
    otherDiseases: [],
});

const currentSlide = computed(() => {
    let swiperUnwrapped = swiper.value;

    if (!swiperUnwrapped) {
        return;
    }

    return swiperUnwrapped.activeIndex;
});

const nextSlide = () => {
    let swiperUnwrapped = swiper.value;
    if (!swiperUnwrapped) {
        return;
    }
    if (swiperUnwrapped.isEnd) {
        return;
    }

    swiperUnwrapped.slideNext();
};

const lastSlide = () => {
    let swiperUnwrapped = swiper.value;
    if (!swiperUnwrapped) {
        return;
    }
    if (swiperUnwrapped.isBeginning) {
        return;
    }

    swiperUnwrapped.slidePrev();
};


const updateSlideMaxHeight = () => {
    let swiperUnwrapped = swiper.value;
    if (!swiperUnwrapped) {
        return;
    }

    const currentSlide = swiperUnwrapped.slides[swiperUnwrapped.activeIndex];
    const slides = Array.from(swiperUnwrapped.wrapperEl.children) as HTMLElement[];
    for (const slide of slides) {
        slide.style.maxHeight = currentSlide.offsetHeight + "px";
    }
};

watch(currentSlide, () => {
    updateSlideMaxHeight();
});

onMounted(updateSlideMaxHeight);

const canNextSlide = computed(() => {
    let unwrapCurrentSlideIndex = currentSlide.value;
    let unwrapGoutPositioning = goutPositioning.value;

    if (unwrapCurrentSlideIndex === undefined) {
        return false;
    }

    return (
        unwrapCurrentSlideIndex === 0 ||
        (unwrapCurrentSlideIndex === 1 && unwrapGoutPositioning.numberOfCrisis !== undefined) ||
        (unwrapCurrentSlideIndex === 2 && unwrapGoutPositioning.sinceWhen !== undefined) ||
        (unwrapCurrentSlideIndex === 3 && unwrapGoutPositioning.kindOfPractitioner !== undefined) ||
        (unwrapCurrentSlideIndex === 4 && unwrapGoutPositioning.lastTreatment !== undefined && unwrapGoutPositioning.stillUnderTheLastTreatment !== undefined) ||
        (unwrapCurrentSlideIndex === 5 && unwrapGoutPositioning.otherDiseases !== undefined) ||
        (unwrapCurrentSlideIndex === 6 && Object.values(unwrapGoutPositioning).reduce((acc, value) => (acc && value !== undefined), true))
    );
});

const sendPositioning = async () => {
    await patientGoutPositioningMutation.mutateAsync(goutPositioning.value as PositioningType);

    Notify.addNotification({
        title: "gout.positioning.sendPositioning.success.title",
        content: "gout.positioning.sendPositioning.success.content",
        icon: "mdi-check",
        type: NotificationType.SUCCESS,
    });

    router.push({name: "home"});
};
</script>

<style lang="scss" scoped>
#positioning-view {
    display: flex;
    flex-direction: column;
    background: #66C1BF;

    &.slide-6 {
        background: #FFF0EB;
    }

    .dynamic-title {
        transition: font-size ease-in-out 250ms, line-height ease-in-out 250ms;
        margin-bottom: 28px;
        font-size: 44px;
        line-height: 44px;

        &--small {
            font-size: 20px;
            line-height: 24px;
        }
    }

    #positioning-content {
        min-height: calc(100% - 82px);
        max-height: calc(100% - 82px);
        display: flex;
        flex-direction: column;
    }

    #positioning-swiper {
        margin-top: 23px;
        flex: 1;
    }

    .swiper-slide {
        max-width: 100%;
    }
}
</style>

<style>
.swiper-slide-active {
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.slide {
    box-sizing: border-box;
}
</style>