<template>
    <div class="slide">
        <h2>
            Avez-vous d’autres symptômes ?
        </h2>

        <v-item-group
            v-model="model.crisisDailyFollowingType.symptoms"
        >
            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="toggleSymptom('diarrhea')"
                                :active="isSymptomSelected('diarrhea')"
                            >
                                Diarrhée
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="toggleSymptom('nausea')"
                                :active="isSymptomSelected('nausea')"
                            >
                                Nausée
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="toggleSymptom('vomiting')"
                                :active="isSymptomSelected('vomiting')"
                            >
                                Vomissement
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="toggleSymptom('skin rash')"
                                :active="isSymptomSelected('skin rash')"
                            >
                                Éruption cutanée
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>
        </v-item-group>

        <TreatmentSymptomAlert v-model="treatmentSymptomAlert"/>
    </div>
</template>

<script lang="ts" setup>
import {CrisisDailyFollowingType, PositioningType} from "@/.generated/api";
import {CustomDiagnosisType} from "@/views/gout/CrisisDiagnosisView.vue";
import {CrisisDailyCustomModel} from "@/views/gout/CrisisDailyFollowingView.vue";
import TreatmentSymptomAlert from "@/components/gout/crisisDailyFollowing/TreatmentSymptomAlert.vue";
import {Ref, ref} from "vue";
import treatmentTook = CrisisDailyFollowingType.treatmentTook;

const model = defineModel<CrisisDailyCustomModel>({
    required: true,
});

const treatmentSymptomAlert: Ref<string | undefined> = ref(undefined);

model.value.crisisDailyFollowingType.symptoms = [];

const toggleSymptom = (symptom: CrisisDailyFollowingType["symptoms"][number]) => {
    if (model.value.crisisDailyFollowingType.symptoms === undefined) {
        model.value.crisisDailyFollowingType.symptoms = [];
    }

    const index = model.value.crisisDailyFollowingType.symptoms.indexOf(symptom);

    if (index === -1) {
        model.value.crisisDailyFollowingType.symptoms.push(symptom);

        if (model.value.crisisDailyFollowingType.treatmentTook === treatmentTook.COLCHICINE) {
            if (["diarrhea", "nausea", "vomiting"].includes(symptom)) {
                treatmentSymptomAlert.value = "Réduisez votre dose de colchicine.";
            } else {
                treatmentSymptomAlert.value = "Vous avez probablement une réaction allergique. Arrêtez immédiatement la colchicine et consultez votre médecin.";
            }
        }

        return;
    }

    model.value.crisisDailyFollowingType.symptoms.splice(index, 1);
};
const isSymptomSelected = (symptom: PositioningType["symptoms"][number]): boolean => {
    return model.value.crisisDailyFollowingType.symptoms?.includes(symptom) ?? false;
};
</script>

<style lang="scss" scoped>
</style>