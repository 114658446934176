<template>
    <div class="logo">
        <img :src="logoUrl" :alt="$t('global.app')">
    </div>
</template>

<script lang="ts" setup>
import {computed, toRefs} from "vue";
import logo from "@/assets/svg/logo/logo.svg";
import logoDuotone from "@/assets/svg/logo/logo-duotone.svg";

export interface Props {
    alt?: "default" | "duotone";
}

const props = withDefaults(
    defineProps<Props>(),
    {
        alt: "default",
    },
);

const {alt} = toRefs(props);

const logoUrl = computed(() => {
    return alt.value === "duotone" ? logoDuotone : logo;
});
</script>

<style scoped>
.logo {
    width: 82px;
    height: 82px;
    padding: 10px 20px;

    > img {
        width: 42px;
        height: 62px;
    }
}
</style>