import {initializeApp} from "firebase/app";
import {getMessaging, getToken, Messaging} from "firebase/messaging";
import env from "@/config/env";


abstract class Firebase {
    private static messaging?: Messaging;

    static async init() {
        const firebaseConfig = {
            apiKey: env.FIREBASE_API_KEY,
            authDomain: env.FIREBASE_AUTH_DOMAIN,
            projectId: env.FIREBASE_PROJECT_ID,
            storageBucket: env.FIREBASE_STORAGE_BUCKET,
            messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID,
            appId: env.FIREBASE_APP_ID,
        };

        const app = initializeApp(firebaseConfig);

        this.messaging = getMessaging(app);
    }

    static async getToken() {
        if (!this.messaging) {
            return;
        }

        return await getToken(this.messaging, {vapidKey: env.FIREBASE_VAPID_KEY});
    }
}

export default Firebase;