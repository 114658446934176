/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Treatment } from '../models/Treatment';
import type { Treatment2 } from '../models/Treatment2';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TreatmentService {

    /**
     * Treatment list
     * Get all treatment
     * @param type
     * @returns Treatment Return all treatment
     * @throws ApiError
     */
    public static getPatientTreatmentList(
        type: 'crisis' | 'daily',
    ): CancelablePromise<Array<Treatment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/patient/treatment/{type}',
            path: {
                'type': type,
            },
        });
    }

    /**
     * Treatment show
     * Show treatment
     * @param treatmentId
     * @returns Treatment2 Return data of treatment
     * @throws ApiError
     */
    public static getPatientTreatmentShow(
        treatmentId: string,
    ): CancelablePromise<Treatment2> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/patient/treatment/{treatmentId}',
            path: {
                'treatmentId': treatmentId,
            },
        });
    }

}
