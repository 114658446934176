<template>
    <div class="slide">
        <main>
            <div id="content1">
                AppliGoutte est développé par Rheuma Care SAS, avec l'aide du centre expert de la goutte de l'hôpital
                Lariboisière à Paris.
            </div>
            <br>
            <div id="content2">
                Des personnes vivant avec la goutte ont participé à la conception de l'application.
            </div>

            <div id="partners">
                <img :src="require('@/assets/svg/intro/rheumacare.svg')" :alt="$t('global.app')" width="200">
                <img :src="require('@/assets/svg/intro/lariboisiere.svg')" :alt="$t('global.app')" width="200">
                <div>
                    avec le soutien de
                </div>
                <img :src="require('@/assets/svg/intro/viatris.svg')" :alt="$t('global.app')" width="200">
            </div>
        </main>
    </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
.slide {
    overflow: auto;

    main {
        padding-bottom: 40px;

        > #content1 {
            font-weight: 700;
            font-size: 20px;
            color: #13002C;
            padding-top: 20px;
        }

        > #content2 {
            font-weight: 500;
            font-size: 20px;
            color: #13002C;
        }

        > #partners {
            display: flex;
            flex-direction: column;
            align-items: center;

            > * {
                margin: 10px;
            }
        }
    }
}
</style>