import {ref, Ref} from "vue";

export enum NotificationType {
    ERROR = "error",
    SUCCESS = "success",
    WARNING = "warning",
    INFO = "info"
}

export enum NotificationIcon {
    SUCCESS = "mdi-check",
    ERROR = "mdi-error",
}

let count = 1;

export interface Notification {
    id: number,
    title: string,
    content?: string,
    icon: string | NotificationIcon,
    type: NotificationType,
    timeMs?: number,
    hide: boolean
}

class Notify {
    _notifications: Ref<Ref<Notification>[]>;

    constructor() {
        this._notifications = ref([]);
    }

    get notifications(): Ref<Ref<Notification>[]> {
        return this._notifications;
    }

    addNotification(notification: Omit<Notification, "id" | "hide">) {
        const notificationRef: Ref<Notification> = ref({
            ...notification,
            id: ++count,
            timeMs: notification.timeMs ?? 5000,
            hide: false,
        });

        this._notifications.value.unshift(notificationRef);
        setTimeout((() => notificationRef.value.hide = true), notificationRef.value.timeMs as number);
        setTimeout((() => this._notifications.value.splice(this._notifications.value.indexOf(notificationRef), 1)), notificationRef.value.timeMs as number + 400);
    }

    cleanNotifications() {
        this._notifications.value = this._notifications.value.filter(n => !n.value.hide);
    }
}

export default new Notify();