<template>
    <div class="slide">
        <v-row>
            <v-col cols="12">
                <h2>
                    Quels sont le(s) facteur(s) déclenchant(s) qui vous semblent pertinents ?
                </h2>
            </v-col>
        </v-row>
        <div>
            <TriggerFactorCategory
                class="mt-10"
                v-for="triggerFactorCategory in triggerFactorCategories"
                :trigger-factor-category="triggerFactorCategory"
                v-model="crisisInformationType"
            ></TriggerFactorCategory>
        </div>
    </div>
</template>

<script lang="ts" setup>

import {CrisisInformationsType} from "@/.generated/api";
import TriggerFactorCategory, {
    TriggerFactorCategoryType,
} from "@/components/gout/crisisInformations/TriggerFactorCategory.vue";

const crisisInformationType = defineModel<Partial<CrisisInformationsType>>({required: true});

const triggerFactorCategories: TriggerFactorCategoryType[] = [
    {
        name: "Alimentation",
        imgUrl: require("@/assets/svg/gout/informations/alimentation.svg"),
        triggerFactorsChoices: [
            {
                name: "Consommation d'alcool",
                imgUrl: require("@/assets/svg/gout/informations/alcool.png"),
                value: "alcool",
            },
            {
                name: "Consommation de sodas et/ou jus de fruits",
                imgUrl: require("@/assets/svg/gout/informations/soda.png"),
                value: "soda / fruit juice",
            },
            {
                name: "Consommation d'abats, de charcuterie, de crustacés, d'autres viandes",
                imgUrl: require("@/assets/svg/gout/informations/meat.png"),
                value: "offal / charcuterie / shellfish / other meats",
            },
        ],
    },
    {
        name: "Mode de vie",
        imgUrl: require("@/assets/svg/gout/informations/lifestyle.svg"),
        triggerFactorsChoices: [
            {
                name: "Stress important",
                imgUrl: require("@/assets/svg/gout/informations/stress.png"),
                value: "stresses",
            },
            {
                name: "Déshydratation",
                imgUrl: require("@/assets/svg/gout/informations/dehydration.png"),
                value: "dehydration",
            },
            {
                name: "Voyage en avion",
                imgUrl: require("@/assets/svg/gout/informations/travel.png"),
                value: "plane travel",
            },
        ],
    },
    {
        name: "Santé",
        imgUrl: require("@/assets/svg/gout/informations/health.svg"),
        triggerFactorsChoices: [
            {
                name: "Infection",
                imgUrl: require("@/assets/svg/gout/informations/infection.png"),
                value: "infection",
            },
            {
                name: "Arrêt du traitement de fond",
                imgUrl: require("@/assets/svg/gout/informations/stopping_daily_treatment.png"),
                value: "stopping daily treatment",
            },
            {
                name: "Arrêt du traitement de crise",
                imgUrl: require("@/assets/svg/gout/informations/stopping_crisis_treatment.png"),
                value: "stopping crisis treatment",
            },
            {
                name: "Prise de médicaments qui font augmenter le taux d'acide urique",
                imgUrl: require("@/assets/svg/gout/informations/medication_augmenting_ual.png"),
                value: "medication augmenting ual",
            },
            {
                name: "Traumatisme ou sollicitation physique",
                imgUrl: require("@/assets/svg/gout/informations/trauma.png"),
                value: "trauma or physical stress",
            },
        ],
    },
];

</script>

<style lang="scss" scoped>
</style>