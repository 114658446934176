export default {
    NODE_ENV: process.env.NODE_ENV,
    API: process.env.VUE_APP_API,
    BASE_URL: process.env.VUE_APP_BASE_URL,
    I18N_LOCALE: process.env.VUE_APP_I18N_LOCALE,
    I18N_FALLBACK_LOCALE: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
    FIREBASE_API_KEY: process.env.VUE_APP_FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    FIREBASE_PROJECT_ID: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGING_SENDER_ID: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_APP_ID: process.env.VUE_APP_FIREBASE_APP_ID,
    FIREBASE_VAPID_KEY: process.env.VUE_APP_FIREBASE_VAPID_KEY,
};