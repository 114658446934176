export default {
  "permission": {
    "dialog": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Notifications"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree to receive notifications to be better supported by the application."])},
      "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Authorized"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have authorized notifications."])}
    },
    "refused": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Denied"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have denied notifications."])}
    },
    "error": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Error"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have an error for the notifications."])}
    }
  }
}