export default {
  "articulations": {
    "Elbow_Left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left Elbow"])},
    "Elbow_Right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right Elbow"])},
    "Wrist_Left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left Wrist"])},
    "Wrist_Right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right Wrist"])},
    "Hand_Left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left Hand"])},
    "Hand_Right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right Hand"])},
    "Ankle_Left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left Ankle"])},
    "Ankle_Right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right Ankle"])},
    "Back_of_Foot_Left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left Back of Foot"])},
    "Back_of_Foot_Right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right Back of Foot"])},
    "Big_Toe_Left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left Big Toe"])},
    "Big_Toe_Right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right Big Toe"])},
    "Knee_Left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left Knee"])},
    "Knee_Right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right Knee"])}
  },
  "yourHistoric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Crisis Historic"])},
  "noHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven't entered any seizures yet.You haven't entered any crisis yet."])}
}