/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CrisisDailyFollowingType = {
    treatmentTook: CrisisDailyFollowingType.treatmentTook;
    painLevel: number;
    feelings: Array<'swelling' | 'heat' | 'redness'>;
    symptoms: Array<'diarrhea' | 'nausea' | 'vomiting' | 'skin rash' | 'headache'>;
};

export namespace CrisisDailyFollowingType {

    export enum treatmentTook {
        NO_TREATMENT = 'no_treatment',
        COLCHICINE = 'colchicine',
        PAINKILLERS = 'painkillers',
        ANTI_INFLAMMATORY = 'anti_inflammatory',
        CORTICOIDS = 'corticoids',
    }


}

