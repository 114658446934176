<template>
    <div class="slide">
        <h2>
            Avez-vous eu un traitement contre la goutte ?
        </h2>

        <v-item-group
            v-model="hadATreatment"
        >
            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="hadATreatment = false"
                                :active="hadATreatment === false"
                            >
                                Non
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="hadATreatment = true"
                                :active="hadATreatment === true"
                            >
                                Oui
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>
        </v-item-group>

        <template v-if="hadATreatment">
            <h2 class="mt-8">
                Quel est le dernier traitement que vous avez pris contre la goutte ?
            </h2>

            <v-item-group
                v-model="modelValue.lastTreatment"
            >
                <v-row class="mt-2">
                    <v-col cols="12">
                        <v-item style="position: relative;">
                            <template v-slot:default>
                                <v-btn-transparent
                                    @click="modelValue.lastTreatment = PositioningType.lastTreatment.COLCHICINE"
                                    :active="modelValue.lastTreatment === PositioningType.lastTreatment.COLCHICINE"
                                    style="justify-content: space-between"
                                >
                                    <template v-slot:prepend>
                                    </template>
                                    Colchicine
                                    <template v-slot:append>
                                        <InfoTooltip
                                            text="
                                                Colchicine
                                                Colchimax
                                            "
                                        />
                                    </template>
                                </v-btn-transparent>
                            </template>
                        </v-item>
                    </v-col>
                </v-row>

                <v-row class="mt-2">
                    <v-col cols="12">
                        <v-item>
                            <template v-slot:default>
                                <v-btn-transparent
                                    @click="modelValue.lastTreatment = PositioningType.lastTreatment.PAINKILLERS"
                                    :active="modelValue.lastTreatment === PositioningType.lastTreatment.PAINKILLERS"
                                    style="justify-content: space-between"
                                >
                                    <template v-slot:prepend>
                                    </template>
                                    Antidouleurs
                                    <template v-slot:append>
                                        <InfoTooltip
                                            text="
                                                Paracétamol (Doliprane®, Efferalgan®, Dafalgan®...)
                                                Paracétamol codéiné (Doliprane codéine®, Efferalgan codéine®, Daffalgan codéine®...)
                                                Tramadol
                                            "
                                        />
                                    </template>
                                </v-btn-transparent>
                            </template>
                        </v-item>
                    </v-col>
                </v-row>

                <v-row class="mt-2">
                    <v-col cols="12">
                        <v-item>
                            <template v-slot:default>
                                <v-btn-transparent
                                    @click="modelValue.lastTreatment = PositioningType.lastTreatment.ANTI_INFLAMMATORY"
                                    :active="modelValue.lastTreatment === PositioningType.lastTreatment.ANTI_INFLAMMATORY"
                                    style="justify-content: space-between"
                                >
                                    <template v-slot:prepend>
                                    </template>
                                    Anti-inflammatoires
                                    <template v-slot:append>
                                        <InfoTooltip
                                            text="
                                                Cortisone et dérivés (Solupred®, Cortancyl®, Prednisolone®)
                                                Anti-inflammatoire (Ketoprofène®, Diplofenac®, Voltarène®, Naproxene®,...)
                                            "
                                        />
                                    </template>
                                </v-btn-transparent>
                            </template>
                        </v-item>
                    </v-col>
                </v-row>

                <v-row class="mt-2">
                    <v-col cols="12">
                        <v-item>
                            <template v-slot:default>
                                <v-btn-transparent
                                    @click="modelValue.lastTreatment = PositioningType.lastTreatment.HYPOURICEMIC"
                                    :active="modelValue.lastTreatment === PositioningType.lastTreatment.HYPOURICEMIC"
                                    style="justify-content: space-between"
                                >
                                    <template v-slot:prepend>
                                    </template>
                                    Hypouricémiants
                                    <template v-slot:append>
                                        <InfoTooltip
                                            text="
                                                allopurinol
                                                febuxostat
                                            "
                                        />
                                    </template>
                                </v-btn-transparent>
                            </template>
                        </v-item>
                    </v-col>
                </v-row>
            </v-item-group>
        </template>

        <template
            v-if="modelValue.lastTreatment && modelValue.lastTreatment !== PositioningType.lastTreatment.NO_TREATMENT">
            <h2 class="mt-8">
                Continuez-vous de prendre le traitement contre la goutte ?
            </h2>

            <v-item-group
                v-model="modelValue.stillUnderTheLastTreatment"
            >
                <v-row class="mt-2">
                    <v-col cols="12">
                        <v-item>
                            <template v-slot:default>
                                <v-btn-transparent
                                    @click="modelValue.stillUnderTheLastTreatment = false"
                                    :active="modelValue.stillUnderTheLastTreatment === false"
                                >
                                    Non
                                </v-btn-transparent>
                            </template>
                        </v-item>
                    </v-col>
                </v-row>

                <v-row class="mt-2">
                    <v-col cols="12">
                        <v-item>
                            <template v-slot:default>
                                <v-btn-transparent
                                    @click="modelValue.stillUnderTheLastTreatment = true"
                                    :active="modelValue.stillUnderTheLastTreatment"
                                >
                                    Oui
                                </v-btn-transparent>
                            </template>
                        </v-item>
                    </v-col>
                </v-row>
            </v-item-group>
        </template>
    </div>
</template>

<script lang="ts" setup>

import {PositioningType} from "@/.generated/api";
import {computed, Ref, ref, toRefs, watch} from "vue";
import Browser from "@/utils/Browser";
import InfoTooltip from "@/components/tooltip/InfoTooltip.vue";

const props = defineProps<{
    modelValue: Partial<PositioningType>
}>();

const {modelValue} = toRefs(props);

const hadATreatment: Ref<boolean | undefined> = ref(undefined);

watch(hadATreatment, () => {
    let slideElement: HTMLElement = document.querySelector(".swiper-slide-active");

    Browser.scrollTo(slideElement, true);

    if (hadATreatment.value === false) {
        modelValue.value.lastTreatment = PositioningType.lastTreatment.NO_TREATMENT;
        modelValue.value.stillUnderTheLastTreatment = false;
        return;
    }

    if (hadATreatment.value === true) {
        modelValue.value.lastTreatment = undefined;
        modelValue.value.stillUnderTheLastTreatment = undefined;
    }

    modelValue.value.lastTreatment = undefined;
});

watch(computed(() => modelValue.value.lastTreatment), () => {
    let slideElement: HTMLElement = document.querySelector(".swiper-slide-active");
    Browser.scrollTo(slideElement, true);
});


</script>

<style lang="scss" scoped>
</style>