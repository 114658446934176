<template>
    <div id="Slide">
        <header id="header">
            <h1 id="logo">
                <img :src="require('@/assets/svg/logo_v2.svg')" :alt="$t('global.app')" width="200">
            </h1>
            <h1 id="title">
                Mon profil
            </h1>
        </header>

        <div id="main">
            <v-form @submit.prevent="update">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Nom"
                                v-model="updateData.lastname"
                                :error-messages="Filters.errors(vuelidate.lastname.$errors)"
                                @blur="vuelidate.lastname.$touch"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Prénom"
                                v-model="updateData.firstname"
                                :error-messages="Filters.errors(vuelidate.firstname.$errors)"
                                @blur="vuelidate.firstname.$touch"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Email"
                                v-model="updateData.email"
                                :error-messages="Filters.errors(vuelidate.email.$errors)"
                                @blur="vuelidate.email.$touch"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <UseElementSize v-slot="{ width, height }">
                                <v-date-picker
                                    mode="date"
                                    v-model="birthdateComputed"
                                    :update-on-input="false"
                                    :max="new Date()"
                                    :width="width"
                                />
                            </UseElementSize>
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Ville"
                                v-model="updateData.city"
                                :error-messages="Filters.errors(vuelidate.city.$errors)"
                                @blur="vuelidate.city.$touch"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-btn
                                type="success"
                                :loading="updateMutation.isPending.value"
                                :disabled="vuelidate.$invalid"
                            >
                                ENREGISTRER
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-container>
            </v-form>

        </div>

    </div>
</template>

<script lang="ts" setup>
import {computed, reactive} from "vue";
import useVuelidate from "@vuelidate/core";
import Vuelidate from "@/utils/Vuelidate";
import Filters from "@/utils/Filters";
import {useRouter} from "vue-router";
import MeManager from "@/managers/MeManager";
import {UseElementSize} from "@vueuse/components";
import filters from "@/filters";

const profileQuery = MeManager.useGetPatient();

const updateData = reactive({
    firstname: profileQuery.data.value?.firstname ?? "",
    lastname: profileQuery.data.value?.lastname ?? "",
    email: profileQuery.data.value?.email ?? "",
    birthdate: profileQuery.data.value?.birthdate ?? "",
    city: profileQuery.data.value?.city ?? "",
});

const updateMutation = MeManager.usePatientUpdateMutation();

const router = useRouter();

const birthdateComputed = computed({
    get: () => {
        if (!updateData.birthdate) {
            return undefined;
        }

        return new Date(updateData.birthdate);
    },
    set: (newDate) => {
        if (!newDate) {
            updateData.birthdate = undefined;
            return;
        }

        updateData.birthdate = filters.date(newDate);
    },
});

const update = async () => {
    await updateMutation.mutateAsync({
        firstname: updateData.firstname,
        lastname: updateData.lastname,
        email: updateData.email,
        birthdate: updateData.birthdate,
        city: updateData.city,
    });

    router.push({name: "profileView"});
};

const vuelidate = useVuelidate(
    {
        firstname: [Vuelidate.required],
        lastname: [Vuelidate.required],
        email: [Vuelidate.required, Vuelidate.email],
        birthdate: [Vuelidate.required],
        city: [Vuelidate.required],
    },
    updateData,
);
</script>

<style lang="scss" scoped>
.v-text-field {
    height: 50px !important;
    background-color: #FFFFFF !important;
}

.v-btn {
    width: 400px;
    height: 50px;
    border-radius: 20px;
    background-color: #47B9AE;
    border-color: #47B9AE;
    color: #FFFFFF;
}


#Slide {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-color: #FFFFFF;

    > #header {
        position: relative;

        > #logo {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
        }

        > #title {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 50px;
        }
    }

    > #main {
        flex: 1;
        padding: 38px;

        > #formInput {
            border-radius: 20px;
            background-color: #FFFFFF;
        }
    }

    > #footer {
        position: relative;

        > #picto {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

</style>