<template>
    <div id="diagnosis-view" :class="'slide-'+currentSlide">
        <div id="diagnosis-content">
            <div v-if="currentSlide <= 5">
                <h1 :class="{'dynamic-title': true, 'dynamic-title--small': currentSlide >= 1}">
                    Diagnostiquez votre crise de goutte
                </h1>

                <template v-if="currentSlide >= 1">
                    <Breadcrumb :current-step="currentSlide - 1" :nb-steps="slides.length - 2"/>
                </template>
            </div>

            <Swiper
                id="diagnosis-swiper"
                :slides-per-view="1"
                :space-between="20"
                :loop="false"
                :pagination="{clickable: false , el: '.swiper-pagination' }"
                @swiper="(currentSwiper) => swiper = currentSwiper"
                :allow-touch-move="false"
                style="height: 100%; width: 100%;"
            >
                <SwiperSlide v-for="slide in slides" :key="slide.id">
                    <component :is="slide.component" v-model="customDiagnosisType"/>
                </SwiperSlide>
            </Swiper>

            <div id="diagnosis-buttons">
                <v-container>
                    <v-row>
                        <v-col v-if="currentSlide > 0" cols="6">
                            <v-btn
                                @click.prevent="lastSlide"
                            >
                                {{ currentSlide === 5 ? "Modifier" : "Précedent" }}
                            </v-btn>
                        </v-col>
                        <v-col :cols="currentSlide === 0 ? 12: 6">
                            <v-btn-success
                                @click.prevent="() => currentSlide === 5 ? sendDiagnosis(): nextSlide()"
                                :disabled="!canNextSlide"
                            >
                                {{ currentSlide === 5 ? "Valider" : "Continuer" }}
                            </v-btn-success>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import DiagnosisSlide1 from "@/components/gout/crisisDiagnosis/DiagnosisSlide1.vue";
import DiagnosisSlide2 from "@/components/gout/crisisDiagnosis/DiagnosisSlide2.vue";
import DiagnosisSlide3 from "@/components/gout/crisisDiagnosis/DiagnosisSlide3.vue";
import DiagnosisSlide4 from "@/components/gout/crisisDiagnosis/DiagnosisSlide4.vue";
import DiagnosisSlide5 from "@/components/gout/crisisDiagnosis/DiagnosisSlide5.vue";
import DiagnosisSlide6 from "@/components/gout/crisisDiagnosis/DiagnosisSlide6.vue";
import {Swiper, SwiperSlide} from "swiper/vue";
import {computed, markRaw, onMounted, Ref, ref, watch} from "vue";
import {Swiper as SwiperClass} from "swiper/types";
import {useRouter} from "vue-router";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb.vue";
import GoutManager from "@/managers/GoutManager";
import Notify from "@/notify";
import notify, {NotificationIcon, NotificationType} from "@/notify";
import {CrisisDiagnosisType} from "@/.generated/api";
import MeManager from "@/managers/MeManager";

const router = useRouter();

const swiper = ref<SwiperClass | null>(null);

const patientQuery = MeManager.useGetPatient();
const patientGoutCrisisDiagnosisMutation = GoutManager.usePatientGoutCrisisDiagnosisMutation();

const patient = computed(() => {
    return patientQuery.data.value;
});

const activeCrisis = computed(() => {
    if (!patientQuery.data.value) {
        return undefined;
    }

    return patientQuery.data.value.crisis.find((crisis) => crisis.stoppedAt === null);
});

const slides = ref([
    {id: 1, component: markRaw(DiagnosisSlide1)},
    {id: 2, component: markRaw(DiagnosisSlide2)},
    {id: 3, component: markRaw(DiagnosisSlide3)},
    {id: 4, component: markRaw(DiagnosisSlide4)},
    {id: 5, component: markRaw(DiagnosisSlide5)},
    {id: 6, component: markRaw(DiagnosisSlide6)},
]);

export type CustomDiagnosisType = Partial<CrisisDiagnosisType & { thinkToHaveCrisis: boolean }>;
const customDiagnosisType: Ref<CustomDiagnosisType> = ref({
    thinkToHaveCrisis: undefined,
    painLevel: undefined,
    swollenJointLevel: undefined,
    heatJointLevel: undefined,
});

const diagnosisType = computed((): Partial<CrisisDiagnosisType> => {
    return {
        painLevel: customDiagnosisType.value.painLevel,
        swollenJointLevel: customDiagnosisType.value.swollenJointLevel,
        heatJointLevel: customDiagnosisType.value.heatJointLevel,
    };
});

function checkIfDontHaveActiveCrisis() {
    if (customDiagnosisType.value.thinkToHaveCrisis === undefined && activeCrisis.value) {
        router.push({name: "home"});

        Notify.addNotification({
            title: "Crise déjà en cours",
            content: "Vous avez déjà indiqué être en crise. Vous pourrez renseigner une nouvelle crise à la fin de celle-ci.",
            icon: "mdi-error",
            type: NotificationType.ERROR,
        });

        return;
    }
}

watch(activeCrisis, () => {
    checkIfDontHaveActiveCrisis();
});

onMounted(checkIfDontHaveActiveCrisis);

const currentSlide = computed(() => {
    let swiperUnwrapped = swiper.value;

    if (!swiperUnwrapped) {
        return;
    }

    return swiperUnwrapped.activeIndex;
});


watch(currentSlide, (nv, ov) => {
    if (nv === 2 && ov === 1 && customDiagnosisType.value.thinkToHaveCrisis === false) {
        router.push({name: "home"});

        notify.addNotification({
            type: NotificationType.INFO,
            icon: NotificationIcon.SUCCESS,
            title: "gout.crisisDiagnosis.dontThinkHaveCrisis.title",
            content: "gout.crisisDiagnosis.dontThinkHaveCrisis.content",
        });
    }
});
const nextSlide = () => {
    let swiperUnwrapped = swiper.value;
    if (!swiperUnwrapped) {
        return;
    }
    if (swiperUnwrapped.isEnd) {
        return;
    }

    swiperUnwrapped.slideNext();
};

const lastSlide = () => {
    let swiperUnwrapped = swiper.value;
    if (!swiperUnwrapped) {
        return;
    }
    if (swiperUnwrapped.isBeginning) {
        return;
    }

    swiperUnwrapped.slidePrev();
};


const updateSlideMaxHeight = () => {
    let swiperUnwrapped = swiper.value;
    if (!swiperUnwrapped) {
        return;
    }

    const currentSlide = swiperUnwrapped.slides[swiperUnwrapped.activeIndex];
    const slides = Array.from(swiperUnwrapped.wrapperEl.children) as HTMLElement[];
    for (const slide of slides) {
        slide.style.maxHeight = currentSlide.offsetHeight + "px";
    }
};

watch(currentSlide, () => {
    updateSlideMaxHeight();
});

onMounted(updateSlideMaxHeight);

const canNextSlide = computed(() => {
    let unwrapCurrentSlideIndex = currentSlide.value;
    let unwrapGoutDiagnosis = customDiagnosisType.value;

    if (unwrapCurrentSlideIndex === undefined) {
        return false;
    }

    return (
        unwrapCurrentSlideIndex === 0 ||
        (unwrapCurrentSlideIndex === 1 && unwrapGoutDiagnosis.thinkToHaveCrisis !== undefined) ||
        (unwrapCurrentSlideIndex === 2 && unwrapGoutDiagnosis.painLevel !== undefined) ||
        (unwrapCurrentSlideIndex === 3 && unwrapGoutDiagnosis.swollenJointLevel !== undefined) ||
        (unwrapCurrentSlideIndex === 4 && unwrapGoutDiagnosis.heatJointLevel !== undefined) ||
        (unwrapCurrentSlideIndex === 5 && Object.values(unwrapGoutDiagnosis).reduce((acc, value) => (acc && value !== undefined), true))
    );
});

const sendDiagnosis = async () => {
    await patientGoutCrisisDiagnosisMutation.mutateAsync(diagnosisType.value as CrisisDiagnosisType);

    Notify.addNotification({
        title: "gout.positioning.sendDiagnosis.success.title",
        content: "gout.positioning.sendDiagnosis.success.content",
        icon: "mdi-check",
        type: NotificationType.SUCCESS,
    });

    router.push({name: "home"});
};
</script>

<style lang="scss" scoped>
#diagnosis-view {
    display: flex;
    flex-direction: column;
    height: 100%;

    &.slide-6 {
        background: #FFF0EB;
    }

    .dynamic-title {
        transition: font-size ease-in-out 250ms, line-height ease-in-out 250ms;
        margin-bottom: 28px;
        font-size: 44px;
        line-height: 44px;

        &--small {
            font-size: 20px;
            line-height: 24px;
        }
    }

    #diagnosis-content {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    #diagnosis-swiper {
        margin-top: 23px;
        flex: 1;
    }
}
</style>

<style>
.swiper-slide-active {
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.slide {
    box-sizing: border-box;
}
</style>