<template>
    <div class="slide d-flex flex-column align-center">
        <img class="h-50" :src="require('@/assets/svg/gout/daily/montaign_path.svg')" alt="Path">
        <div class="d-flex flex-column align-center">
            <div class="font-weight-bold text-h5 mt-8">
                Votre nouvelle crise va être enregistrée dans bilan de crise.
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>

import {toRefs} from "vue";
import {CrisisInformationsType} from "@/.generated/api";

const props = defineProps<{
    modelValue: Partial<CrisisInformationsType>
}>();

const {modelValue} = toRefs(props);
</script>

<style lang="scss" scoped>
.slide {
    height: 100%;
    max-height: 100%;
}
</style>