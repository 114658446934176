<template>
    <div class="slide">
        <img :src="require('@/assets/svg/gout/positioning/positioning_slide1.svg')" :alt="$t('global.app')" width="250">
    </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-height: 100%;

    img {
        height: 100%;
        max-height: 100%;
    }
}
</style>