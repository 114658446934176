export default {
  "addUalLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Uric Acid Level"])},
  "diagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagnosis"])},
  "triggeringFactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggering Factor"])},
  "dailyFollowing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crisis Follow-up"])},
  "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gout App"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "crisis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crisis"])},
  "doctorAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor's Appointment"])},
  "error": {
    "401": {
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to log in."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication Required"])}
    },
    "5xx": {
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are experiencing an issue with the server, please try again later."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Error"])}
    }
  },
  "iHaveAGoutCrisis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Have a Gout Crisis"])},
  "laboratoryAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratory Appointment"])},
  "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify"])},
  "myMonitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Monitoring"])},
  "ualLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uric Acid Level"])},
  "understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understand"])},
  "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before"])},
  "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low"])},
  "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
  "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High"])},
  "crisisHistoric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crisis Historic"])},
  "dailyTreatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
}