import moment from "moment";
import "moment/locale/fr.js";
import i18n from "@/plugins/i18n";
import vuetify from "@/plugins/vuetify";


class Locale {
    public setLocale(locale: string): void {
        if (!["fr", "en"].includes(locale)) {
            console.warn(`Locale "${locale}" not available. Fallback to "en".`);
            locale = "en";
        }

        moment.locale(locale);
        i18n.global.locale.value = locale;

        vuetify.locale.current.value = locale;
    }
}

export default new Locale();