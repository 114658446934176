/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CredentialType } from '../models/CredentialType';
import type { NewPatientType } from '../models/NewPatientType';
import type { Patient6 } from '../models/Patient6';
import type { PatientForgotPasswordNewPasswordType } from '../models/PatientForgotPasswordNewPasswordType';
import type { PatientForgotPasswordType } from '../models/PatientForgotPasswordType';
import type { Token } from '../models/Token';
import type { TokenRefreshType } from '../models/TokenRefreshType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SecurityService {

    /**
     * User Authentication
     * Permit to authenticate the user for get a token.
     * @param requestBody
     * @returns Token Return token and refreshToken.
     * @throws ApiError
     */
    public static postPatientSecurityToken(
        requestBody?: CredentialType,
    ): CancelablePromise<Token> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/patient/token',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * User Authentication with refresh token
     * Permit to authenticate the user for get a token with refresh token.
     * @param requestBody
     * @returns Token Return token and refreshToken.
     * @throws ApiError
     */
    public static postPatientSecurityTokenRefresh(
        requestBody?: TokenRefreshType,
    ): CancelablePromise<Token> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/patient/token/refresh',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Register patient
     * Permit to register a patient.
     * @param requestBody
     * @returns Patient6 Return registered patient
     * @throws ApiError
     */
    public static postPatientSecurityRegister(
        requestBody?: NewPatientType,
    ): CancelablePromise<Patient6> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/patient/register',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Validate user account
     * Permit to validate user account.
     * @param token
     * @returns void
     * @throws ApiError
     */
    public static getPatientSecurityValidation(
        token: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/patient/validation/{token}',
            path: {
                'token': token,
            },
        });
    }

    /**
     * Forgot password
     * Patient forgot password
     * @param requestBody
     * @returns Patient6 Return patient new token to update password
     * @throws ApiError
     */
    public static postPatientSecurityForgotPassword(
        requestBody?: PatientForgotPasswordType,
    ): CancelablePromise<Patient6> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/patient/forgot_password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Forgot password new password
     * Patient forgot password new password
     * @param token
     * @param requestBody
     * @returns Patient6 Update patient password if token is valid
     * @throws ApiError
     */
    public static getPatientSecurityForgotPasswordNewPassword(
        token: string,
        requestBody?: PatientForgotPasswordNewPasswordType,
    ): CancelablePromise<Patient6> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/patient/forgot_password/{token}',
            path: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
