<template>
    <div class="slide">
        <h2>
            Quel médicament avez-vous pris pour votre crise aujourd'hui ?
        </h2>

        <v-item-group
            v-model="model.crisisDailyFollowingType.treatmentTook"
        >
            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="model.crisisDailyFollowingType.treatmentTook = CrisisDailyFollowingType.treatmentTook.NO_TREATMENT"
                                :active="model.crisisDailyFollowingType.treatmentTook === CrisisDailyFollowingType.treatmentTook.NO_TREATMENT"
                            >
                                Aucun
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="model.crisisDailyFollowingType.treatmentTook = CrisisDailyFollowingType.treatmentTook.ANTI_INFLAMMATORY"
                                :active="model.crisisDailyFollowingType.treatmentTook === CrisisDailyFollowingType.treatmentTook.ANTI_INFLAMMATORY"
                            >
                                Anti-inflammatoires
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="model.crisisDailyFollowingType.treatmentTook = CrisisDailyFollowingType.treatmentTook.CORTICOIDS"
                                :active="model.crisisDailyFollowingType.treatmentTook === CrisisDailyFollowingType.treatmentTook.CORTICOIDS"
                            >
                                Corticoïdes
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="model.crisisDailyFollowingType.treatmentTook = CrisisDailyFollowingType.treatmentTook.PAINKILLERS"
                                :active="model.crisisDailyFollowingType.treatmentTook === CrisisDailyFollowingType.treatmentTook.PAINKILLERS"
                            >
                                Anti-douleurs
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="model.crisisDailyFollowingType.treatmentTook = CrisisDailyFollowingType.treatmentTook.COLCHICINE"
                                :active="model.crisisDailyFollowingType.treatmentTook === CrisisDailyFollowingType.treatmentTook.COLCHICINE"
                            >
                                Colchicine
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>
        </v-item-group>
    </div>
</template>

<script lang="ts" setup>

import {CrisisDailyCustomModel} from "@/views/gout/CrisisDailyFollowingView.vue";
import {CrisisDailyFollowingType} from "@/.generated/api";

const model = defineModel<CrisisDailyCustomModel>({
    required: true,
});
</script>


<style lang="scss" scoped>
</style>