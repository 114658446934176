/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PositioningType = {
    numberOfCrisis: PositioningType.numberOfCrisis;
    sinceWhen: PositioningType.sinceWhen;
    kindOfPractitioner: PositioningType.kindOfPractitioner;
    lastTreatment: PositioningType.lastTreatment;
    stillUnderTheLastTreatment: boolean;
    otherDiseases: Array<'diabetes' | 'transplantation' | 'hypertension' | 'cardiac insufficiency' | 'cholesterol' | 'renal insufficiency' | 'coronary diseases' | 'other vascular problems'>;
};

export namespace PositioningType {

    export enum numberOfCrisis {
        ZERO = 'zero',
        ONE = 'one',
        TWO_TO_FOUR = 'two_to_four',
        MORE_THAN_FIVE = 'more_than_five',
    }

    export enum sinceWhen {
        ZERO_TO_SIX_MONTHS = 'zero_to_six_months',
        SIX_TO_ONE_YEAR = 'six_to_one_year',
        ONE_TO_TWO_TEN_YEARS = 'one_to_two_ten_years',
        MORE_THAN_TEN_YEARS = 'more_than_ten_years',
    }

    export enum kindOfPractitioner {
        NO_PRACTITIONER = 'no_practitioner',
        ATTENDING_GENERAL_PRACTITIONER = 'attending_general_practitioner',
        IN_HOSPITAL_EMERGENCIES = 'in_hospital_emergencies',
        ANOTHER_GENERAL_PRACTITIONER = 'another_general_practitioner',
        RHEUMATOLOGIST = 'rheumatologist',
    }

    export enum lastTreatment {
        NO_TREATMENT = 'no_treatment',
        COLCHICINE = 'colchicine',
        PAINKILLERS = 'painkillers',
        ANTI_INFLAMMATORY = 'anti_inflammatory',
        HYPOURICEMIC = 'hypouricemic',
    }


}

