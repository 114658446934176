/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewPatientPractitionerType } from '../models/NewPatientPractitionerType';
import type { NewUalType } from '../models/NewUalType';
import type { Patient } from '../models/Patient';
import type { Patient4 } from '../models/Patient4';
import type { Patient5 } from '../models/Patient5';
import type { Patient6 } from '../models/Patient6';
import type { PatientProfileType } from '../models/PatientProfileType';
import type { PatientUpdatePasswordType } from '../models/PatientUpdatePasswordType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MePatientService {

    /**
     * Patient information
     * Get patient information
     * @returns Patient Return patient informations
     * @throws ApiError
     */
    public static getPatientMeProfile(): CancelablePromise<Patient> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/patient/me/',
        });
    }

    /**
     * Update patient information
     * Permit to update patient information
     * @param requestBody
     * @returns Patient4 Update patient informations
     * @throws ApiError
     */
    public static putPatientMeProfileUpdate(
        requestBody?: PatientProfileType,
    ): CancelablePromise<Patient4> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/patient/me',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update password
     * Permit to update patient password
     * @param requestBody
     * @returns Patient4 Update patient password
     * @throws ApiError
     */
    public static putPatientMePasswordUpdate(
        requestBody?: PatientUpdatePasswordType,
    ): CancelablePromise<Patient4> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/patient/me/update_password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * UAL get history
     * Permit to get ual's history.
     * @returns Patient5 Return token and refreshToken.
     * @throws ApiError
     */
    public static getPatientMeUalList(): CancelablePromise<Patient5> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/patient/me/ual',
        });
    }

    /**
     * UAL creation
     * Permit to add an ual to history.
     * @param requestBody
     * @returns Patient5 Return token and refreshToken.
     * @throws ApiError
     */
    public static postPatientMeUalNew(
        requestBody?: NewUalType,
    ): CancelablePromise<Patient5> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/patient/me/ual',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Select practitioner
     * Permit to select a practitioner
     * @param requestBody
     * @returns Patient6 Return patient with practitioner
     * @throws ApiError
     */
    public static putPatientMeSelectPractitioner(
        requestBody?: NewPatientPractitionerType,
    ): CancelablePromise<Patient6> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/patient/me/practitioner',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
