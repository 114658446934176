import axios, {AxiosError, AxiosResponse} from "axios";
import notify, {NotificationType} from "@/notify";

axios.interceptors.response.use(
    (config: AxiosResponse) => {
        return config;
    }, (error: AxiosError) => {
        if (error.response?.status && error.response?.status >= 500) {
            notify.addNotification({
                title: "global.error.5xx.title",
                content: "global.error.5xx.content",
                icon: "mdi-error",
                type: NotificationType.ERROR,
            });

            return;
        }

        return Promise.reject(error);
    },
);