<template>
    <div id="splash-screen">
        <img :src="require('@/assets/svg/splashScreen/logo.svg')" :alt="$t('global.app')">
    </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
#splash-screen {
    background-color: #FFF0EB;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>