<template>
    <form id="select-hospital-view" @submit.prevent="onSubmit">
        <header>
            <h1>
                <Logo/>
            </h1>
        </header>

        <main>
            <div class="mt-10">
                <h2>
                    Votre médecin vous a-t-il proposé AppliGoutte ?
                </h2>

                <v-item-group
                    v-model="toggleFirstRow"
                >
                    <v-row class="mt-2">
                        <v-col cols="6">
                            <v-item>
                                <template v-slot:default="{ toggle }">
                                    <v-btn
                                        @click="() => {toggle(); ignoreFollowUp(); moveToLogin();}"
                                        :active="toggleFirstRow != null && toggleFirstRow === 0"
                                    >
                                        Non
                                    </v-btn>
                                </template>
                            </v-item>
                        </v-col>
                        <v-col cols="6">
                            <v-item>
                                <template v-slot:default="{ toggle }">
                                    <v-btn
                                        @click="toggle"
                                        :active="toggleFirstRow != null && toggleFirstRow === 1"
                                    >
                                        Oui
                                    </v-btn>
                                </template>
                            </v-item>
                        </v-col>
                    </v-row>
                </v-item-group>
            </div>

            <div v-if="showSecondRow" class="mt-10">
                <h2>
                    Voulez-vous vous connecter à votre médecin ?
                </h2>

                <v-item-group
                    v-model="toggleSecondRow"
                >
                    <v-row class="mt-2">
                        <v-col cols="6">
                            <v-item>
                                <template v-slot:default="{ toggle }">
                                    <v-btn
                                        @click="() => {toggle(); ignoreFollowUp(); moveToLogin();}"
                                        :active="toggleSecondRow != null && toggleSecondRow === 0"
                                    >
                                        Non
                                    </v-btn>
                                </template>
                            </v-item>
                        </v-col>
                        <v-col cols="6">
                            <v-item>
                                <template v-slot:default="{ toggle }">
                                    <v-btn
                                        @click="toggle"
                                        :active="toggleSecondRow != null && toggleSecondRow === 1"
                                    >
                                        Oui
                                    </v-btn>
                                </template>
                            </v-item>
                        </v-col>
                    </v-row>
                </v-item-group>
            </div>

            <div v-if="showThirdRow">
                <h2 class="mt-10">
                    Entrez ici le code à 6 chiffres que vous avez reçu :
                </h2>

                <div class="mt-2">
                    <v-otp-input
                        :length="6" placeholder="0" v-model="invitationCode"
                        :loading="patientClinicalTestSessionQuery.isLoading.value"
                    />
                </div>
            </div>

            <div v-if="showFourthRow">
                <h2 class="mt-10">
                    Sélectionnez le centre où vous êtes suivi :
                </h2>
                <div class="mt-2">
                    <v-select
                        label="Centre"
                        :items="centers"
                        item-title="name"
                        return-object="return-object"
                        v-model="center"
                    ></v-select>
                </div>
            </div>

            <div v-if="showFifthRow">
                <h2 class="mt-10">
                    Sélectionnez votre médecin référent :
                </h2>
                <div class="mt-2">
                    <v-select
                        label="Medecin"
                        :items="practitioners"
                        v-model="practitioner"
                    >
                        <template v-slot:item="{ props, item, index }">
                            <div v-bind="props" class="select-item">
                                <div class="select-item--title">
                                    Dr {{ item.raw.lastname }} {{ item.raw.firstname }}
                                </div>
                                <img
                                    class="select-item--img"
                                    :src="require('@/assets/svg/registration/practitioner.svg')"
                                    :alt="`Dr ${item.raw.lastname} ${item.raw.firstname}`"
                                >
                            </div>
                            <div v-if="index !== (practitioners.length - 1)" class="separator"></div>
                        </template>

                        <template v-slot:selection="{ props, item }">
                            Dr {{ item.raw.lastname }} {{ item.raw.firstname }}
                        </template>
                    </v-select>
                </div>
            </div>
        </main>

        <footer>
            <v-row class="mt-15">
                <v-col cols="6">
                    <v-btn-danger @click.prevent="()=> {ignoreFollowUp(); moveToLogin()}">
                        Ignorer
                    </v-btn-danger>
                </v-col>

                <v-col cols="6">
                    <v-btn-success type="submit" :disabled="!isValid">
                        Valider
                    </v-btn-success>
                </v-col>
            </v-row>
        </footer>

        <div class="background-img">
            <img
                :src="require('@/assets/svg/registration/illustrations_consultations-specialiste-1.svg')"
                :alt="$t('global.app')"
            />
        </div>
    </form>
</template>

<script lang="ts" setup>
import {useRouter} from "vue-router";
import {computed, Ref, ref, watch} from "vue";
import Logo from "@/components/logo/Logo.vue";
import ClinicalTestManager from "@/managers/ClinicalTestManager";
import Notify, {NotificationIcon, NotificationType} from "@/notify";
import {Center3, Practitioner4} from "@/.generated/api";
import Browser from "@/utils/Browser";

const router = useRouter();

const toggleFirstRow = ref(undefined);
const toggleSecondRow = ref(undefined);
const invitationCode = ref("");
const center: Ref<undefined | Center3> = ref(undefined);
const practitioner: Ref<undefined | Practitioner4> = ref(undefined);

const patientClinicalTestSessionQuery = ClinicalTestManager.usePatientClinicalTestSessionQuery(invitationCode);
const patientClinicalTestFollowUpMutation = ClinicalTestManager.usePatientClinicalTestFollowUpMutation();
const patientClinicalTestFollowUpIgnoreMutation = ClinicalTestManager.usePatientClinicalTestFollowUpIgnoreMutation();

watch(patientClinicalTestSessionQuery.isError, () => {
    if (patientClinicalTestSessionQuery.isError.value) {
        invitationCode.value = "";

        Notify.addNotification({
            title: "Code d'invitation invalide",
            content: "Votre code est invalide, merci de le vérifier, ou contacter le médecin qui vous a proposé AppliGoutte",
            type: NotificationType.ERROR,
            icon: NotificationIcon.ERROR,
        });
    }
});

const showSecondRow = computed(() => {
    return !!toggleFirstRow.value;
});

const showThirdRow = computed(() => {
    return showSecondRow.value && !!toggleSecondRow.value;
});

const showFourthRow = computed(() => {
    return showThirdRow.value && patientClinicalTestSessionQuery.isSuccess.value;
});

const showFifthRow = computed(() => {
    return showFourthRow.value && !!center.value;
});

const isValid = computed(() => {
    return showFifthRow.value && !!practitioner.value;
});

const moveToLogin = () => {
    router.push({name: "login"});
};

const centersWithDuplicates = computed((): Center3[] => {
    return patientClinicalTestSessionQuery.data.value?.practitioners ?
        patientClinicalTestSessionQuery.data.value?.practitioners
            ?.map((practitioner: Practitioner4) => practitioner.center) as Center3[]
        :
        [];
});
const centers = computed((): Center3[] => {
    return [...new Map<number, Center3>(centersWithDuplicates.value.map(v => [v.id, v]) as [number, Center3][]).values()];
});

const practitioners = computed((): Practitioner4[] => {
    return patientClinicalTestSessionQuery.data.value?.practitioners ?
        patientClinicalTestSessionQuery.data.value?.practitioners
            .filter((practitioner: Practitioner4) => practitioner.center?.id === center.value?.id) as Practitioner4[]
        :
        [];
});

watch(patientClinicalTestSessionQuery.data, () => {
    center.value = undefined;
    practitioner.value = undefined;
});

watch([showSecondRow, showThirdRow, showFourthRow, showFifthRow], () => {
    Browser.scrollTo(document.getElementById("select-hospital-view"), true);
});

const onSubmit = async () => {
    try {
        await patientClinicalTestFollowUpMutation.mutateAsync({
            practitioner: practitioner.value?.id?.toString() ?? "",
            session: invitationCode.value ?? "",
        });

        Notify.addNotification({
            title: "Inscription au suivi réussie",
            content: `Le Dr ${practitioner.value?.firstname} va maintenant pouvoir vous suivre à distance.`,
            type: NotificationType.SUCCESS,
            icon: NotificationIcon.SUCCESS,
        });

        await router.push({
            name: "home",
        });
    } catch (e) {
        Notify.addNotification({
            title: "Erreur lors de votre inscription au suivi",
            type: NotificationType.ERROR,
            icon: NotificationIcon.ERROR,
        });
    }
};

const ignoreFollowUp = async () => {
    await patientClinicalTestFollowUpIgnoreMutation.mutateAsync(undefined);

    await router.push({
        name: "home",
    });
};
</script>

<style lang="scss" scoped>
#select-hospital-view {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #FFECE5;
    overflow: auto;

    header, main, footer {
        position: relative;
        z-index: 1;
    }

    main {
        flex: 1;
    }

    .background-img {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;

        display: flex;
        justify-content: center;

        img {
            width: 80%;
            max-width: calc(600px * 80 / 100);
        }
    }
}

.select-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 50px;

    &--title {
        flex: 1;
        font-weight: bold;
        align-self: center;
    }

    &--img {
        width: 100px;
        height: 100px;
        object-fit: cover;
    }

    & + .separator {
        border-bottom: 1px solid lightgray;
    }
}
</style>