import {useQuery} from "@tanstack/vue-query";
import {ModuleService} from "@/.generated/api";

export default abstract class ModuleManager {
    public static useModuleListQuery() {
        return useQuery({
            queryKey: ["module", "list"],
            queryFn: ModuleService.getPatientModuleList,
        });
    }

    public static useModuleProfileListQuery() {
        return useQuery({
            queryKey: ["module", "list", "profile"],
            queryFn: ModuleService.getPatientModuleProfile,
        });
    }

    public static useModuleShowQuery(moduleId: string) {
        return useQuery({
            queryKey: ["module", "show", moduleId],
            queryFn: () => ModuleService.getPatientModuleShow(moduleId),
        });
    }
}