<template>
    <div class="slide">
        <main>
            <div id="title">
                Bienvenue
            </div>
            <p id="content">
                <strong>AppliGoutte</strong><br/>
                accompagne les personnes atteintes
                de goutte sur le chemin de la guérison !
            </p>

        </main>

        <div class="intro-background">
            <img :src="require('@/assets/svg/intro/mountain.svg')" :alt="$t('global.app')" width="300">
        </div>
    </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
main {
    #title {
        font-weight: 700;
        font-size: 44px;
        color: #13002C;

    }

    #content {
        font-size: 20px;
        color: #13002C;

    }
}
</style>