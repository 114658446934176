/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CrisisDiagnosisType = {
    painLevel: number;
    swollenJointLevel: CrisisDiagnosisType.swollenJointLevel;
    heatJointLevel: CrisisDiagnosisType.heatJointLevel;
};

export namespace CrisisDiagnosisType {

    export enum swollenJointLevel {
        NOT_AT_ALL = 'not_at_all',
        LOW = 'low',
        MEDIUM = 'medium',
        HIGH = 'high',
    }

    export enum heatJointLevel {
        NOT_AT_ALL = 'not_at_all',
        LOW = 'low',
        MEDIUM = 'medium',
        HIGH = 'high',
    }


}

