/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Module } from '../models/Module';
import type { Module2 } from '../models/Module2';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ModuleService {

    /**
     * Module list
     * Get all modules
     * @returns Module Return all modules
     * @throws ApiError
     */
    public static getPatientModuleList(): CancelablePromise<Array<Module>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/patient/module',
        });
    }

    /**
     * Module Profile
     * Get modules depending of patient profile
     * @returns Module Get modules depending of patient profile
     * @throws ApiError
     */
    public static getPatientModuleProfile(): CancelablePromise<Module> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/patient/module/profile',
        });
    }

    /**
     * Module content and quiz
     * Show all module content and quiz
     * @param moduleId
     * @returns Module2 Return all module content and quiz
     * @throws ApiError
     */
    public static getPatientModuleShow(
        moduleId: string,
    ): CancelablePromise<Module2> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/patient/module/{moduleId}',
            path: {
                'moduleId': moduleId,
            },
        });
    }

}
