<template>
    <div>
        <v-tooltip
            :open-on-click="true"
            :open-on-focus="true"
            location="bottom"
        >
            <template v-slot:activator="{ props }">
                <v-btn :block="false" rounded="xl" size="x-small" v-bind="props" :icon="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M12 7C11.45 7 10.979 6.804 10.587 6.412C10.195 6.02 9.99934 5.54934 10 5C10 4.45 10.196 3.979 10.588 3.587C10.98 3.195 11.4507 2.99934 12 3C12.55 3 13.021 3.196 13.413 3.588C13.805 3.98 14.0007 4.45067 14 5C14 5.55 13.804 6.021 13.412 6.413C13.02 6.805 12.5493 7.00067 12 7ZM12 21C11.5833 21 11.229 20.854 10.937 20.562C10.645 20.27 10.4993 19.916 10.5 19.5V10.5C10.5 10.0833 10.646 9.729 10.938 9.437C11.23 9.145 11.584 8.99934 12 9C12.4167 9 12.771 9.146 13.063 9.438C13.355 9.73 13.5007 10.084 13.5 10.5V19.5C13.5 19.9167 13.354 20.271 13.062 20.563C12.77 20.855 12.416 21.0007 12 21Z"
                            fill="#66C0BE"/>
                    </svg>
                </v-btn>
            </template>
            <div style="max-width:80vw" v-html="textHtml"></div>
        </v-tooltip>
    </div>
</template>

<script setup lang="ts">
import {computed, toRefs} from "vue";
import * as DOMPurify from "dompurify";

const props = defineProps<{
    text: string
}>();

const {text} = toRefs(props);

const textHtml = computed(() => {
    return DOMPurify.sanitize(text.value, {USE_PROFILES: {}}).trim().replace(/(\r\n|\r|\n)+/g, "<br>");
});
</script>

<style scoped lang="scss">

</style>