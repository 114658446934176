import {RouteRecordRaw} from "vue-router";
import SelectPractitionerView from "@/views/clinicalTestFollowUp/SelectPractitionerView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/clinicalTest/followUp/practitioner",
        name: "selectPractitioner",
        component: SelectPractitionerView,
        meta: {
            layout: "FullScreenLayout",
        },
    },
];

export default routes;
