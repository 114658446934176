export default abstract class Browser {
    public static isMobile(): boolean {
        return !!navigator.userAgent.match(/(Android|iPhone|iPad|iPod|Mobile|Tablet)/);
    }

    public static scrollTo(htmlElement: HTMLElement, bottom: Boolean = false): void {
        setTimeout(() => {
            htmlElement.scrollTo({
                left: 0,
                top: bottom ? htmlElement.scrollHeight : 0,
                behavior: "smooth",
            });
        }, 50);
    }
}