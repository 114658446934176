<template>
    <div id="module-view">
        <Swiper
            :modules="[Pagination, Navigation]"
            :navigation="true"
            :space-between="20"
            @swiper="(currentSwiper) => swiper = currentSwiper"
            :allowTouchMove="false"
        >
            <template v-if="module">
                <SwiperSlide
                    v-if="module"
                    v-for="(content, indexContent) in module.contents"
                >
                    <Breadcrumb :current-step="Number.parseInt(indexContent.toString())"
                                :nb-steps="module.contents.length" class="w-75 ma-auto mb-10"/>

                    <template v-if="content.video">
                        <div v-if="!videoOpened[content.id]" class="cover">
                            <img
                                class="cover-img aspect-16-9" :src="content.video.previewUrl??content.cover?.signedUrl"
                                :alt="content.title"
                            >
                            <div class="cover-play">
                                <img
                                    :src="require('@/assets/svg/play.svg')"
                                    alt="play"
                                    @click="videoOpened[content.id] = true"
                                />
                            </div>
                        </div>
                        <div v-else>
                            <iframe class="aspect-16-9" :src="content.video.iframeUrl" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen
                            ></iframe>
                        </div>
                    </template>

                    <div class="content">
                        <h2>
                            {{ content.title }}
                        </h2>

                        <div v-html="content.text"></div>

                        <div class="navigation-buttons">
                            <v-btn v-if="indexContent > 0" @click.prevent="goToPrevious">
                                Précédent
                            </v-btn>

                            <v-btn-success @click.prevent="contentDone(content.id)">
                                Suivant
                            </v-btn-success>
                        </div>
                    </div>
                </SwiperSlide>

                <template v-for="quiz in module.quizs">
                    <SwiperSlide
                        v-for="(question, key) in quiz.questions"
                    >
                        <div class="content">
                            <div class="pb-8">
                                <h2>
                                    {{ question.text }}
                                </h2>
                                <div style="text-align: center">({{ question.nbMaxAnswers }}
                                    réponse{{ question.nbMaxAnswers > 1 ? "s" : "" }} max)
                                </div>
                            </div>
                            <div class="answers">
                                <v-btn
                                    type="button" v-for="answer in question.attributes.answers"
                                    @click.prevent="answerToggle(quiz.id, question.id, answer.uuid, question.nbMaxAnswers)"
                                    :variant="answers[quiz.id] && answers[quiz.id][question.id] && answers[quiz.id][question.id].includes(answer.uuid) ? 'tonal' : 'elevated' as  'tonal'|'elevated'"
                                    class="no-text-transform mb-2"
                                >
                                    <p class="text-wrap ma-auto">
                                        {{ answer.text }}
                                    </p>
                                </v-btn>
                            </div>
                            <div class="navigation-buttons">
                                <v-btn @click.prevent="goToPrevious">
                                    Précédent
                                </v-btn>
                                <v-btn @click.prevent="validAnswers(quiz.id, question.id)">
                                    Valider
                                </v-btn>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div class="fill-height d-flex flex-column justify-space-between align-center">
                            <h2>{{ quiz.title }}</h2>

                            <QuizMedal :score="results[quiz.id]??0"/>

                            <v-btn @click.prevent="goToHome" class="ma-5 flex-grow-0">
                                Terminer
                            </v-btn>
                        </div>
                    </SwiperSlide>
                </template>
            </template>
        </Swiper>
    </div>
</template>

<script lang="ts" setup>
import {Swiper, SwiperSlide} from "swiper/vue";
import ModuleManager from "@/managers/ModuleManager";
import {computed, ComputedRef, onMounted, ref, watch} from "vue";
import {Navigation, Pagination} from "swiper";
import {useRoute, useRouter} from "vue-router";
import ContentManager from "@/managers/ContentManager";
import QuizManager from "@/managers/QuizManager";
import Notify, {NotificationType} from "@/notify";
import QuizMedal from "@/components/learning/QuizMedal.vue";
import {Swiper as SwiperClass} from "swiper/types";
import Browser from "@/utils/Browser";


const route = useRoute();

const moduleId: ComputedRef<string> = computed(() => {
    return route.params.moduleId.toString();
});
const videoOpened = ref<boolean[]>([]);

const moduleShowQuery = ModuleManager.useModuleShowQuery(moduleId.value);

const module = computed(() => {
    return moduleShowQuery.data.value;
});

watch(module, (data) => {
    if (!data || !data.contents || !data.quizs || !swiper.value?.isBeginning) {
        return;
    }

    const step = router.currentRoute.value.query.step;
    if (step) {
        const type = (step as string)[0];
        const id = (step as string).substring(1);

        if (type === "c") {
            const index = data.contents.findIndex(content => content.id === Number.parseInt(id));
            if (index !== -1) {
                swiper.value?.slideTo(index + 1);
            }
        } else if (type === "q") {
            const index = data.quizs.findIndex(quiz => quiz.id === Number.parseInt(id));
            if (index !== -1) {
                swiper.value?.slideTo(data.contents.length + index * 2 + 2);
            }
        }

        return;
    }

    let indexToGo = 0;

    for (let content of data.contents) {
        if (content.isRead) {
            indexToGo++;
        } else {
            break;
        }
    }

    if (indexToGo === 0) {
        return;
    }

    for (let quiz of data.quizs) {
        if (quiz.isDone) {
            indexToGo += quiz.questions.length + 2;
        } else {
            break;
        }
    }

    if (indexToGo === (data.contents.length + data.quizs.map(quiz => quiz.questions.length + 2).reduce((acc, cv) => acc + cv, 0))) {
        return;
    }

    swiper.value?.slideTo(indexToGo);
});


const swiper = ref<SwiperClass | null>(null);

const router = useRouter();

const contentDoneMutation = ContentManager.useContentDoneMutation();
const quizAnswerMutation = QuizManager.useQuizAnswerMutation();

const answers = ref<Record<number, Record<number, string[]>>>({});
const results = ref<Record<number, number>>({});

const currentSlideIndex = computed(() => {
    let swiperUnwrapped = swiper.value;

    if (!swiperUnwrapped) {
        return;
    }

    return swiperUnwrapped.activeIndex;
});

const goToPrevious = () => {
    let swiperUnwrapped = swiper.value;

    if (!swiperUnwrapped) {
        return;
    }

    swiperUnwrapped.slidePrev();
    scrollToTop();
};

function goToNext() {
    let swiperUnwrapped = swiper.value;

    if (!swiperUnwrapped) {
        return;
    }

    if (swiperUnwrapped.isEnd) {
        goToHome();
    }

    swiperUnwrapped.slideNext();
    scrollToTop();
}

function scrollToTop() {
    let htmlElement: HTMLElement = document.querySelector("html");

    Browser.scrollTo(htmlElement);
}

const updateSlideMaxHeight = () => {
    let swiperUnwrapped = swiper.value;
    if (!swiperUnwrapped) {
        return;
    }


    const currentSlide = swiperUnwrapped.slides[swiperUnwrapped.activeIndex];
    const slides = Array.from(swiperUnwrapped.wrapperEl.children) as HTMLElement[];

    for (const slide of slides) {
        slide.style.maxHeight = currentSlide.offsetHeight + "px";
    }
};

watch(currentSlideIndex, () => {
    updateSlideMaxHeight();
});

onMounted(updateSlideMaxHeight);

const contentDone = (id: number) => {
    contentDoneMutation.mutate(id.toString());

    goToNext();
};

const lastQuestionIdPerQuizs: ComputedRef<Record<number, number>> = computed(() => {
    const lastQuestionIdPerQuizs: Record<number, number> = {};

    module.value?.quizs?.forEach((quiz) => {
        if (!quiz.id || !quiz.questions || !quiz.questions.length) {
            return;
        }

        lastQuestionIdPerQuizs[quiz.id] = quiz.questions[quiz.questions.length - 1].id ?? 0;
    });

    return lastQuestionIdPerQuizs;
});

const validAnswers = async (quizId: number, questionId: number) => {
    if (!answers.value[quizId] || !answers.value[quizId][questionId] || !answers.value[quizId][questionId].length) {
        Notify.addNotification({
            title: "quiz.errors.missingAnswers.title",
            content: "quiz.errors.missingAnswers.content",
            icon: "mdi-error",
            type: NotificationType.ERROR,
        });

        return;
    }

    if (lastQuestionIdPerQuizs.value[quizId] === questionId) {
        const response = await quizAnswerMutation.mutateAsync({
            quizId: quizId.toString(),
            answers: Object.values(answers.value[quizId] || {}).reduce((acc, answerUuids) => acc.concat(answerUuids), []) as String[],
        });

        results.value[quizId] = response.currentQuizScore ?? 0;
    }

    goToNext();
};

const goToHome = () => {
    router.push({name: "home"});
};

const answerToggle = (quizId: number, questionId: number, answerUuid: string, nbMaxAnswers: number) => {
    if (!answers.value[quizId]) {
        answers.value[quizId] = [];
    }

    if (!answers.value[quizId][questionId]) {
        answers.value[quizId][questionId] = [];
    }

    if (answers.value[quizId][questionId].includes(answerUuid)) {
        answers.value[quizId][questionId] = answers.value[quizId][questionId].filter((uuid) => uuid !== answerUuid);
        return;
    }

    if (answers.value[quizId][questionId].length === nbMaxAnswers) {
        Notify.addNotification({
            title: "Nombre max de réponse atteinte",
            content: `Il y a au maximum ${nbMaxAnswers} réponse${nbMaxAnswers > 1 ? "s" : ""}.`,
            icon: "mdi-error",
            type: NotificationType.ERROR,
        });

        return;
    }

    answers.value[quizId][questionId].push(answerUuid);
};
</script>

<style lang="scss" scoped>
#module-view {
    position: relative;
    min-height: 100%;

    > .swiper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.cover {
    position: relative;

    > .cover-img {
        display: block;
    }

    > .cover-play {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        background: rgba(0, 0, 0, 0.3);

        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.content {
    padding: 50px 48px;

    h2 {
        padding-bottom: 30px;
    }

    > .navigation-buttons {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        margin-top: 50px;

        > * {
            flex: 1;
            min-width: auto;
        }
    }


    .answers {
        button {
            width: 100%;
            box-sizing: border-box;
        }
    }
}

.aspect-16-9 {
    max-width: 100%;
    min-width: 100%;
    aspect-ratio: 16 / 9;
}
</style>