<template>
    <div class="slide">
        <main>
            <div id="title">
                Des outils pour bien gérer
            </div>
            <ul id="content">
                <li>ses crises</li>
                <li>son traitement</li>
                <li>ses rendez-vous…</li>
            </ul>

        </main>

        <div class="intro-background">
            <img :src="require('@/assets/svg/intro/pain.svg')" :alt="$t('global.app')" width="300">
        </div>
    </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
main {
    #title {
        font-weight: 700;
        font-size: 44px;
        color: #13002C;

    }

    #content {
        padding: 30px;
        font-size: 20px;
        color: #13002C;
    }
}

.intro-background {
    justify-content: flex-end !important;
}
</style>