/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessagingSubscriptionType } from '../models/MessagingSubscriptionType';
import type { MessagingSubscriptionType2 } from '../models/MessagingSubscriptionType2';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MessagingService {

    /**
     * Subscribe current user to Messaging
     * Permit to subscribe current user to Messaging
     * @param requestBody
     * @returns MessagingSubscriptionType2 Subscription created
     * @throws ApiError
     */
    public static postPatientMessagingSubscribe(
        requestBody?: MessagingSubscriptionType,
    ): CancelablePromise<MessagingSubscriptionType2> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/patient/messaging/subscription',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
