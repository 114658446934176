<template>
    <div class="slide">
        <h2>
            Que ressentez-vous aujourd’dhui ?
        </h2>

        <v-item-group
            v-model="model.crisisDailyFollowingType.feelings"
        >
            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="toggleFeeling('swelling')"
                                :active="isFeelingSelected('swelling')"
                            >
                                Gonflement
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="toggleFeeling('redness')"
                                :active="isFeelingSelected('redness')"
                            >
                                Rougeur
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="toggleFeeling('heat')"
                                :active="isFeelingSelected('heat')"
                            >
                                Chaleur
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>
        </v-item-group>
    </div>
</template>

<script lang="ts" setup>
import {CrisisDailyFollowingType, PositioningType} from "@/.generated/api";
import {CustomDiagnosisType} from "@/views/gout/CrisisDiagnosisView.vue";
import {CrisisDailyCustomModel} from "@/views/gout/CrisisDailyFollowingView.vue";

const model = defineModel<CrisisDailyCustomModel>({
    required: true,
});

model.value.crisisDailyFollowingType.feelings = [];

const toggleFeeling = (feeling: CrisisDailyFollowingType["feelings"][number]) => {
    if (model.value.crisisDailyFollowingType.feelings === undefined) {
        model.value.crisisDailyFollowingType.feelings = [];
    }

    const index = model.value.crisisDailyFollowingType.feelings.indexOf(feeling);

    if (index === -1) {
        model.value.crisisDailyFollowingType.feelings.push(feeling);
        return;
    }

    model.value.crisisDailyFollowingType.feelings.splice(index, 1);
};
const isFeelingSelected = (treatment: PositioningType["feelings"][number]): boolean => {
    return model.value.crisisDailyFollowingType.feelings?.includes(treatment) ?? false;
};
</script>

<style lang="scss" scoped>
</style>