<template>
    <div class="slide">
        <h2>
            Avez-vous d'autres maladies ?
        </h2>

        <v-item-group
            v-model="modelValue.otherDiseases"
        >
            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="toggleOtherDisease('diabetes')"
                                :active="isDiseaseSelected('diabetes')"
                            >
                                Diabète
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>


            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="toggleOtherDisease('transplantation')"
                                :active="isDiseaseSelected('transplantation')"
                            >
                                Transplantation
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>


            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="toggleOtherDisease('hypertension')"
                                :active="isDiseaseSelected('hypertension')"
                            >
                                Hypertension artérielle
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>


            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="toggleOtherDisease('cardiac insufficiency')"
                                :active="isDiseaseSelected('cardiac insufficiency')"
                            >
                                Insuffisance cardiaque
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>


            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="toggleOtherDisease('cholesterol')"
                                :active="isDiseaseSelected('cholesterol')"
                            >
                                Problèmes de cholestérol
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>


            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="toggleOtherDisease('renal insufficiency')"
                                :active="isDiseaseSelected('renal insufficiency')"
                            >
                                Insuffisance rénale
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>


            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="toggleOtherDisease('coronary diseases')"
                                :active="isDiseaseSelected('coronary diseases')"
                                style="justify-content: space-between"
                            >
                                <template v-slot:prepend>
                                </template>
                                Maladies coronaires
                                <template v-slot:append>
                                    <InfoTooltip
                                        text="
                                                Infarctus du myocarde
                                                Angine de poitrine
                                            "
                                    />
                                </template>
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>


            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="toggleOtherDisease('other vascular problems')"
                                :active="isDiseaseSelected('other vascular problems')"
                                style="justify-content: space-between"
                            >
                                <template v-slot:prepend>
                                </template>
                                Autres problèmes vasculaires
                                <template v-slot:append>
                                    <InfoTooltip
                                        text="
                                                AVC
                                                Artérite des membres inférieurs
                                                Ischémie mésentérique
                                            "
                                    />
                                </template>
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>
        </v-item-group>
    </div>
</template>

<script lang="ts" setup>

import {PositioningType} from "@/.generated/api";
import {toRefs} from "vue";
import InfoTooltip from "@/components/tooltip/InfoTooltip.vue";

const props = defineProps<{
    modelValue: Partial<PositioningType>
}>();

const {modelValue} = toRefs(props);

const toggleOtherDisease = (treatment: PositioningType["otherDiseases"][number]) => {
    if (modelValue.value.otherDiseases === undefined) {
        modelValue.value.otherDiseases = [];
    }

    const index = modelValue.value.otherDiseases.indexOf(treatment);

    if (index === -1) {
        modelValue.value.otherDiseases.push(treatment);
        return;
    }

    modelValue.value.otherDiseases.splice(index, 1);
};
const isDiseaseSelected = (treatment: PositioningType["otherDiseases"][number]): boolean => {
    return modelValue.value.otherDiseases?.includes(treatment) ?? false;
};
</script>

<style lang="scss" scoped>
</style>