import * as validators from "@vuelidate/validators";
import {createI18nMessage} from "@vuelidate/validators";
import i18n from "@/plugins/i18n";

export default abstract class Vuelidate {
    private static withI18nMessage = createI18nMessage({
        // @ts-ignore
        t: i18n.global.t.bind(i18n),
        messagePath: (params) => "validation." + (<any>params.$params).type,
    });

    public static alpha = this.withI18nMessage(validators.alpha);
    public static alphaNum = this.withI18nMessage(validators.alphaNum);
    public static and = this.withI18nMessage(validators.and, {withArguments: true});
    public static between = this.withI18nMessage(validators.between, {
        withArguments: true,
    });
    public static decimal = this.withI18nMessage(validators.decimal, {
        withArguments: true,
    });
    public static email = this.withI18nMessage(validators.email);
    public static integer = this.withI18nMessage(validators.integer);
    public static ipAddress = this.withI18nMessage(validators.ipAddress);
    public static macAddress = this.withI18nMessage(validators.macAddress);
    public static maxLength = this.withI18nMessage(validators.maxLength, {
        withArguments: true,
    });
    public static maxValue = this.withI18nMessage(validators.maxValue, {
        withArguments: true,
    });
    public static minLength = this.withI18nMessage(validators.minLength, {
        withArguments: true,
    });
    public static minValue = this.withI18nMessage(validators.minValue, {
        withArguments: true,
    });
    public static not = this.withI18nMessage(validators.not);
    public static numeric = this.withI18nMessage(validators.numeric);
    public static or = this.withI18nMessage(validators.or, {withArguments: true});
    public static required = this.withI18nMessage(validators.required);
    public static requiredIf = this.withI18nMessage(validators.requiredIf);
    public static requiredUnless = this.withI18nMessage(validators.requiredUnless);
    public static sameAs = this.withI18nMessage(validators.sameAs, {
        withArguments: true,
    });
    public static url = this.withI18nMessage(validators.url);
}
