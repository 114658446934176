export default abstract class Color {
    public static WHITE: string = "#FFFFFF";
    public static BLACK: string = "#000000";

    public static getBlackOrWhiteBestConstrast(backgroundColor: string | undefined): string {
        if (!backgroundColor) {
            return this.BLACK;
        }

        const rgb = backgroundColor.match(/\d+/g)?.map(Number);

        if (!rgb) {
            return this.BLACK;
        }

        const luminance = 0.2126 * rgb[0] / 255 + 0.7152 * rgb[1] / 255 + 0.0722 * rgb[2] / 255;

        return luminance > 0.5 ? this.BLACK : this.WHITE;
    }

    public static adjustColorBrightness(color: string, percent: number): string {
        const num = parseInt(color.replace("#", ""), 16);
        const amt = Math.round(2.55 * percent);


        const colors = {
            R: (num >> 16) + amt,
            G: (num >> 8 & 0x00FF) + amt,
            B: (num & 0x0000FF) + amt,
        };

        return "#" + (Object.keys(colors) as Array<keyof typeof colors>).map((key) => {
            const color = colors[key];
            return (color < 255 ? (color < 1 ? 0 : color) : 255).toString(16).padStart(2, "0");
        }).join("");
    }
}