<template>
    <div class="slide">
        <h2>
            Quelles sont les articulations impactées ?
        </h2>

        <ArticulationsBody
            :articulations-selected="modelValue.articulations as ArticulationType[]"
            @click="toggleArticulations"
            style="width: 100%;"
        />

        <ArticulationsChoice
            v-model="articulationsToChoose"
            @selected="articulationsSelected => modelValue.articulations = Array.from(new Set([...articulationsSelected, ...modelValue.articulations]))"
        />
    </div>
</template>

<script lang="ts" setup>
import {Ref, ref, toRefs} from "vue";
import {CrisisInformationsType} from "@/.generated/api";
import ArticulationsBody from "@/components/gout/crisisInformations/ArticulationsBody.vue";
import {ArticulationType} from "@/types/Articulation";
import ArticulationsChoice from "@/components/gout/crisisInformations/ArticulationsChoice.vue";

const props = defineProps<{
    modelValue: Partial<CrisisInformationsType>
}>();

const {modelValue} = toRefs(props);

const articulationsToChoose: Ref<ArticulationType[] | undefined> = ref(undefined);

function toggleArticulations(articulations: ArticulationType[]) {
    const temp = new Set(modelValue.value.articulations ?? []);
    let openChoice = true;

    articulations.forEach(articulation => {
        if (temp.has(articulation)) {
            temp.delete(articulation);
            openChoice = false;
        }
    });

    if (openChoice) {
        articulationsToChoose.value = articulations;
    }

    modelValue.value.articulations = Array.from(temp);
}
</script>


<style lang="scss" scoped>
</style>