import {useMutation} from "@tanstack/vue-query";
import {QuizService} from "@/.generated/api";

export default abstract class QuizManager {
    public static useQuizAnswerMutation() {
        return useMutation({
            mutationFn: (data: {
                quizId: string,
                answers: string[]
            }) => QuizService.postPatientQuizPostAnswer(data.quizId, {answers: data.answers}),
        });
    }
}