<template>
    <v-col
        class="notification bg-white mb-3"
        cols="auto"
        :style="style"
    >
        <v-alert
            :title="$t(notification.title)"
            :type="notification.type"
            variant="tonal"
            :closable="true"
            @click:close="onClose"
        >
            {{ notification.content ? $t(notification.content) : "" }}
        </v-alert>
    </v-col>
</template>

<script lang="ts" setup>
import {Notification} from "@/notify";
import {computed, toRefs} from "vue";

const props = defineProps<{
    notification: Notification
}>();
const {notification} = toRefs(props);

const emit = defineEmits<{
    close: []
}>();

const style = computed(() => {
    return ((notification.value.hide) ? "opacity: 0;" : "");
});

const hide = computed(() => {
    return !notification.value.hide;
});

const onClose = () => {
    notification.value.hide = true;
    emit("close");
};
</script>

<style scoped lang="scss">
.notification {
    display: flex;
    align-items: center;
    opacity: 1;
    transition: opacity 500ms linear;

    .v-alert {
        max-width: calc(600px * 0.90);
        min-width: 300px;
        width: 90vw;
    }
}
</style>