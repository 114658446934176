import {useMutation, useQueryClient} from "@tanstack/vue-query";
import {CrisisDailyFollowingType, CrisisFinishedType, GoutService, PositioningType} from "@/.generated/api";
import {CrisisInformationsType} from "@/.generated/api/models/CrisisInformationsType";
import {CrisisDiagnosisType} from "@/.generated/api/models/CrisisDiagnosisType";

export default abstract class GoutManager {
    public static usePatientGoutPositioningMutation() {
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: (goutPositioning: PositioningType) => GoutService.postPatientGoutPositioning(goutPositioning),
            onSuccess: async () => {
                await queryClient.invalidateQueries({queryKey: ["patient", "me", "profile"]});
            },
        });
    }

    public static usePatientGoutCrisisDiagnosisMutation() {
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: (goutDiagnosis: CrisisDiagnosisType) => GoutService.postPatientGoutCrisisDiagnosis(goutDiagnosis),
            onSuccess: async () => {
                await queryClient.invalidateQueries({queryKey: ["patient", "me", "profile"]});
            },
        });
    }

    public static usePatientGoutCrisisInformationsMutation() {
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: ({crisisId, crisisInformationsType}: {
                crisisId: string,
                crisisInformationsType: CrisisInformationsType
            }) => GoutService.postPatientGoutCrisisInformations(crisisId, crisisInformationsType),
            onSuccess: async () => {
                await queryClient.invalidateQueries({queryKey: ["patient", "me", "profile"]});
            },
        });
    }

    public static usePatientGoutCrisisDailyFollowingMutation() {
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: ({crisisId, crisisDailyFollowing}: {
                crisisId: string,
                crisisDailyFollowing: CrisisDailyFollowingType
            }) => GoutService.postPatientGoutCrisisDailyFollowing(crisisId, crisisDailyFollowing),
            onSuccess: async () => {
                await queryClient.invalidateQueries({queryKey: ["patient", "me", "profile"]});
            },
        });
    }

    public static usePatientGoutCrisisFinishMutation() {
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: ({crisisId, crisisFinished}: {
                crisisId: string, crisisFinished: CrisisFinishedType
            }) => GoutService.patchPatientGoutCrisisFinish(crisisId, crisisFinished),
            onSuccess: async () => {
                await queryClient.invalidateQueries({queryKey: ["patient", "me", "profile"]});
            },
        });
    }
}