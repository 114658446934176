import {createRouter, createWebHistory} from "vue-router";
import env from "@/config/env";
import routes from "@/router/routes/routes";
import securityManager from "@/managers/SecurityManager";
import notify, {NotificationType} from "@/notify";

const router = createRouter({
    history: createWebHistory(env.BASE_URL),
    routes: routes,
});

router.beforeEach(async (to, from, next) => {
    if (securityManager.isAuthenticated()) {
        if (to.matched.some((record) => record.meta.disallowConnected)) {
            next({name: "home"});
            return;
        }
    } else {
        const succeed = await securityManager.tryToRefreshToken();

        if (!succeed && !to.matched.some((record) => record.meta.allowAnonymous)) {
            notify.addNotification({
                title: "global.error.401.title",
                content: "global.error.401.content",
                icon: "mdi-error",
                type: NotificationType.ERROR,
            });

            next({name: "login"});
            return;
        }
    }

    next();
});

export default router;
