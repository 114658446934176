import UalView from "@/views/ual/UalView.vue";
import NewResult from "@/views/ual/NewResultView.vue";
import UalHistoric from "@/views/ual/UalHistoric.vue";
import {RouteRecordRaw} from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/ual",
        name: "ual",
        component: UalView,
        meta: {
            layout: "AppLayout"
        },
    },
    {
        path: "/ual/new/:step(\\d+)?",
        name: "ualNew",
        component: NewResult,
        meta: {
            layout: "AppLayout"
        }
    },
    {
        path: "/ual/historic",
        name: "ualHistoric",
        component: UalHistoric,
        meta: {
            layout: "AppLayout"
        },
    },
];

export default routes;