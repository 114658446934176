<template>
    <div class="slide">
        <v-row>
            <v-col cols="12">
                <h2>
                    Quand la crise a-t-elle commencé ?
                </h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-select
                    :items="['today', 'before']"
                    v-model="selectDate"
                    :item-title="item => $t(`global.${item}`)"
                    :item-value="item => item"
                ></v-select>
            </v-col>
        </v-row>
        <v-row v-if="selectDate === 'before'">
            <v-col cols="12">
                <UseElementSize v-slot="{ width, height }">
                    <v-date-picker
                        mode="date"
                        v-model="startedAtDateComputed"
                        :max="new Date()"
                        :width="width"
                    />
                </UseElementSize>
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts" setup>

import {CrisisInformationsType} from "@/.generated/api";
import {UseElementSize} from "@vueuse/components";
import {computed, Ref, ref, watch} from "vue";
import filters from "@/filters";

const crisisInformationType = defineModel<Partial<CrisisInformationsType>>({required: true});

const selectDate: Ref<string | undefined> = ref(undefined);

const startedAtDateComputed = computed({
    get: () => {
        if (!crisisInformationType.value.startedAt) {
            return undefined;
        }

        return new Date(crisisInformationType.value.startedAt);
    },
    set: (newDate) => {
        if (!newDate) {
            crisisInformationType.value.startedAt = undefined;
            return;
        }

        crisisInformationType.value.startedAt = filters.date(newDate);
    },
});

watch(selectDate, (nv) => {
    if (selectDate.value === "today") {
        startedAtDateComputed.value = new Date();
        return;
    }

    startedAtDateComputed.value = undefined;
});
</script>

<style lang="scss" scoped>
</style>