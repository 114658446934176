export default {
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "error": {
    "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauvais identifiants"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problème d'authentification"])}
  },
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se souvenir de moi"])},
  "success": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification réussie"])}
  }
}