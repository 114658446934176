<template>
    <v-dialog
        v-model="openModal"
        width="auto"
        transition="dialog-bottom-transition"
        scrollable
    >
        <v-card>
            <v-card-title>
                <v-icon @click="openModal = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-item>
                <v-card-text>
                    <form @submit.prevent="onSubmit">
                        <h3>Veuillez préciser les articulations concernées</h3>

                        <v-item-group
                            v-model="articulationsSelected"
                        >
                            <v-row class="mt-2" justify="space-around">
                                <v-col v-for="articulation of articulationsChoiceModel" cols="12" lg="6">
                                    <v-item>
                                        <template v-slot:default>
                                            <v-btn-transparent
                                                @click="toggleArticulation(articulation)"
                                                :active="isArticulationSelected(articulation)"
                                            >
                                                {{ $t(`crisis.articulations.${articulation}`) }}
                                            </v-btn-transparent>
                                        </template>
                                    </v-item>
                                </v-col>
                            </v-row>
                        </v-item-group>

                        <v-row class="mt-4">
                            <v-col cols="12">
                                <VBtnSuccess type="submit">Valider</VBtnSuccess>
                            </v-col>
                        </v-row>
                    </form>
                </v-card-text>
            </v-card-item>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import {ArticulationType} from "@/types/Articulation";
import {computed, Ref, ref, watch} from "vue";

const articulationsChoiceModel = defineModel<ArticulationType[] | undefined>();
const articulationsSelected: Ref<ArticulationType[]> = ref([]);

const openModal = computed({
    get: () => articulationsChoiceModel.value !== undefined,
    set: (open) => {
        if (!open) {
            articulationsChoiceModel.value = undefined;
        }
    },
});

watch(articulationsChoiceModel, () => {
    articulationsSelected.value = [];
});

const emit = defineEmits<{
    (e: "selected", articulation: ArticulationType[]): void
}>();

const toggleArticulation = (articulation: ArticulationType) => {
    if (articulationsSelected.value === undefined) {
        articulationsSelected.value = [];
    }

    const index = articulationsSelected.value.indexOf(articulation);

    if (index === -1) {
        articulationsSelected.value.push(articulation);
        return;
    }

    articulationsSelected.value.splice(index, 1);
};

const isArticulationSelected = (articulation: ArticulationType): boolean => {
    return articulationsSelected.value.includes(articulation) ?? false;
};

const onSubmit = () => {
    articulationsChoiceModel.value = undefined;
    emit("selected", articulationsSelected.value);
};
</script>

<style scoped lang="scss">
svg {
    max-width: 100%;
}

.v-card {
    max-width: 600px !important;
    background-color: #fcf1ec;

    .v-card-title {
        display: flex;
        justify-content: flex-end;
        background: red;
    }
}
</style>