<template>
    <div class="slide">
        <h2>
            Pensez-vous avoir une crise de goutte ?
        </h2>

        <v-item-group
            v-model="modelValue"
        >
            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="modelValue.thinkToHaveCrisis = false"
                                :active="modelValue.thinkToHaveCrisis === false"
                            >
                                Non
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="modelValue.thinkToHaveCrisis = true"
                                :active="modelValue.thinkToHaveCrisis === true"
                            >
                                Oui
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>
        </v-item-group>
    </div>
</template>

<script lang="ts" setup>

import {toRefs} from "vue";
import {CustomDiagnosisType} from "@/views/gout/CrisisDiagnosisView.vue";

const props = defineProps<{
    modelValue: CustomDiagnosisType
}>();

const {modelValue} = toRefs(props);
</script>

<style lang="scss" scoped>
</style>