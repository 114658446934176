import {useMutation, useQuery, UseQueryOptions} from "@tanstack/vue-query";
import {MePatientService, NewPatientPractitionerType, NewUalType, PatientProfileType} from "@/.generated/api";

export default abstract class MeManager {
    public static useUalListQuery() {
        return useQuery({
            queryKey: ["patient", "ualHistoric"],
            queryFn: MePatientService.getPatientMeUalList,
        });
    }

    public static useUalNewMutation() {
        return useMutation({
            mutationFn: (newUalType: NewUalType) => MePatientService.postPatientMeUalNew(newUalType),
        });
    }

    public static useSetPractitioner() {
        return useMutation({
            mutationFn: (newPatientPractitionerType: NewPatientPractitionerType) => MePatientService.putPatientMeSelectPractitioner(newPatientPractitionerType),
        });
    }

    public static useGetPatient(options?: UseQueryOptions) {
        return useQuery({
            queryKey: ["patient", "me", "profile"],
            queryFn: MePatientService.getPatientMeProfile,
            ...(options ?? []),
        });
    }

    public static usePatientUpdateMutation() {
        return useMutation({
            mutationFn: (PatientProfileType: PatientProfileType) => MePatientService.putPatientMeProfileUpdate(PatientProfileType),
        });
    }
}