<template>
    <div class="breadcrumb">
        <div class="navigation">
            <template v-for="i in nbSteps">
                <div class="dot">
                    <template v-if="i <= currentStep">✓</template>
                </div>
                <div v-if="i !== nbSteps" class="line"></div>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import {toRefs} from "vue";

const props = defineProps<{
    currentStep: number,
    nbSteps: number
}>();

const {currentStep, nbSteps} = toRefs(props);
</script>

<style scoped lang="scss">
.breadcrumb {

    .navigation {
        display: flex;
        align-items: center;
    }

    .dot {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 900;
    }

    .line {
        flex: 1;
        height: 3px;
        background-color: #FFFFFF;
    }
}
</style>