import moment from "moment";

export default {
    errors: (errors: string[]): string[] => {
        if (errors.length === 0) {
            return [""];
        }

        return errors.map((error: any) => error.$message);
    },

    date: (date: Date, format: string | undefined = undefined): string => {
        return moment(date).format(format);
    },

    toSnakeCase: (str: string): string => {
        return str
            .trim()
            .replace(/([A-Z])/g, " $1")
            .replace(/[\s-\/]+/g, "_")
            .toLowerCase()
            .replace(/^_+|_+$/g, "");
    },
};