import {createApp} from "vue";
import App from "./App.vue";
import router from "@/router/router";
import vuetify from "@/plugins/vuetify";
import {loadFonts} from "@/plugins/webfontloader";
import i18n from "@/plugins/i18n";
import "@/assets/scss/main.scss";
import {OpenAPI} from "@/.generated/api";
import env from "@/config/env";
import {VueQueryPlugin, VueQueryPluginOptions} from "@tanstack/vue-query";
import {createPinia} from "pinia";
import VCalendar from "v-calendar";
import locale from "@/utils/Locale";
import securityManager from "@/managers/SecurityManager";
import Firebase from "@/plugins/firebase";

// noinspection JSIgnoredPromiseFromCall
loadFonts();

OpenAPI.BASE = env.API || "";
OpenAPI.TOKEN = undefined;

startApp();

async function startApp() {
    securityManager.useTokenFromLocalStorage();

    if (!securityManager.isAuthenticated()) {
        await securityManager.tryToRefreshToken();
    }

    locale.setLocale(i18n.global.locale.value);

    const vueQueryPluginOptions: VueQueryPluginOptions = {
        queryClientConfig: {
            defaultOptions: {
                queries: {
                    retry: 0,
                },
                mutations: {
                    retry: 0,
                },
            },
        },
    };

    createApp(App)
        .use(createPinia())
        .use(router)
        .use(i18n)
        .use(vuetify)
        .use(VueQueryPlugin, vueQueryPluginOptions)
        .use(VCalendar, {})
        .mount("#app");

    await Firebase.init();
}
