import {RouteRecordRaw} from "vue-router";
import ModuleView from "@/views/learning/ModuleView.vue";
import ModuleListView from "@/views/learning/ModuleListView.vue";
import TreatmentListView from "@/views/learning/TreatmentListView.vue";
import TreatmentView from "@/views/learning/TreatmentView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/moduleList",
        name: "moduleList",
        component: ModuleListView,
        meta: {
            layout: "AppLayout",
        },
    },
    {
        path: "/module/:moduleId",
        name: "module",
        component: ModuleView,
        meta: {
            layout: "AppLayout",
            appStyle: {
                fullWidth: true,
            },
        },
    },
    {
        path: "/treatmentList/:type",
        name: "treatmentList",
        component: TreatmentListView,
        meta: {
            layout: "AppLayout",
        },
    },
    {
        path: "/treatment/:treatmentId",
        name: "treatment",
        component: TreatmentView,
        meta: {
            layout: "AppLayout",
        },
    },
];

export default routes;
