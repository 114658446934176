import ProfileView from "@/views/user/ProfileView.vue";
import ProfileUpdate from "@/views/user/ProfileUpdate.vue";
import {RouteRecordRaw} from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/profileView",
        name: "profileView",
        component: ProfileView,
        meta: {
            layout: "AppLayout"
        },
    },
    {
        path: "/profileUpdate",
        name: "profileUpdate",
        component: ProfileUpdate,
        meta: {
            layout: "AppLayout"
        },
    },
];

export default routes;