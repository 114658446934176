/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Patient2 } from '../models/Patient2';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContentService {

    /**
     * Content done
     * Set content as done
     * @param id
     * @returns Patient2 Return patient with content history
     * @throws ApiError
     */
    public static postPatientContentDone(
        id: string,
    ): CancelablePromise<Patient2> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/patient/content/{id}/done',
            path: {
                'id': id,
            },
        });
    }

}
