import {RouteRecordRaw} from "vue-router";
import LoginView from "@/views/security/LoginView.vue";
import RegistrationView from "@/views/security/RegistrationView.vue";
import RegistrationConfirmationView from "@/views/security/RegistrationConfirmationView.vue";


const routes: Array<RouteRecordRaw> = [
    {
        path: "/login",
        name: "login",
        component: LoginView,
        meta: {
            allowAnonymous: true,
            disallowConnected: true,
            layout: "AppLayout",
            appStyle: {
                displayNavigationBar: false,
            }
        },
    },
    {
        path: "/registration",
        name: "registration",
        component: RegistrationView,
        meta: {
            allowAnonymous: true,
            disallowConnected: true,
            layout: "AppLayout",
            appStyle: {
                displayNavigationBar: false,
            },
        },
    },
    {
        path: "/registrationConfirmation",
        name: "registrationConfirmation",
        component: RegistrationConfirmationView,
        meta: {
            layout: "AppLayout",
            appStyle: {
                displayNavigationBar: false,
            },
        },
    },
];

export default routes;
