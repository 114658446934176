<template>
    <div class="slide">
        <h2>
            Avez-vous consulté un médecin ?
        </h2>

        <v-item-group
            v-model="hasConsultedAPractitioner"
        >
            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="hasConsultedAPractitioner = false"
                                :active="hasConsultedAPractitioner === false"
                            >
                                Non
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="hasConsultedAPractitioner = true"
                                :active="hasConsultedAPractitioner === true"
                            >
                                Oui
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>
        </v-item-group>

        <template v-if="hasConsultedAPractitioner">
            <h2 class="mt-8">
                Quel médecin ?
            </h2>

            <v-item-group
                v-model="modelValue.kindOfPractitioner"
            >
                <v-row class="mt-2">
                    <v-col cols="12">
                        <v-item>
                            <template v-slot:default>
                                <v-btn-transparent
                                    @click="modelValue.kindOfPractitioner = PositioningType.kindOfPractitioner.ATTENDING_GENERAL_PRACTITIONER"
                                    :active="modelValue.kindOfPractitioner === PositioningType.kindOfPractitioner.ATTENDING_GENERAL_PRACTITIONER"
                                >
                                    Médecin généraliste traitant
                                </v-btn-transparent>
                            </template>
                        </v-item>
                    </v-col>
                </v-row>

                <v-row class="mt-2">
                    <v-col cols="12">
                        <v-item>
                            <template v-slot:default>
                                <v-btn-transparent
                                    @click="modelValue.kindOfPractitioner = PositioningType.kindOfPractitioner.IN_HOSPITAL_EMERGENCIES"
                                    :active="modelValue.kindOfPractitioner === PositioningType.kindOfPractitioner.IN_HOSPITAL_EMERGENCIES"
                                >
                                    Aux urgences à l'hôpital
                                </v-btn-transparent>
                            </template>
                        </v-item>
                    </v-col>
                </v-row>

                <v-row class="mt-2">
                    <v-col cols="12">
                        <v-item>
                            <template v-slot:default>
                                <v-btn-transparent
                                    @click="modelValue.kindOfPractitioner = PositioningType.kindOfPractitioner.ANOTHER_GENERAL_PRACTITIONER"
                                    :active="modelValue.kindOfPractitioner === PositioningType.kindOfPractitioner.ANOTHER_GENERAL_PRACTITIONER"
                                >
                                    Un autre médecin généraliste
                                </v-btn-transparent>
                            </template>
                        </v-item>
                    </v-col>
                </v-row>

                <v-row class="mt-2">
                    <v-col cols="12">
                        <v-item>
                            <template v-slot:default>
                                <v-btn-transparent
                                    @click="modelValue.kindOfPractitioner = PositioningType.kindOfPractitioner.RHEUMATOLOGIST"
                                    :active="modelValue.kindOfPractitioner === PositioningType.kindOfPractitioner.RHEUMATOLOGIST"
                                >
                                    Un rhumatologue
                                </v-btn-transparent>
                            </template>
                        </v-item>
                    </v-col>
                </v-row>
            </v-item-group>
        </template>
    </div>
</template>

<script lang="ts" setup>

import {PositioningType} from "@/.generated/api";
import {Ref, ref, toRefs, watch} from "vue";
import Browser from "@/utils/Browser";

const props = defineProps<{
    modelValue: Partial<PositioningType>
}>();

const {modelValue} = toRefs(props);

const hasConsultedAPractitioner: Ref<boolean | undefined> = ref(undefined);

watch(hasConsultedAPractitioner, () => {
    let htmlElement: HTMLElement = document.querySelector(".swiper-slide-active");

    Browser.scrollTo(htmlElement);

    if (hasConsultedAPractitioner.value === false) {
        modelValue.value.kindOfPractitioner = PositioningType.kindOfPractitioner.NO_PRACTITIONER;
        return;
    }

    if (hasConsultedAPractitioner.value === true) {
        Browser.scrollTo(htmlElement, true);
    }

    modelValue.value.kindOfPractitioner = undefined;
});
</script>

<style lang="scss" scoped>
</style>