<template>
    <div>
        <section>
            <h2>
                <label for="ual-result">
                    {{ $t("ual.yourHistoric") }}
                </label>
            </h2>

            <UalHistoricLineChart v-if="ualListQuery.isSuccess" :ualHistoric="ualListQuery.data.value?.ualHistory">
            </UalHistoricLineChart>
        </section>
    </div>
</template>

<script lang="ts" setup>
import MeManager from "@/managers/MeManager";
import {computed} from "vue";
import UalHistoricLineChart from "@/components/UalHistoricLineChart.vue";

const ualListQuery = MeManager.useUalListQuery();
const mePatient = MeManager.useGetPatient();

const patient = computed(() => {
    return mePatient.data.value;
});
</script>

<style lang="scss" scoped>
</style>