<template>
    <div class="slide d-flex flex-column align-center">
        <img
            class="h-50"
            :src="require('@/assets/svg/gout/positioning/positioning_confirmation.svg')" :alt="$t('congrats')"
        >
        <div class="d-flex flex-column align-center">
            <img
                class="h-25"
                :src="require('@/assets/svg/gout/positioning/appligoutte_logo.svg')" :alt="$t('global.app')"
            >
            <div class="font-weight-bold text-h5 mt-8">
                Merci pour votre aide.
                <br>
                Vous allez pouvoir bénéficier de nos services.
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
.slide {
    height: 100%;
    max-height: 100%;
}
</style>