<template>
    <main id="treatment-view">
        <div v-if="treatment">
            <h2 class="mb-5">
                {{ $t(`global.treatment.${treatment.type}`) }}
            </h2>

            <hr>

            <h3>
                {{ treatment.name }}
            </h3>

            <div class="treatment-img">
                <img :src="treatment.image.signedUrl" :alt="treatment.name">
            </div>

            <div class="advices-precautions-tab">
                <div class="tabs">
                    <div
                        class="advices tab"
                        :class="{'active': currentTab === ADVICES}"
                        @click.prevent="currentTab = ADVICES"
                    >
                        Conseils
                    </div>
                    <div
                        class="precautions tab"
                        :class="{'active': currentTab === PRECAUTIONS}"
                        @click.prevent="currentTab = PRECAUTIONS"
                    >
                        Précautions
                    </div>
                </div>

                <div class="contents">
                    <div class="advices content pl-5" :class="{'active': currentTab === ADVICES}" v-html="treatment.advice">
                    </div>
                    <div class="precautions content pl-5" :class="{'active': currentTab === PRECAUTIONS}" v-html="treatment.precaution">
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script lang="ts" setup>
import TreatmentManager from "@/managers/TreatmentManager";
import {useRoute} from "vue-router";
import {computed, ComputedRef, ref} from "vue";

const route = useRoute();

const ADVICES = "advices";
const PRECAUTIONS = "precautions";

const currentTab = ref(ADVICES);

const treatmentId: ComputedRef<string> = computed(() => {
    return route.params.treatmentId.toString();
});

const treatmentListQuery = TreatmentManager.useTreatmentShowQuery(treatmentId.value);

const treatment = computed(() => {
    return treatmentListQuery.data.value;
});
</script>

<style lang="scss" scoped>
#treatment-view {
    hr {
        border: 1px dashed #13002C;
        margin-bottom: 30px;
    }

    .treatment-img {
        display: flex;
        justify-content: center;

        height: 100px;
        margin: 20px 0;
    }

    .advices-precautions-tab {
        .tabs {
            display: flex;
            gap: 10px;

            .tab {
                display: flex;
                justify-content: center;
                align-items: center;

                cursor: pointer;
                padding: 10px 0;
                border-radius: 20px 20px 0 0;
                font-size: 20px;
                font-weight: 700;
                flex: 1;
                opacity: 0.5;

                &.advices {
                    background: #66C1BF;
                }

                &.precautions {
                    background: #EA5153;
                }

                &.active {
                    opacity: 1;
                }
            }
        }

        .contents {
            .content {
                display: none;
                padding: 10px;
                border-radius: 0 0 20px 20px;
                background: #FFFFFF;
                border: 2px solid;

                &.advices {
                    border-color: #66C1BF;
                    border-top-width: 1px;
                }

                &.precautions {
                    border-color: #EA5153;
                    border-top-width: 1px;
                }

                &.active {
                    display: block;
                }
            }
        }
    }

}
</style>