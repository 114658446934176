<template>
    <div class="slide">
        <h2>
            Combien de crises de goutte avez-vous eues ?
        </h2>

        <v-item-group
            v-model="modelValue.numberOfCrisis"
        >
            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="modelValue.numberOfCrisis = PositioningType.numberOfCrisis.ZERO"
                                :active="modelValue.numberOfCrisis === PositioningType.numberOfCrisis.ZERO"
                            >
                                0
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="modelValue.numberOfCrisis = PositioningType.numberOfCrisis.ONE"
                                :active="modelValue.numberOfCrisis === PositioningType.numberOfCrisis.ONE"
                            >
                                1
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="modelValue.numberOfCrisis = PositioningType.numberOfCrisis.TWO_TO_FOUR"
                                :active="modelValue.numberOfCrisis === PositioningType.numberOfCrisis.TWO_TO_FOUR"
                            >
                                De 2 à 4
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="modelValue.numberOfCrisis = PositioningType.numberOfCrisis.MORE_THAN_FIVE"
                                :active="modelValue.numberOfCrisis === PositioningType.numberOfCrisis.MORE_THAN_FIVE"
                            >
                                Plus de 5
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>
        </v-item-group>
    </div>
</template>

<script lang="ts" setup>

import {PositioningType} from "@/.generated/api";
import {toRefs} from "vue";

const props = defineProps<{
    modelValue: Partial<PositioningType>
}>();

const {modelValue} = toRefs(props);
</script>

<style lang="scss" scoped>
</style>