<template>
    <div id="Slide">
        <header id="header">
            <h1 id="logo">
                <img :src="require('@/assets/svg/logo_v2.svg')" :alt="$t('global.app')" width="200">
            </h1>
            <h1 id="title">
                Mon profil
            </h1>
        </header>

        <div id="main" v-if="profileQuery.isSuccess">
            <div id="fieldName">
                Nom
            </div>
            <div id="fieldContent">
                {{ profileQuery.data.value?.lastname }}
            </div>
            <div id="fieldName">
                Prénom
            </div>
            <div id="fieldContent">
                {{ profileQuery.data.value?.firstname }}
            </div>
            <div id="fieldName">
                Email
            </div>
            <div id="fieldContent">
                {{ profileQuery.data.value?.email }}
            </div>
            <div id="fieldName">
                Date de naissance
            </div>
            <div id="fieldContent">
                {{ formatDateOfBirth() }}
            </div>
            <div id="fieldName">
                Ville
            </div>
            <div id="fieldContent">
                {{ profileQuery.data.value?.city }}
            </div>
            <div id="button">
                <v-btn
                    class="v-btn1"
                    @click.prevent="moveToRoute()"
                >
                    MODIFIER
                </v-btn>
            </div>
            <div id="buttonLogout">
                <v-btn
                    class="v-btn2"
                    @click.prevent="logout()"
                >
                    ME DECONNECTER
                </v-btn>
            </div>
        </div>

        <div class="text-center">
            Consulter les <a href="https://appligoutte.io/index.php/cgu/">conditions générales d'utilisation</a>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {reactive} from "vue";
import useVuelidate from "@vuelidate/core";
import Vuelidate from "@/utils/Vuelidate";
import {useRouter} from "vue-router";
import MeManager from "@/managers/MeManager";
import SecurityManager from "@/managers/SecurityManager";


const data = reactive({
    code: "",
});

const router = useRouter();

const moveToRoute = () => {
    router.push("ProfileUpdate");
};

function logout() {
    SecurityManager.logout();
    router.push({name: "login"});
}


const vuelidate = useVuelidate(
    {
        code: [Vuelidate.required],
    },
    data,
);

const profileQuery = MeManager.useGetPatient();

const formatDateOfBirth = () => {
    const date = new Date(profileQuery.data.value?.birthdate);
    const options = {day: "numeric", month: "long", year: "numeric"};
    return date.toLocaleDateString("fr-FR", options);
};

</script>

<style lang="scss" scoped>


.v-text-field {
    height: 50px !important;
    background-color: #FFFFFF !important;
}

.v-btn1 {
    width: 200px;
    height: 50px;
    border-radius: 50px;
    background-color: #FFDE59;
    border-color: #FFDE59;
    color: #000000;
    top: 10px;
}

.v-btn2 {
    width: 200px;
    height: 50px;
    border-radius: 50px;
    background-color: #ffac59;
    border-color: #ffac59;
    color: #000000;
    top: 10px;
}

.arrowNext {
    fill: #FFFFFF;
    stroke: #FFFFFF;
}


#Slide {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-color: #FFFFFF;

    > #header {
        position: relative;

        > #logo {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
        }

        > #title {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 50px;
        }
    }

    > #main {
        flex: 1;
        padding: 38px;

        > #fieldName {
            position: relative;
            width: 288px;
            left: 20px;
            top: 30px;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            color: #000000;
        }

        > #fieldContent {
            position: relative;
            width: 288px;
            left: 20px;
            top: 20px;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            color: #47B9AE;
        }

        > #button {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 80px;
        }

        > #buttonLogout {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
        }

    }

    > #footer {
        position: relative;

        > #picto {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

</style>