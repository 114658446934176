<template>
    <div class="slide d-flex flex-column align-center">
        <img
            class="h-50"
            :src="
                havePossiblyCrisis?
                require('@/assets/svg/gout/diagnosis/have_crisis.svg'):
                require('@/assets/svg/gout/diagnosis/dont_have_crisis.svg')
            "
            :alt="havePossiblyCrisis?'Have Probably Crisis':'No Crisis'"
        >
        <div class="d-flex flex-column align-center">
            <div class="font-weight-bold text-h5 mt-8">
                {{
                    havePossiblyCrisis ? "Vous avez probablement une crise de goutte !" : "Vous n’avez probablement pas une crise de goutte"
                }}
            </div>
            <div>
                Nous vous conseillons de voir le médecin pour obtenir le bon diagnostic
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>

import {computed, toRefs} from "vue";
import {CustomDiagnosisType} from "@/views/gout/CrisisDiagnosisView.vue";
import {CrisisDiagnosisType} from "@/.generated/api";


const props = defineProps<{
    modelValue: CustomDiagnosisType
}>();

const {modelValue} = toRefs(props);

const havePossiblyCrisis = computed(() => {
    return (
        (
            (modelValue.value.thinkToHaveCrisis === true ? 1 : 0) +
            (modelValue.value.painLevel >= 5 ? 1 : 0) +
            (modelValue.value.swollenJointLevel !== CrisisDiagnosisType.swollenJointLevel.NOT_AT_ALL ? 1 : 0) +
            (modelValue.value.heatJointLevel !== CrisisDiagnosisType.heatJointLevel.NOT_AT_ALL ? 1 : 0)
        )
        >= 3
    );
});
</script>

<style lang="scss" scoped>
.slide {
    height: 100%;
    max-height: 100%;
}
</style>