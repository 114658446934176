<template>
    <div class="slide">
        <h2>
            Votre crise de goutte est-elle terminée ?
        </h2>

        <v-item-group
            v-model="model"
        >
            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="model.crisisContinue = false"
                                :active="model.crisisContinue === false"
                            >
                                Non
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="model.crisisContinue = true"
                                :active="model.crisisContinue === true"
                            >
                                Oui
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>
        </v-item-group>

        <template v-if="model.crisisContinue === true">
            <v-row>
                <v-col cols="12">
                    <h2>
                        Quand la crise s'est t'elle terminé ?
                    </h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-select
                        :items="['today', 'before']"
                        v-model="selectDate"
                        :item-title="item => $t(`global.${item}`)"
                        :item-value="item => item"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row v-if="selectDate === 'before'">
                <v-col cols="12">
                    <UseElementSize v-slot="{ width, height }">
                        <v-date-picker
                            mode="date"
                            v-model="stoppedAtDateComputed"
                            :max="new Date()"
                            :width="width"
                        />
                    </UseElementSize>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script lang="ts" setup>

import {computed, Ref, ref, watch} from "vue";
import {UseElementSize} from "@vueuse/components";
import filters from "@/filters";
import {CrisisDailyCustomModel} from "@/views/gout/CrisisDailyFollowingView.vue";

const model = defineModel<CrisisDailyCustomModel>({
    required: true,
});

const selectDate: Ref<string | undefined> = ref(undefined);

const stoppedAtDateComputed = computed({
    get: () => {
        if (!model.value.crisisFinishType.stoppedAt) {
            return undefined;
        }

        return new Date(model.value.crisisFinishType.stoppedAt);
    },
    set: (newDate) => {
        if (!newDate) {
            model.value.crisisFinishType.stoppedAt = undefined;
            return;
        }

        model.value.crisisFinishType.stoppedAt = filters.date(newDate);
    },
});

watch(selectDate, (nv) => {
    if (selectDate.value === "today") {
        stoppedAtDateComputed.value = new Date();
        return;
    }

    stoppedAtDateComputed.value = undefined;
});
</script>


<style lang="scss" scoped>
</style>