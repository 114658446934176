// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import {en, fr} from "vuetify/locale";

// Vuetify
import {createVuetify, ThemeDefinition} from "vuetify";
import {VBtn} from "vuetify/components";

const goutTheme: ThemeDefinition = {
    dark: false,
    colors: {
        primary: "#47B9AE",
    },
};

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify(
    {
        aliases: {
            VBtnSecondary: VBtn,
            VBtnSuccess: VBtn,
            VBtnDanger: VBtn,
            VBtnLink: VBtn,
            VBtnTransparent: VBtn,
        },
        defaults: {
            VBtn: {
                size: "large",
                rounded: true,
                block: true,
                class: ["text-black", "font-weight-black"],
            },
            VBtnSecondary: {
                size: "large",
                color: "#FFA050",
                rounded: true,
                block: true,
                class: ["text-black", "font-weight-black"],
            },
            VBtnSuccess: {
                size: "large",
                color: "#47B9AE",
                rounded: true,
                block: true,
                class: ["text-black", "font-weight-black"],
            },
            VBtnDanger: {
                size: "large",
                color: "#EA5153",
                rounded: true,
                block: true,
                class: ["text-black", "font-weight-black"],
            },
            VBtnLink: {
                size: "large",
                color: "#47B9AE",
                variant: "text",
                rounded: true,
                block: true,
                class: ["text-black", "font-weight-black", "v-btn--variant-text-link"],
            },
            VBtnTransparent: {
                size: "large",
                rounded: true,
                block: true,
                class: ["background-white-transparent", "text-black", "font-weight-black"],
            },
            VTextField: {
                variant: "outlined",
                rounded: true,
                class: ["mb-2"],
            },
            VSelect: {
                variant: "outlined",
                rounded: true,
                class: ["mb-2"],
            },
            vOtpInput: {
                style: {
                    "border-radius": "50%",
                },
            },

        },
        theme: {
            defaultTheme: "goutTheme",
            themes: {goutTheme},
        },
        locale: {
            fallback: "fr",
            messages: {
                fr, en,
            },
        }
    },
);
