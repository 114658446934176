<template>
    <div id="registration">
        <header id="header" class="mb-4">
            <h1>
                Créez votre compte
            </h1>
        </header>

        <v-form @submit.prevent="register">
            <v-text-field
                label="Nom"
                placeholder="Petit"
                v-model="registrationData.lastname"
                :error-messages="Filters.errors(vuelidate.lastname.$errors)"
                @blur="vuelidate.lastname.$touch"
            ></v-text-field>

            <v-text-field
                label="Prénom"
                placeholder="Jean"
                v-model="registrationData.firstname"
                :error-messages="Filters.errors(vuelidate.firstname.$errors)"
                @blur="vuelidate.firstname.$touch"
            ></v-text-field>

            <v-text-field
                label="Email"
                autocomplete="email"
                placeholder="jean-petit@domain.com"
                v-model="registrationData.email"
                :error-messages="Filters.errors(vuelidate.email.$errors)"
                @blur="vuelidate.email.$touch"
            ></v-text-field>

            <v-text-field
                label="Mot de passe"
                autocomplete="new-password"
                placeholder="MyS3cur3dP@ssw0rd"
                v-model="registrationData.plainPassword"
                :type="hidePassword?'password':'text'"
                :error-messages="Filters.errors(vuelidate.plainPassword.$errors)"
                @blur="vuelidate.plainPassword.$touch"
                :append-inner-icon="hidePassword?'mdi-eye':'mdi-eye-off'"
                @click:append-inner="hidePassword = !hidePassword"
            >
            </v-text-field>

            <v-text-field
                label="Mot de passe (confirmation)"
                autocomplete="new-password"
                placeholder="MyS3cur3dP@ssw0rd"
                v-model="plainPasswordRepeat"
                :type="hidePassword?'password':'text'"
                :error-messages="Filters.errors(vuelidate.plainPasswordRepeat.$errors)"
                @blur="vuelidate.plainPasswordRepeat.$touch"
                :append-inner-icon="hidePassword?'mdi-eye':'mdi-eye-off'"
                @click:append-inner="hidePassword = !hidePassword"
            >
            </v-text-field>

            <v-text-field
                label="Date de naissance"
                placeholder="01/01/2001"
                type="date"
                v-model="registrationData.birthdate"
                :error-messages="Filters.errors(vuelidate.birthdate.$errors)"
                @blur="vuelidate.birthdate.$touch"
            />

            <v-select
                v-model="registrationData.gender"
                placeholder="Man"
                :items="genderList"
                label="Gender"
                required
            ></v-select>

            <v-text-field
                label="Pays"
                placeholder="France"
                v-model="registrationData.country"
                :error-messages="Filters.errors(vuelidate.country.$errors)"
                @blur="vuelidate.country.$touch"
            ></v-text-field>

            <v-text-field
                label="Ville"
                placeholder="Paris"
                v-model="registrationData.city"
                :error-messages="Filters.errors(vuelidate.city.$errors)"
                @blur="vuelidate.city.$touch"
            ></v-text-field>

            <v-checkbox
                v-model="registrationData.gcu"
                :error-messages="Filters.errors(vuelidate.city.$errors)"
                @blur="vuelidate.city.$touch"
            >
                <template v-slot:label>
                    <div>
                        J'accepte les&nbsp;<a href="https://appligoutte.io/index.php/cgu/">conditions générales d'utilisation</a>
                    </div>
                </template>
            </v-checkbox>

            <v-btn-success
                type="submit"
                :loading="registerMutation.isPending.value"
                :disabled="vuelidate.$invalid"
            >
                S'enregistrer
            </v-btn-success>

            <div class="mt-4">
                Vous avez déjà un compte?
                <router-link :to="{name: 'login'}">
                    Connectez-vous !.
                </router-link>
            </div>
        </v-form>
    </div>
</template>

<script lang="ts" setup>
import {computed, Ref, ref} from "vue";
import useVuelidate from "@vuelidate/core";
import Vuelidate from "@/utils/Vuelidate";
import Filters from "@/utils/Filters";
import {useRouter} from "vue-router";
import SecurityManager from "@/managers/SecurityManager";
import Notify, {NotificationType} from "@/notify";
import {CredentialType, NewPatientType} from "@/.generated/api";
import gender = NewPatientType.gender;

const hidePassword = ref(true);

const registrationData: Ref<NewPatientType> = ref({
    email: "",
    firstname: "",
    lastname: "",
    plainPassword: "",
    birthdate: "",
    gender: gender.MALE,
    country: "",
    city: "",
    gcu: false,
});

const plainPasswordRepeat = ref("");

const genderList = [
    gender.MALE,
    gender.FEMALE,
];

const registerMutation = SecurityManager.useRegisterMutation();
const loginMutation = SecurityManager.useTokenMutation();

const router = useRouter();

const register = async () => {
    await registerMutation.mutateAsync({
        ...registrationData.value,
        gcu: undefined,
    });

    const credentials: CredentialType = {
        email: registrationData.value.email,
        password: registrationData.value.plainPassword,
    };

    await loginMutation.mutateAsync(credentials)
        .then(() => {
            Notify.addNotification({
                title: "security.success.title",
                icon: "mdi-check",
                type: NotificationType.SUCCESS,
            });

            router.push({name: "registrationConfirmation"});
        })
        .catch(() => {
            Notify.addNotification({
                title: "security.error.title",
                content: "security.error.invalidCredentials",
                icon: "mdi-error",
                type: NotificationType.ERROR,
            });
        });

};

const vuelidate = useVuelidate(
    {
        email: [Vuelidate.required, Vuelidate.email],
        firstname: [Vuelidate.required],
        lastname: [Vuelidate.required],
        plainPassword: [Vuelidate.required],
        plainPasswordRepeat: [Vuelidate.required, Vuelidate.sameAs(computed(() => registrationData.value.plainPassword), "\"Mot de passe\"")],
        birthdate: [Vuelidate.required],
        gender: [Vuelidate.required],
        city: [Vuelidate.required],
        country: [Vuelidate.required],
        gcu: [Vuelidate.required, Vuelidate.sameAs(true)],
    },
    computed(() => {
        return {
            ...registrationData.value,
            plainPasswordRepeat: plainPasswordRepeat,
        };
    }),
);
</script>

<style lang="scss" scoped>
#registration {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: auto;

    > #main {
        flex: 1;
    }
}
</style>