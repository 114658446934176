<template>
    <div class="d-flex flex-column justify-space-around align-center w-100 h-100">
        <v-row
            align="center"
            justify="center"
        >
            <v-col cols="12">
                <div id="title">
                    <section>
                        <div id="top-section1">
                            Indiquer un
                        </div>
                        <div id="top-section2">
                            nouveau taux d’acide urique
                        </div>
                        <div id="button-add">
                            <v-btn
                                icon="mdi-plus"
                                size="large"
                                color="#47B9AE"
                                :to="{name: 'ualNew'}">
                            </v-btn>
                        </div>
                    </section>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <div id="bottom-section1">
                    Ou
                </div>
                <div id="button-history">
                    <section>
                        <v-btn
                            class="bottom-btn-size"
                            rounded="xl"
                            color="#47B9AE"
                            :to="{name: 'ualHistoric'}"
                        >
                            Voir mon historique
                        </v-btn>
                    </section>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
#title {
    width: 300px;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
}

#top-section1 {
    display: flex;
    justify-content: center;
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
}

#top-section2 {
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
}

#button-add {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

#bottom-section1 {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 72%;
    left: 50%;
    transform: translate(-50%, -72%);
}

.bottom-btn-size {
    width: 180px;
    height: 50px;
}

#button-history {
    display: flex;
    justify-content: center;
    border-radius: 20px;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -80%);
}
</style>