<template>
    <div id="fullscreen-layout">
        <slot></slot>
    </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss">
#fullscreen-layout {
    width: 100%;
    height: 100vh;
    overflow: hidden;

    > * {
        padding: 33px 50px 76px;
        width: 100%;
        height: 100%;

        @media (max-width: 375px) {
            padding: 16px 25px 38px;
        }
    }
}
</style>