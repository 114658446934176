<template>
    <div class="d-flex flex-column justify-space-around align-center w-100 h-100">
        <v-form @submit.prevent="goToNextStep">
            <v-row
                align="center"
                justify="center"
            >
                <v-col cols="12">
                    <section v-if="step === 1">
                        <div id="title">
                            <label for="ual-result">
                                {{ $t("ual.newLevel") }}
                            </label>
                        </div>
                        <div id="input-ual">
                            <v-text-field
                                id="ual-result"
                                type="number"
                                v-model="data.ual"
                                placeholder="250.35"
                                @change="vuelidate.ual.$touch"
                                :error-messages="filters.errors(vuelidate.ual.$errors)"
                            >
                                <template v-slot:append>
                                    {{ $t("ual.umol/L") }}
                                </template>
                            </v-text-field>
                        </div>
                        <div id="button-continue">
                            <v-btn
                                class="bottom-btn-size"
                                color="#47B9AE"
                                type="submit"
                                rounded="xl"
                            >
                                {{ $t("global.continue") }}
                            </v-btn>
                        </div>
                    </section>


                    <section v-else-if="step === 2">
                        <div id="title">
                            <label for="ual-result">
                                {{ $t("ual.bloodTestDate") }}
                            </label>
                        </div>

                        <div class="d-flex justify-center">
                            <v-input
                                :error-messages="filters.errors(vuelidate.date.$errors)"
                            >
                                <v-date-picker
                                    mode="date"
                                    v-model="data.date"
                                    :max-date="new Date()"
                                    :attributes='calendarAttributes'
                                    @change="vuelidate.date.$touch"
                                />
                            </v-input>
                        </div>

                        <div id="button-continue">
                            <v-btn
                                class="bottom-btn-size"
                                color="#47B9AE"
                                type="submit"
                                rounded="xl"
                            >
                                {{ $t("global.continue") }}
                            </v-btn>
                        </div>
                    </section>
                    <section v-else-if="step === 3">
                        <div id="resume1">
                            <label for="ual-result">
                                {{ $t("ual.newLevel") }}
                            </label>
                        </div>

                        <div id="resume2">
                            <v-input
                                :error-messages="filters.errors(vuelidate.ual.$errors)"
                            >
                                <div v-if="data.ual">
                                    <img :src="require('@/assets/svg/yellow_check.svg')" class="check">
                                    <strong>{{ data.ual }}</strong> {{ $t("ual.umol/L") }}
                                </div>
                            </v-input>
                        </div>

                        <div id="resume3">
                            <label for="ual-result">
                                {{ $t("ual.bloodTestDate") }}
                            </label>
                        </div>

                        <div id="resume4">
                            <v-input
                                :error-messages="filters.errors(vuelidate.date.$errors)"
                            >
                                <div v-if="data.date">
                                    <img :src="require('@/assets/svg/yellow_check.svg')" class="check">
                                    <strong>{{ filters.date(data.date, "L") }}</strong>
                                </div>
                            </v-input>
                        </div>


                        <div>
                            <div class="d-flex justify-space-around">
                                <div id="button-validate">
                                    <v-btn
                                        class="bottom-btn-size"
                                        color="#47B9AE"
                                        type="submit"
                                        rounded="xl"
                                    >
                                        {{ $t("global.validate") }}
                                    </v-btn>
                                </div>
                                <div id="button-modify">
                                    <v-btn
                                        class="bottom-btn-size"
                                        color="#E3E3E3"
                                        type="submit"
                                        rounded="xl"
                                        :to="{name: 'ualNew', params: {step: 1}}"
                                    >
                                        {{ $t("global.modify") }}
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section v-else-if="step === 4">
                        <div v-if="data.ual > 360">

                            <div id="circular">
                                <v-progress-circular
                                    :rotate="360"
                                    :size="200"
                                    :width="15"
                                    :model-value="(data.ual/360)*100"
                                    color="#DD3354"
                                >
                                    <div id="circular-value">
                                        <strong>{{ data.ual }}</strong> {{ $t("ual.umol/L") }}
                                    </div>

                                </v-progress-circular>
                            </div>
                            <div>
                                <div id="title2">
                                    <label for="ual-result">
                                        Votre taux d’acide urique est trop élevé
                                    </label>
                                </div>
                                <div id="subtitle">
                                    {{ $t("ual.standardUal") }}
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div id="circular">
                                <v-progress-circular
                                    :rotate="360"
                                    :size="200"
                                    :width="15"
                                    :model-value="(data.ual/360)*100"
                                    color="#47B9AE"
                                >
                                    <div id="circular-value">
                                        <strong>{{ data.ual }}</strong> {{ $t("ual.umol/L") }}
                                    </div>
                                </v-progress-circular>
                            </div>
                            <div id="title">
                                <label for="ual-result">
                                    Vous êtes en bonne voie !
                                </label>
                            </div>
                        </div>

                        <div id="button-continue">
                            <v-btn
                                class="bottom-btn-size"
                                color="#47B9AE"
                                rounded="xl"
                                type="button"
                                :to="{name: 'ualHistoric'}">
                                {{ $t("ual.historic") }}
                            </v-btn>
                        </div>
                    </section>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script lang="ts" setup>
import {computed, reactive, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useAppStore} from "@/stores/AppStore";
import useVuelidate, {BaseValidation} from "@vuelidate/core";
import Vuelidate from "@/utils/Vuelidate";
import filters from "@/filters";
import MeManager from "@/managers/MeManager";

const router = useRouter();
const appStore = useAppStore();

const ualNewMutation = MeManager.useUalNewMutation();
const route = useRoute();
const step = computed(() => parseInt(route.params.step?.toString() ?? "1"));

const calendarAttributes = [
    {
        key: "today",
        highlight: {
            color: "grey",
            fillMode: "outline",
        },
    },
];

const data = reactive({
    ual: undefined,
    date: new Date(),
});

const goToNextStep = async () => {
    let elementToValidate: BaseValidation | undefined = undefined;
    switch (step.value) {
        case 1:
            elementToValidate = vuelidate.value.ual;
            break;
        case 2:
            elementToValidate = vuelidate.value.date;
            break;
        case 3:
            elementToValidate = vuelidate.value;
            break;
    }

    if (elementToValidate) {
        elementToValidate.$touch();
        if (elementToValidate.$error) {
            return;
        }
    }

    if (step.value === 3 && data.date && data.ual) {
        await ualNewMutation.mutateAsync({
            date: filters.date(data.date),
            ual: data.ual,
        });
    }

    router.push({name: "ualNew", params: {step: step.value + 1}});
};

const vuelidate = useVuelidate(
    {
        ual: [Vuelidate.required, Vuelidate.numeric],
        date: [Vuelidate.required],
    },
    data,
);
if (step.value === 3) {
    vuelidate.value.$touch();
}
</script>

<style lang="scss" scoped>
.check {
    position: relative;
    top: 6px;
    margin-right: 30px;
}

#title {
    display: flex;
    justify-content: center;
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    width: 300px;
    top: 20%;
    left: 50%;
    padding-top: 50px;
}

#title2 {
    display: flex;
    justify-content: center;
    color: #DD3354;
    font-style: normal;
    font-size: 20px;
    text-align: center;
    line-height: 24px;
    font-weight: bold;
    width: 300px;
    top: 20%;
    left: 50%;
    padding-top: 50px;
}

#subtitle {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    width: 300px;
    top: 25%;
    left: 50%;
}

#resume1 {
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    width: 300px;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
}

#resume2 {
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    width: 300px;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -25%);
}

#resume3 {
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    width: 300px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
}

#resume4 {
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    width: 300px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -45%);
}

#input-ual {
    display: flex;
    justify-content: center;
    width: 200px;
    //margin-top: 20px;
    //margin-left: 50px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -30%);
}

#date-picker {
    display: flex;
    justify-content: center;
    margin-left: 20px;
    margin-top: 20px;
}

.bottom-btn-size {
    width: 180px;
    height: 50px;
}

#button-continue {
    display: flex;
    justify-content: center;
    border-radius: 20px;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -80%);
}

#button-validate {
    border-radius: 20px;
    position: absolute;
    top: 77%;
    left: 50%;
    transform: translate(-50%, -77%);
}

#button-modify {
    border-radius: 20px;
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -85%);
}

#circular {
    display: flex;
    justify-content: center;
}

#circular-value {
    font-style: normal;
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    align-items: center;
}
</style>