<template>
    <div class="module-doughnut">
        <div
            class="module-doughnut-percent"
        >
            <div
                :style="{background: backgroundPercentColor, color: textColor}"
            >
                {{ percent }} %
            </div>
        </div>
        <svg viewBox="0 0 36 36" class="module-doughnut-chart">
            <path
                class="module-doughnut-circle-line"
                d="M18 1.0845 a 16.9155 16.9155 0 0 1 0 33.831 a 16.9155 16.9155 0 0 1 0 -33.831"
            />
            <path
                class="module-doughnut-circle-line"
                d="M18 3.0845 a 14.9155 14.9155 0 0 1 0 29.831 a 14.9155 14.9155 0 0 1 0 -29.831"
            />
            <path
                class="module-doughnut-circle-bg"
                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
                class="module-doughnut-circle"
                :stroke-dasharray="percent + ', 100'"
                :style="{stroke: backgroundPercentColor}"
                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <image
                class="module-doughnut-image"
                x="8"
                y="8"
                width="20"
                height="20"
                :xlink:href="module.cover.signedUrl"
            />
        </svg>

        <div class="module-doughnut-title">
            {{ module.title }}
        </div>
    </div>
</template>

<script lang="ts" setup>
import {computed, toRefs} from "vue";
import {Module} from "@/.generated/api";
import Color from "@/utils/Color";

const props = defineProps<{
    module: Module;
}>();

const {module} = toRefs(props);

const backgroundPercentColor = computed(() => {
    return module.value.color;
});
const percent = computed(() => {
    return module.value.moduleGlobalPercent;
});

const textColor = computed(() => {
    return Color.getBlackOrWhiteBestConstrast(backgroundPercentColor.value);
});
</script>

<style lang="scss" scoped>
.module-doughnut {
    position: relative;
    box-sizing: content-box;

    .module-doughnut-percent {
        position: absolute;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;

        > div {
            width: 45%;
            height: 22px;
            padding: 5px;
            border: 1px solid #a8a8a8;
            border-radius: 80px;
            text-align: center;


            font-size: 18px;
            font-weight: 700;
        }
    }

    .module-doughnut-circle-line {
        fill: none;
        stroke: #a8a8a8;
        stroke-width: .2;
    }

    .module-doughnut-circle-bg {
        fill: none;
        stroke: #FFFFFF;
        stroke-width: 2;
    }

    .module-doughnut-circle {
        fill: none;
        background: white;
        stroke-width: 2;
        stroke-linecap: round;
        animation: progress 1s ease-out forwards;
    }

    @keyframes progress {
        0% {
            stroke-dasharray: 0 100;
        }
    }

    .module-doughnut-title {
        text-align: center;
        font-size: 20px;
        font-weight: 700;
    }
}
</style>
