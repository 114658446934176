/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewPatientQuizAnswsersType } from '../models/NewPatientQuizAnswsersType';
import type { Patient3 } from '../models/Patient3';
import type { Quiz } from '../models/Quiz';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class QuizService {

    /**
     * Quiz list
     * Get all quizzes
     * @returns Quiz Return all quizzes
     * @throws ApiError
     */
    public static getPatientQuizList(): CancelablePromise<Array<Quiz>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/patient/quiz',
        });
    }

    /**
     * Quiz post answers
     * Post quiz answers
     * @param id
     * @param requestBody
     * @returns Patient3 Return patient quiz score
     * @throws ApiError
     */
    public static postPatientQuizPostAnswer(
        id: string,
        requestBody?: NewPatientQuizAnswsersType,
    ): CancelablePromise<Patient3> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/patient/quiz/{id}/answer',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
