<template>
    <div
        class="painLevel"
    >
        <div class="image">
            <img :src="painLevelImg" :alt="painLevel">
        </div>

        <v-slider
            v-model="painLevel"
            show-ticks="always"
            :step="1"
            :min="0"
            :max="10"
            thumb-label="always"
        >
        </v-slider>
    </div>
</template>

<script setup lang="ts">
import {computed} from "vue";

const painLevel = defineModel<number>();

const painLevelImg = computed(() => {
    if (!painLevel.value || painLevel.value < 1) {
        return require("@/assets/svg/gout/painLevel/no_pain.png");
    }

    if (painLevel.value < 5) {
        return require("@/assets/svg/gout/painLevel/low_pain.png");
    }

    if (painLevel.value < 8) {
        return require("@/assets/svg/gout/painLevel/medium_pain.png");
    }

    return require("@/assets/svg/gout/painLevel/high_pain.png");
});
</script>

<style scoped lang="scss">
.painLevel {
    .image {
        display: flex;
        justify-content: center;

        img {
            width: 150px;
        }
    }
}
</style>