<template>
    <div class="slide">
        <h2>
            Depuis quand souffrez-vous de la goutte ?
        </h2>

        <v-item-group
            v-model="modelValue.sinceWhen"
        >
            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="modelValue.sinceWhen = PositioningType.sinceWhen.ZERO_TO_SIX_MONTHS"
                                :active="modelValue.sinceWhen === PositioningType.sinceWhen.ZERO_TO_SIX_MONTHS"
                            >
                                Moins de 6 mois
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="modelValue.sinceWhen = PositioningType.sinceWhen.SIX_TO_ONE_YEAR"
                                :active="modelValue.sinceWhen === PositioningType.sinceWhen.SIX_TO_ONE_YEAR"
                            >
                                Depuis 6 mois à 1 an
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="modelValue.sinceWhen = PositioningType.sinceWhen.ONE_TO_TWO_TEN_YEARS"
                                :active="modelValue.sinceWhen === PositioningType.sinceWhen.ONE_TO_TWO_TEN_YEARS"
                            >
                                Depuis 1 à 10 ans
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12">
                    <v-item>
                        <template v-slot:default>
                            <v-btn-transparent
                                @click="modelValue.sinceWhen = PositioningType.sinceWhen.MORE_THAN_TEN_YEARS"
                                :active="modelValue.sinceWhen === PositioningType.sinceWhen.MORE_THAN_TEN_YEARS"
                            >
                                Plus de 10 ans
                            </v-btn-transparent>
                        </template>
                    </v-item>
                </v-col>
            </v-row>
        </v-item-group>
    </div>
</template>

<script lang="ts" setup>

import {PositioningType} from "@/.generated/api";
import {toRefs} from "vue";

const props = defineProps<{
    modelValue: Partial<PositioningType>
}>();

const {modelValue} = toRefs(props);
</script>

<style lang="scss" scoped>
</style>