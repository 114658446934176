export default {
  "addUalLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un taux d’acide urique"])},
  "diagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagnostic"])},
  "triggeringFactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facteur déclenchant"])},
  "dailyFollowing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi de la crise"])},
  "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AppliGoutte"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
  "crisis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crise"])},
  "doctorAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendez-vous chez le médecin"])},
  "error": {
    "401": {
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez-vous connecter."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification nécessaire"])}
    },
    "5xx": {
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous rencontrons un souci avec le serveur, veuillez réessayer plus tard."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur Serveur"])}
    }
  },
  "iHaveAGoutCrisis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai une crise de goutte"])},
  "laboratoryAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendez-vous au laboratoire"])},
  "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "myMonitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon suivi"])},
  "ualLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux d'acide urique"])},
  "understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprendre"])},
  "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
  "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant"])},
  "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bas"])},
  "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyen"])},
  "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haut"])},
  "crisisHistoric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des crises"])},
  "treatment": {
    "crisis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement de crise"])},
    "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement de fond"])}
  }
}