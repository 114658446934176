export default {
  "permission": {
    "dialog": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer les notifications"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptez de recevoir les notifications pour être accompagné au mieux par l'application."])},
      "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation accordée"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez autorisé les notifications."])}
    },
    "refused": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation refusée"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez refusé les notifications."])}
    },
    "error": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de permission"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez une erreur pour les notifications."])}
    }
  }
}