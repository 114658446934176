export default {
  "articulations": {
    "Elbow_Left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coude Gauche"])},
    "Elbow_Right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coude Droit"])},
    "Wrist_Left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poignet Gauche"])},
    "Wrist_Right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poignet Droit"])},
    "Hand_Left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Gauche"])},
    "Hand_Right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Droite"])},
    "Ankle_Left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheville Gauche"])},
    "Ankle_Right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheville Droite"])},
    "Back_of_Foot_Left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrière du Pied Gauche"])},
    "Back_of_Foot_Right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrière du Pied Droit"])},
    "Big_Toe_Left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gros Orteil Gauche"])},
    "Big_Toe_Right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gros Orteil Droit"])},
    "Knee_Left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genou Gauche"])},
    "Knee_Right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genou Droit"])}
  },
  "triggerFactors": {
    "alcool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alcool"])},
    "soda_fruit_juice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soda / Jus de fruit"])},
    "offal_charcuterie_shellfish_other_meats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abats / Charcuterie / Fruits de mer / Autres viandes"])},
    "stresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stress"])},
    "dehydration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déshydratation"])},
    "plane_travel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyage en avion"])},
    "infection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infection"])},
    "stopping_daily_treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrêt du traitement quotidien"])},
    "stopping_crisis_treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrêt du traitement de crise"])},
    "medication_augmenting_ual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médicaments augmentant l'UAL"])},
    "trauma_or_physical_stress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traumatisme ou stress physique"])}
  },
  "yourHistoric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre historique de crises"])},
  "noHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore renseigné de crises."])},
  "feelings": {
    "swelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gonflement"])},
    "heat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaleur"])},
    "redness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rougeur"])}
  },
  "symptoms": {
    "diarrhea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diarrhée"])},
    "nausea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nausée"])},
    "vomiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vomissement"])},
    "skin_rash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éruption cutanée"])},
    "headache": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mal de tête"])}
  },
  "treatment": {
    "crisis": {
      "no_treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun Traitement"])},
      "colchicine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colchicine"])},
      "painkillers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antalgiques"])},
      "anti_inflammatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-inflammatoires"])},
      "corticoids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corticoïdes"])}
    }
  },
  "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homme"])},
  "Female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femme"])}
}